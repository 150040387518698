import 'jspdf-autotable'

import html2canvas from "html2canvas/dist/html2canvas"
import { jsPDF } from "jspdf"
import loader from "./loader"
import plan from "./plan"
import tools from "./tools"

window.html2canvas = html2canvas

const CLASSNAME_PLAN_CONTAINER = 'form-plan-container-'
const URI_PLAN_CONTAINER = 'app_customer_report_plan'
const URI_PLAN_FORM_CONTAINER = 'app_customer_report_plan_form'
const URI_INTERVENTION_GLOBAL_FORM_CONTAINER = 'app_customer_report_global_intervention_form'
const URI_INTERVENTION_CONTAINER = 'app_customer_report_intervention'
const REPORT_DATA_CONTAINER = 'report-data'

const appendPlanForm = function (planId, isFirst = false) {
    let $planContainer = $('body #' + CLASSNAME_PLAN_CONTAINER + planId)
    let $dataContainer = $('body #' + REPORT_DATA_CONTAINER)
    let $reportLogsContainer = $('body .report-logs')

    if ($dataContainer.length <= 0) {
        console.log('dataContainer not found')
        return
    }

    if ($planContainer.length > 0) {
        console.log('the plan form container already exist')
        return
    }
    // remove navbar elms associated
    $('body .report-intervention-plan-nav-link.plan-' + planId).remove()
    $('body .toggle-report-form-plan-' + planId).remove()

    let interventionId = $dataContainer.data('intervention')

    let uri = Routing.generate(URI_PLAN_CONTAINER, {
        slug: tools.slug(),
        plan: planId,
        intervention: interventionId
    })

    let uriForm = Routing.generate(URI_PLAN_FORM_CONTAINER, {
        slug: tools.slug(),
        plan: planId,
        intervention: interventionId
    })

    // console.log('uriFormGenerated : ' + uriForm)

    let isHidden = isFirst ? '' : 'hide'
    let pageInstance = 'pdf-page-instance-' + tools.getRandomInt()

    if ($('body #' + CLASSNAME_PLAN_CONTAINER + planId).length > 0) {
        return
    }

    let newWrapper = `
        <div class="col-12 form-plan-wrapper is-an-pdf-page ` + isHidden + `"
        id="` + pageInstance + `">
            <div class="row" id="` + CLASSNAME_PLAN_CONTAINER + planId + `"
            data-container="` + CLASSNAME_PLAN_CONTAINER + planId + `"
            data-url="` + uri + `">
            </div>
        </div>
    `

    let isActive = isFirst ? 'active bg-menu text-white' : ''

    let $planData = $('body .report-vars .plans #plan-data-' + planId)
    let planLabel = $planData.data('label')
    
    let $formsWrapper = $('body .forms-wrapper')
    let $navWrapper = $('body .page-navigation .row')
    let $navContainer = $('body .page-navigation')
    let navClassName = $navContainer.data('navbar-classname')
    let $reportPanelContainer = $('body #report-panel-container')
    let $reportPanelNavbar = $('body .report-panel-navbar')
    let $reportPlanFormTypeContainer = $('body #report-plan-form-types-container')
    let $reportPlanFormTypeWrapper = $('body .report-plan-form-types-wrapper')
    let $reportPlanFormTypeNavbar = $('body .report-plan-form-types-navbar')

    let nav = `
        <div class="toggle-container text-uppercase font-weight-bold font-size-16 btn nav-link rounded-0 btn-sm switch-bg report-intervention-plan-nav-link ` + isActive + ` plan-` + planId + `" data-target="` + pageInstance + `"
        data-hide-childrens="form-plan-wrapper" data-map-id="plan-` + planId + `"
        data-reference="` + navClassName + `">
            ` + planLabel + `
        </div>
    `

    let formRui = 'form-plan-intervention-' + tools.getRandomInt()

    $reportPlanFormTypeWrapper.append('<div class="col-12 disable-auto-hide generated-container-report-plan-type hide ' + formRui + '" id="' + formRui + '"></div>')
    
    let btnNavbar = `<button class="btn btn-outline-secondary rounded-pill btn-url btn-sm col-12 mb-2 font-size-12 toggle-container nav-link ml-1 toggle-report-form-plan-` + planId + `" 
    data-container="` + formRui + `" data-url="` + uriForm + `"
    data-reference="report-plan-form-types-navbar"
    data-hide-childrens="report-plan-form-types-navbar" data-target="` + formRui + `">
        <i class="fal fa-map"></i>
        ` + planLabel + `
    </button>`

    $reportPlanFormTypeNavbar.append(btnNavbar)

    $formsWrapper.append(newWrapper)
    $navWrapper.append(nav)
    $planContainer = $('body #' + CLASSNAME_PLAN_CONTAINER + planId)

    tools.loadContent($planContainer, uri)
    // tools.loadContent($('body #' + formRui), uriForm)
}

const init = function (autoloading = true) {
    
    $('body .logs-container').append('report::init()')
    $('body .logs-container').append(plan.getMapsInstance().length + ' founded')
    if ($('.plan-interactive').length > 0) {
        plan.clearMapInstance()
    }
    tools.addToDebug('report::init() fired')

    let $dataContainer = $('body #' + REPORT_DATA_CONTAINER)
    let interventionId = $dataContainer.data('intervention')
    const $select = $('body .report-form .select-plans-container select')

    if ($select.length <= 0) {
        return
    }
  
    if (autoloading && $select.hasClass('preloading-is-ready')) {
        return
    }

    $select.addClass('preloading-is-ready')
    const $plansWrapper = $('body .form-plan-wrapper:not(.intervention-details)')

    let $reportLogsContainer = $('body .report-logs')
    $plansWrapper.remove()
    $('body .global-report-plan-intervention-nav-link-' + interventionId).remove()
    let $selectedValues = $select.find(':selected')

    let $includeGlobalInterventionStats = $('body #stats_intervention_includeGlobalInterventionStats')

    // Listener on report label for reporting on view
    let $inputReportLabel = $('body #stats_intervention_label')
    if ($inputReportLabel.length > 0 && !$inputReportLabel.hasClass('initialized')) {
        $inputReportLabel.on('change blur keyup', function (e) {
            e.preventDefault()
            $('body .report-label').html($inputReportLabel.val())
        })
    }

    let isFirst = true
   
    if ($('body .global-plan-intervention-' + interventionId).length <= 0 
    && ($selectedValues.length <= 0 || $includeGlobalInterventionStats.is(':checked'))) {
        let isVisible = 'hide'
        let gbIsActive = ''
        if ($('body .page-navigation .row .toggle-container').length <= 0) {
            isFirst = false
            isVisible = ''
            gbIsActive = 'active bg-menu text-white'
        }
        let uri = Routing.generate(URI_INTERVENTION_CONTAINER, {
            slug: tools.slug(),
            intervention: interventionId
        })
    
        let pageInstance = 'pdf-page-instance-' + tools.getRandomInt()
        
        let newWrapper = `
            <div class="col-12 form-plan-wrapper is-an-pdf-page ` + isVisible + `"
            id="` + pageInstance + `">
                <div class="row global-plan-intervention-` + interventionId + `" id="` + CLASSNAME_PLAN_CONTAINER + interventionId + `"
                data-container="` + CLASSNAME_PLAN_CONTAINER + interventionId + `"
                data-url="` + uri + `">
                </div>
            </div>
        `
       
    
        let $formsWrapper = $('body .forms-wrapper')
        let $navWrapper = $('body .page-navigation .row')
        let $navContainer = $('body .page-navigation')
        let navClassName = $navContainer.data('navbar-classname')
        let $reportPlanFormTypeWrapper = $('body .report-plan-form-types-wrapper')
        let $reportPlanFormTypeNavbar = $('body .report-plan-form-types-navbar')
        
        let formRui = 'form-plan-intervention-' + tools.getRandomInt()

        $reportPlanFormTypeWrapper.append('<div class="col-12 disable-auto-hide generated-container-report-plan-type hide ' + formRui + '" id="' + formRui + '"></div>')
        let uriForm = Routing.generate(URI_INTERVENTION_GLOBAL_FORM_CONTAINER, {
            slug: tools.slug(),
            intervention: interventionId
        })
     
        let btnNavbar = `<button class="btn btn-outline-secondary rounded-pill col-12 mb-2 btn-sm font-size-12 toggle-container btn-url nav-link ml-1 toggle-report-form-plan-` + interventionId + `" 
            data-url="` + uriForm + `" data-container="` + formRui + `"
            data-reference="report-plan-form-types-navbar"
            data-hide-childrens="report-plan-form-types-navbar" data-target="` + formRui + `">
                <i class="fal fa-share-alt"></i>
                Général
            </button>`

        $reportPlanFormTypeNavbar.append(btnNavbar)
        let nav = `
            <div class="btn btn-sm rounded-0 font-weight-bold font-size-16 switch-bg nav-link toggle-container ` + gbIsActive + ` global-report-plan-intervention-nav-link-` + interventionId + `" 
            data-target="` + pageInstance + `"
            data-hide-childrens="form-plan-wrapper" data-map-id="plan-` + interventionId + `"
            data-reference="` + navClassName + `">
                GÉNÉRAL
            </div>
        `
        
        $formsWrapper.append(newWrapper)
        $navWrapper.append(nav)
        let $planContainer = $('body #' + CLASSNAME_PLAN_CONTAINER + interventionId)
        $planContainer.addClass('')

        tools.loadContent($planContainer, uri)
        // tools.loadContent($('body #' + formRui), uriForm)
    }

    let intervalForAppeningPlan = 2000

    $selectedValues.each(function (i, elm) {
        let $selectedValue = $(elm)

        let planId = $selectedValue.val()

        if (planId == undefined || planId.length <= 0) {
            console.log(planId, 'value is undefined')
            return
        }

        setTimeout(function () {
            $reportLogsContainer.append('<div class="col-12">Ajout reportPlan ' + planId + '</div>')
            if (isFirst !== false) {
                isFirst = i === 0 && $('body .page-navigation .row .toggle-container.active').length <= 0
            }
            report.appendPlanForm(planId, isFirst)
            if (i === ($selectedValues.length - 1)) {
                $reportLogsContainer.append('<div class="col-12 bg-aqua-green">All reports is added</div>')
            }
        }, intervalForAppeningPlan)

        intervalForAppeningPlan += 2000
    })
}

const generatePDFV2 = function () {
    let $pdfContainer = $('body #report-pdf')
    console.log($pdfContainer)
    if ($pdfContainer.length <= 0) {
        console.log('pdfContainer not found')
        return
    }

    // PDF Configurations
    let $inputPdfSize = $('body #stats_intervention_pdfSizeType')
    let $checkboxLandscapeMode = $('body #stats_intervention_isLandscapePdfMode')
    let orientation = 'p'

    if ($checkboxLandscapeMode.length > 0) {
        orientation = $checkboxLandscapeMode.is(':checked') ? 'l' : 'p'
    }

    // Default size is landscape
    let pdfHeight = 700
    let pdfWidth = 991

    if (orientation === 'p') {
        pdfHeight = 800
        pdfWidth = 595
    }

    let pdfSize = 1
    let units = ['', 'pt', 'mm', 'cm', 'px']

    if ($inputPdfSize.length > 0) {
        pdfSize = $inputPdfSize.val()
    }

    let pdfUnits = units[pdfSize]

    // Create instance of PDF
    let pdf = new jsPDF(orientation, pdfUnits, [pdfWidth, pdfHeight])

    let $interventionDetail = $('body .intervention-details')

    // First page
    let $inputLabelOfReport = $('body #stats_intervention_label')

    let startPosX = 0
    let startPosY = 0

    if ($inputLabelOfReport.length > 0) {
        pdf.text($inputLabelOfReport.val(), 0, 25)
        startPosY += 25
    }


    if ($interventionDetail.length > 0) {
        let interventionDetailId = $interventionDetail.attr('id')

        if (interventionDetailId === undefined || interventionDetailId === '') {
            console.log('interventionDetail is required (=>' + interventionDetailId + ')')
            return
        }
        console.log('append detail of intervention', 'interventionDetailId => ' + interventionDetailId)

        let $interventionDetailContainer = $('body #' + interventionDetailId)

        console.log($interventionDetailContainer)

        console.log(document.getElementById(interventionDetailId))

        let receiptId = 'receipt-' + tools.getRandomInt()
        $interventionDetailContainer.append('<div id="' + receiptId + '"></div>')
        let receipt = document.getElementById(receiptId)
        html2canvas(document.querySelector('#' + interventionDetailId), {
            scrollX: 0,
            scrollY: -window.scrollY
        }).then(canvas => {
            $(canvas).addClass('generated-plan-canvas')
            $(canvas).attr('id', 'generated-plan-' + tools.getRandomInt())
            // $(canvas).attr('data-plan', $elm.data('plan'))
            $('#' + interventionDetailId).hide('slow', function () {
                receipt.appendChild(canvas)
            })
            // document.body.appendChild(canvas)
        })

        
        // pdf.html(document.getElementById(interventionDetailId), {
        //     // callback: function (doc) {
        //     //     console.log('adding intervention details to jspdf instance')
        //     //     // pdf.add
        //     // },
        //     x: 10,
        //     y: 10
        // })
        // pdf.addPage()
    }

    // pdf.save('pdf-' + tools.getRandomInt() + '.pdf')


}

const generatePdfReady = function () {
    // plan.loader('Génération de votre fichier PDF en cours', false, 1000)
    console.log('generatePDF launched')
    let $lfLoaderInfos = $('body .')
    $lfLoaderInfos.html('Configuration du PDF')
    let $reportContainer = $('body #report-pdf')
    let $reportData = $('body #report-data')
    let interventionId = $reportData.data('intervention')
    let $formInterventionContainer = $('body #statsInterventionFormType')
    let formSettings = {
        includeStats: true,
        includePlans: true,
        includePhotos: true,
        includeDetailByPlan: true,
        includeSeuil:true,
        includeActionMenee: true
    } 

    // Gérer les données à inclure dans le rapport
    if ($formInterventionContainer.length > 0) {
        let $inputIncludeStats = $('body #stats_intervention_includeCharts')
        let $inputIncludePlans = $('body #stats_intervention_includePlan')
        let $inputIncludePhotos = $('body #stats_intervention_includePhoto')
        let $inputIncludeDetailByPlan = $('body #stats_intervention_includeDetailsByPlan')
        let $inputInludeArticle = $('body #stats_intervention_includeArticle')
        let $inputIncludeSeuil = $('body $stats_intervention_includeSeuil')
        let $inputIncludeActionMenee = $('body $stats_intervention_includeActionMenee')

        if ($inputIncludeStats.length > 0 && !$inputIncludeStats.is(':checked')) {
            formSettings.includeStats = false
        }

        if ($inputIncludePlans.length > 0 && !$inputIncludePlans.is(':checked')) {
            formSettings.includePlans = false
        }

        if ($inputIncludePhotos.length > 0 && !$inputIncludePhotos.is(':checked')) {
            formSettings.includePhotos = false
        }

        if ($inputIncludeDetailByPlan.length > 0 && !$inputIncludeDetailByPlan.is(':checked')) {
            formSettings.includeDetailByPlan = false
        }

        if ($inputInludeArticle.length > 0 && !$inputInludeArticle.is(':checked')) {
            formSettings.includeArticle = false
        }

        if ($inputIncludeSeuil.length > 0 && !$inputIncludeSeuil.is(':checked')) {
            formSettings.includeSeuil = false
        }
        if ($inputIncludeActionMenee.length > 0 && !$inputIncludeActionMenee.is(':checked')) {
            formSettings.includeActionMenee = false
        }
    }

    let $inputPdfSize = $('body #stats_intervention_pdfSizeType')
    let $checkboxLandscapeMode = $('body #stats_intervention_isLandscapePdfMode')

    let orientation = $checkboxLandscapeMode.is(':checked') ? 'l' : 'p'
    
    // default l
    let pdfHeight = 700
    let pdfWidth = 991

    if (orientation === 'p') {
        pdfHeight = 800
        pdfWidth = 595
    }
    
    let pdfSize = $inputPdfSize.val()

    let units = ['', 'pt', 'mm', 'cm', 'px']

    let selectedUnits = pdfSize == undefined ? units[1] : units[pdfSize]
    let pdf = new jsPDF(orientation, selectedUnits, [pdfWidth, pdfHeight])
    let $images = $('body .pdf-image')
    let pdfPosY = 0
    let pdfPosX = 0
    let pdfXMargin = 10
    let pdfYMargin = 20
    let textSettings = {
        align: 'left',
        baseline: 'alphabetic',
        angle: 0,
        rotationDirection: 1,
        charSpace: 0,
        lineHeightFactor: 1,
        flags: {
            noBOM: true,
            autoencode: true
        },
        maxWidth: 0,
        renderingMode: 'fill',
    }
    let textFontSize = {
        h1: 18,
        h2: 16,
        h3: 14,
        p: 11
    }

    /* Start - First page */
    // $lfLoaderInfos.html('Création de la 1ère page...')
    pdfPosY += pdfYMargin
    pdfPosX += pdfXMargin

    // report name
    let $inputLabelOfReport = $('body #stats_intervention_label')
    if ($inputLabelOfReport.length > 0) {
        pdf.setFontSize(textFontSize.h1)
        pdf.setFont('times', 'bold')
        pdf.text($inputLabelOfReport.val(), pdfPosX, pdfPosY)
        pdf.setFont('times', 'normal')
        pdfPosY += textFontSize.h1
        pdf.setFontSize(textFontSize.p)
    }

    // adresse intervention
    let $adresseData = $('body .report-vars .adresse')
    if ($adresseData.length > 0) {
        console.log($adresseData.width())
        console.log(pdf.getFontList())
        pdf.setFont('times', 'bold')
        pdf.text('Adresse d\'intervention :', pdfPosX, pdfPosY, textSettings)
        pdf.line(pdfXMargin, (pdfPosY + 2), 120, (pdfPosY +2))
        pdf.setFont('times', 'normal')
        pdfPosY += textFontSize.p + 5
        pdf.text($adresseData.text().trim(), (pdfPosX + 10), pdfPosY, textSettings)
        pdfPosY += textFontSize.p
    }
    /* End - First page */

    // Ajouter détail de l'intervention
    let $interventionDetail = $('body .intervention-details')

    if ($interventionDetail.length > 0) {
        // $lfLoaderInfos.html('Ajout du détail de l\'intervention')
        $interventionDetail.removeClass('hide')
        let interventionDetailId = $interventionDetail.attr('id')

        if (interventionDetailId === undefined || interventionDetailId === '') {
            return
        }

        // Récupération des infos
        let $treatmentTitle = $('body #' + interventionDetailId + ' .treatment-title')
        let $treatmentContent = $('body #' + interventionDetailId + ' .treatment-content')
        let $photos = $('body #' + interventionDetailId + ' .intervention-' + interventionId + '-photo')
        pdf.text($treatmentTitle.text().trim(), pdfXMargin, pdfPosY, textSettings)
        pdfPosY += textFontSize.p
        pdf.text($treatmentContent.text().trim(), pdfXMargin, pdfPosY, textSettings)
        console.log($treatmentContent.text())
        pdfPosY += textFontSize.p

        $photos.each(function (i, elm) {
            let $photo = $(this)
            let photoId = $photo.data('photo-id')
            let $photoComment = $('body .comment-photo-' + photoId)
            let imageNode = new Image()
            imageNode.src = $photo.attr('src')

            let photoHeight = $photo.height()
            let photoWidth = $photo.width()

            console.log('Taille Photo : Height : ' + photoHeight + ' Width : ' + photoWidth)

            let nextPosX = pdfPosX + photoWidth
            
            if (nextPosX > pdfWidth) {
                pdfPosY += photoHeight + pdfYMargin + 15
                pdfPosX = pdfXMargin
            }

            let nextPosY = pdfPosY + photoHeight + pdfYMargin
            
            if (nextPosY > pdfHeight) {
                pdf.addPage()
                pdfPosY = pdfYMargin
            }

            pdf.addImage(imageNode, 'PNG', pdfPosX, pdfPosY, photoWidth, photoHeight)

            // Si la photo a un commentaire
            if ($photoComment.length > 0) {
                pdf.setFontSize(textFontSize.p)
                pdf.text($photoComment.text().trim(), pdfPosX, (pdfPosY + photoHeight + 10)) // +10 pour un peu d'espace après la photo
            }

            pdfPosX += pdfXMargin + photoWidth
        })
    }

    // Ajouter détail global de l'intervention
    let $detailGlobalIntervention = $('body .global-intervention-report')

    if ($detailGlobalIntervention.length > 0) {
        $lfLoaderInfos.html('Ajout des stats globales de l\'intervention')
        pdf.addPage()
        pdfPosY = pdfYMargin
        pdfPosX = pdfXMargin
        let $chartsOfGlobalIntervention = $('body .canvas-chart.global-intervention-' + interventionId)
        
        pdf.setFont('times', 'italic', 'bold')
        pdf.text('Rapport général de l\'intervention n°# du ##/##/####', pdfXMargin, pdfPosY)
        pdf.setFont('times', 'normal', 'normal')
        pdfPosY += textFontSize.p
        
        let chartStartOfY = pdfPosY
        let chartStartOfX = pdfPosX
        let isFirst = true
        let currentPageRemainingHeight = pdfHeight - chartStartOfY
        let currentPageRemainingWidth = pdfWidth - chartStartOfX

        let arrayPosY = []
        let hasAppenedNewPageForNextChart = false

        $chartsOfGlobalIntervention.each(function (i, elm) {
            console.log(arrayPosY)
            let $chart = $(elm)
            let chartWidth = $chart.width()
            let chartHeight = $chart.height()
            let chartType = $chart.data('chart')
            let chartReference = $chart.data('chart-reference')
            let hasCookieForGraph = tools.getCookie(chartReference)
            chartType = hasCookieForGraph === false ? chartType : hasCookieForGraph
            console.log('chartType : ' + chartType)
            if (chartHeight > pdfHeight) {
                chartHeight = chartHeight / 2
                chartWidth = chartWidth / 2
            }

            if (chartType == 'bar') {
                chartHeight = 450
            } else {
                chartHeight = 225
            }
            chartWidth = 225


            // let heightIsRepositionned = false
            // let widthIsRepositionned = false
            // if (!heightIsRepositionned && currentPageRemainingWidth < chartWidth) {
            //     let newRemainingOnY = currentPageRemainingHeight - chartHeight
            //     let chartStartOfYIsUpadted = false
            //     console.log('New Remaining on Y : ' + newRemainingOnY)
            //     if (newRemainingOnY < chartHeight) {
            //         chartStartOfYIsUpadted = true
            //         console.log('new remaining case')
            //         pdf.addPage()
            //         chartStartOfY = 40
            //         currentPageRemainingHeight = pdfHeight - 40
            //     }
            //     if (!chartStartOfYIsUpadted) {
            //         chartStartOfY += chartHeight
            //     }
            //     chartStartOfX = 40
            //     currentPageRemainingWidth = pdfWidth - 40
            //     widthIsRepositionned = true
            // }
            // if (currentPageRemainingHeight < chartHeight) {
            //     console.log('repos on X and Y, Y case and add page')
            //     pdf.addPage()
            //     chartStartOfY = pdfYMargin
            //     heightIsRepositionned = true
            //     chartStartOfX = pdfXMargin
            //     currentPageRemainingWidth = pdfWidth - chartStartOfX
            //     currentPageRemainingHeight = pdfHeight - chartStartOfY
            // }
            let nextPosXChart = pdfPosX + chartWidth
            console.log('-----------------------------------------------------------------')
            console.log('NextPosX for chart = ' + nextPosXChart + ' pdfWidth = ' + pdfWidth)
            
            if (nextPosXChart > pdfWidth) {
                // checker la dimension en y des graphs précédents
                let tempNextX = 0
                let hasPosOk = false
                for (let i = 0; i < arrayPosY.length; i++) {
                    let simulateNextY = arrayPosY[i].y + chartHeight + 15
                    console.log('simulateNextY => ' + simulateNextY)
                    if (simulateNextY < pdfHeight) {
                        tempNextX = arrayPosY[i].chartW + pdfXMargin + 15
                        console.log('tempNextX = ' + tempNextX)
                        hasPosOk = true
                    }
                }
                if (hasPosOk) {
                    pdfPosY += chartHeight + pdfYMargin + 15
                    pdfPosX = pdfXMargin + tempNextX
                } else {
                    pdf.addPage()
                    pdfPosY = pdfYMargin
                    pdfPosX = pdfXMargin
                    arrayPosY = []
                }
            }

            // if (arrayPosY.length > 0) {
            //     let tempNextX = 0
            //     let hasPosOk = false
            //     for (let i = 0; i < arrayPosY.length; i++) {
            //         tempNextX = arrayPosY[i].chartW + pdfXMargin + 15
            //         let simulateNextY = arrayPosY[i].y + chartHeight + 15
            //         if (tempNextX === arrayPosY[i].x && simulateNextY < pdfHeight) {
            //             hasPosOk = false
            //         }
            //     }
            //     if (hasPosOk) {
            //         pdfPosY += chartHeight + pdfYMargin + 15
            //         pdfPosX = pdfXMargin + tempNextX
            //     } else {
            //         pdf.addPage()
            //         pdfPosY = pdfYMargin
            //         pdfPosX = pdfXMargin
            //         arrayPosY = []
            //     }
            // }

            let nextPosYChart = pdfPosY + chartHeight + pdfYMargin
            
            if (nextPosYChart > pdfHeight) {
                pdf.addPage()
                pdfPosY = pdfYMargin
                arrayPosY = []
            }

            let chartId = $chart.attr('id')
            
            if (chartId === undefined) {
                console.log('chart need an id for export in pdf')
                return
            }
            
            let canvas = document.querySelector('#' + chartId)
            
            let chartImg = canvas.toDataURL("image/png", 0.95)
            
            console.log('Chart dim : height => ' + chartHeight + ' width => ' + chartWidth)
            pdf.addImage(chartImg, 'JPEG', pdfPosX, pdfPosY, chartWidth, chartHeight)
            pdfPosX += chartWidth + 25
            arrayPosY.push({x: pdfPosX, y: pdfPosY, chartH: chartHeight, chartW: chartWidth})
            // currentPageRemainingWidth -= chartWidth + 25
            // chartStartOfY += chartHeight
            isFirst = isFirst ? false : isFirst
            console.log('---------------------------------')
        })

        pdf.addPage()
        let $relevePiegeTable = $('body .report-releves-pieges.global-intervention-' + interventionId)
        let $actionAMenerTable = $('body .actions-a-mener-table.global-intervention-' + interventionId)
        let $articleTable = $('body .articles-table.global-intervention-' + interventionId)
        
        if ($relevePiegeTable.length > 0) {
            let relevePiegeTableId = $relevePiegeTable.attr('id')
            pdf.autoTable({
                html: '#'+relevePiegeTableId,
                // theme: 'css',
                useCss: true,
                didDrawPage: (HookData) => {
                    // console.log(HookData)
                },
                didDrawCell: (data) => {
                }
            })
        }
        // pdf.addPage()

        if ($actionAMenerTable.length > 0) {
            let actionAMenerTableId = $actionAMenerTable.attr('id')
            pdf.autoTable({
                html: '#'+actionAMenerTableId,
                useCss: true,
                didDrawPage: (HookData) => {
                }
            })
        }

        if ($articleTable.length > 0 ) {
            let articleTableId = $articleTable.attr('id')
            pdf.autoTable({
                html: '#'+articleTableId,
                useCss: true,
                didDrawCell: (HookData) => {

                } 
            })
        }
    }

    // Ajouter chaque plan
    $images.each(function (i, elm) {
        // $lfLoaderInfos.html('Ajout du plan n°' + i)
        let $image = $(elm)
        let planId = $image.data('plan')
        let tableHeight = 0
        let $planTitle = $('body .title-plan-' + planId)
        pdf.addPage()
        console.log($planTitle, $planTitle.html())
        pdf.text($planTitle.html(), 5, 5)
        let imageWidth = $image.width() > pdfWidth ? (pdfWidth-50) : $image.width()
        let imageHeight = $image.height() > pdfHeight ? (pdfHeight-50) : $image.height()
        pdf.addImage(elm, 'PNG', 25, 25, imageWidth, imageHeight)
        pdf.addPage()

        // Détails de chaque plan
        if (formSettings.includeDetailByPlan == true) {
            let relevePiegeTableId = '#releve-piege-report-details-' + planId
            let actionAMenerTableId = '#stats-table-plan-' + planId
            let articleTableId = '#report-articles-' + planId
            let seuilTableId = '#releve-piege-report-seuil' + planId   
            
            let $relevePiegeTable = $('body .report-releves-pieges.intervention-' + interventionId + '.plan-' + planId)
            let $actionAMenerTable = $('body ' + actionAMenerTableId)
            let $articleTable = $('body' + articleTableId)
            let $seuilTable = $('body' + seuilTableId)

            // Tableau des relevés de piège associé au plan
            if ($relevePiegeTable.length > 0) {
                relevePiegeTableId = $relevePiegeTable.attr('id')
                pdf.autoTable({
                    html: '#' + relevePiegeTableId,
                    // theme: 'css',
                    useCss: true,
                    didDrawPage: (HookData) => {
                        // console.log(HookData)
                    },
                    didDrawCell: (data) => {
    
                        // var td = data.cell.raw;
                        // // console.log('TD => ', td)
                        // var img = td.getElementsByTagName('img')[0];
                        // if (img == undefined) {
                        //     return
                        // }
                        // console.log('IMG => ', img, 'SRC => ' + img.src)
                        // let imgSrc = img.src
                        // var dim = data.cell.height - data.cell.padding('vertical');
                        // console.log('DIM => ', dim)
                        // console.log('DATA.CELL => ', data.cell, 'DATA.CELL.X => ' + data.cell.x, 'DATA.CELL.Y => ' + data.cell.y)
                        // var textPos = data.cell.textPos;
                        // let isSvg = /.svg/
                        // console.log('IS SVG ?')
                        // if (isSvg.test(imgSrc)) {
                        //     console.log('IS SVG !')
                        //     let svgAsTxt = new XMLSerializer().serializeToString()
                        // } else {
                            // }
                            // console.log(img.src)
                        // pdf.addImage(img.src, data.cell.x, data.cell.y, 10, 10);
                        // let s = new XMLSerializer()
                        // pdf.addImage(img.src, data.cell.x, data.cell.y, 10, 10);
                        // let si = s.serializeToString(img)
                        // console.log('si => ' + si)
                        // pdf.addSvgAsImage(si, data.cell.x, data.cell.y, 40, 40)
                    }
                })
            }
            pdf.addPage()
    
            if ($actionAMenerTable.length > 0) {
                pdf.autoTable({
                    html: actionAMenerTableId,
                    useCss: true,
                    didDrawPage: (HookData) => {
                    }
                })
            }
            if ($seuilTable.length > 0 ) {
                pdf.autoTable({
                    html: seuilTableId,
                    useCss: true,
                    didDrawPage: (HookData) => {

                    }
                })
            }
            if ($articleTable.length > 0) {
                pdf.autoTable({
                    html: articleTableId,
                    useCss: true,
                    didDrawPage: (HookData) => {
                    }
                })
            }
        }

        // Stats pour chaque plan
        if (formSettings.includeStats == true) {
            // récupérer les plans liés au graphique
            let $planCharts = $('body .pdf-chart-' + planId)
    
            let chartStartOfY = pdf.lastAutoTable.finalY + 50 + 80
            let chartStartOfX = 40
            let isFirst = true
    
            console.log('PDF HEIGHT : ' + pdfHeight)
            console.log('PDF WIDTH : ' + pdfWidth)
    
            let currentPageRemainingHeight = pdfHeight - chartStartOfY
            let currentPageRemainingWidth = pdfWidth - chartStartOfX
    
            $planCharts.each(function (i, elm) {
                let $chart = $(elm)
                let chartWidth = $chart.width()
                let chartHeight = $chart.height()
                let heightIsRepositionned = false
                let widthIsRepositionned = false
                console.log('---------------------------------')
                console.log('ChartHeight : ' + chartHeight)
                console.log('ChartWidth : ' + chartWidth)
                console.log('chartStartOfY : ' + chartStartOfY)
                console.log('chartStartOfX : ' + chartStartOfX)
                console.log('remaining Y : ' + currentPageRemainingHeight)
                console.log('remaining X : ' + currentPageRemainingWidth)
                if (currentPageRemainingHeight < chartHeight) {
                    console.log('repos on X and Y, Y case and add page')
                    pdf.addPage()
                    chartStartOfY = 40
                    heightIsRepositionned = true
                    chartStartOfX = 40
                    currentPageRemainingWidth = pdfWidth - 40
                    currentPageRemainingHeight = pdfHeight - 40
                }
                if (!heightIsRepositionned && currentPageRemainingWidth < chartWidth) {
                    let newRemainingOnY = currentPageRemainingHeight - chartHeight
                    console.log('New Remaining on Y : ' + newRemainingOnY)
                    let chartStartOfYIsUpadted = false
                    console.log('New Remaining on Y : ' + newRemainingOnY)
                    if (newRemainingOnY < chartHeight) {
                        chartStartOfYIsUpadted = true
                        console.log('new remaining case')
                        pdf.addPage()
                        chartStartOfY = 40
                        currentPageRemainingHeight = pdfHeight - 40
                    }
                    if (!chartStartOfYIsUpadted) {
                        chartStartOfY += chartHeight
                    }
                    chartStartOfX = 40
                    currentPageRemainingWidth = pdfWidth - 40
                    widthIsRepositionned = true
                }
    
                let chartId = $chart.attr('id')
                
                if (chartId === undefined) {
                    console.log('chart need an id for export in pdf')
                    return
                }
                
                let canvas = document.querySelector('#' + chartId)
                if (canvas == undefined) {
                    console.error('canvas #' + chartId + ' not found')
                    return
                }
                console.log(canvas)
                let chartImg = canvas.toDataURL("image/jpeg", 0.5)
                
                pdf.addImage(chartImg, 'JPEG', chartStartOfX, chartStartOfY, chartWidth, chartHeight)
                chartStartOfX += chartWidth + 25
                currentPageRemainingWidth -= chartWidth + 25
                // chartStartOfY += chartHeight
                isFirst = isFirst ? false : isFirst
                console.log('---------------------------------')
            })
        }
    })

    pdf.save('pdf-' + tools.getRandomInt() + '.pdf')
}

const appendDetailsOfInterventionInReport = function (pdf, interventionContainerId) {
    let $interventionDetail = $('body .intervention-details')
    $interventionDetail.removeClass('hide')
    let interventionDetailId = $interventionDetail.attr('id')

    if (interventionDetailId === undefined || interventionDetailId === '') {
        console.log('interventionDetail is required (=>' + interventionDetailId + ')')
        return
    }
    console.log('append detail of intervention', 'interventionDetailId => ' + interventionDetailId, document.getElementById(interventionDetailId))

    // Récupération des infos
    let $title = $('body #' + interventionDetailId + ' h2')
    let $treatmentContent = $('body #' + interventionDetailId + ' .treatment')
    let photos = $('body #' + interventionDetailId + ' .intervention-' + interventionDetailId + '-photo')

    pdf.text($title.html(), 5, 5)
    pdf.text($treatmentContent.html(), 15, 15)

    // pdf.html(document.getElementById(interventionDetailId), {
    //     // callback: function (doc) {
    //     //     doc.save()
    //     // },
    //     x: pdfPosX,
    //     y: pdfPosY
    // })
    pdf.addPage()
}

const generatePdf = function () {
    plan.loader('Génération de votre rapport personnalisé en cours, merci pour votre patience.', null, 1, true)
    report.newGenerateType()
}

const newGenerateType = async function () {
    // console.log('in test()')
    await report.makePreview()
    
    let $reportLogsContainer = $('body .report-logs.logs-container')
    let $charts = $('body .canvas-chart')
    let $plans = $('body .generated-plan-canvas')
    let $lfLoaderInfos = $('body .lf-loader .loader-infos')
    $lfLoaderInfos.html('Mise à niveau des éléments terminés. Début de la phase de récupération.')

    $reportLogsContainer.append('<div>' + $charts.length + ' graphiques à transformer</div>')
    $reportLogsContainer.append('<div>' + $plans.length + ' plans à transformer</div>')

    let formData = new FormData()

    let $inputInterventionId = $('body .input-intervention-id')
    let $inputReportLabel = $('body #stats_intervention_label')
    let $selectedPlans = $('body #stats_intervention_plans')
    let $includeGlobalInterventionStats = $('body #stats_intervention_includeGlobalInterventionStats')
    let $checkboxLandscapeMode = $('body #stats_intervention_isLandscapePdfMode')
    let $checkboxIncludePlans = $('body #stats_intervention_includePlan')
    let $checkboxIncludeCharts = $('body #stats_intervention_includeCharts')
    let $checkboxIncludePictures = $('body #stats_intervention_includePhoto')
    let $checkboxIncludeDetailsByPlans = $('body #stats_intervention_includeDetailsByPlan')

    let interventionId = $inputInterventionId.val()

    $lfLoaderInfos.html('Récupération des options du rapport')

    formData.append('intervention-id', interventionId)
    formData.append('report-label', $inputReportLabel.val())
    formData.append('selected-plans', JSON.stringify($selectedPlans.val()))

    if ($includeGlobalInterventionStats.is(':checked')) {
        formData.append('report-global-intervention', true)
    }
    if ($checkboxLandscapeMode.is(':checked')) {
        formData.append('landscape-mode', 'l')
    } else {
        formData.append('landscape-mode', 'p')
    }
    if ($checkboxIncludePlans.is(':checked')) {
        formData.append('include-plans', true)
    }
    if ($checkboxIncludeCharts.is(':checked')) {
        formData.append('include-charts', true)
    }
    if ($checkboxIncludePictures.is(':checked')) {
        formData.append('include-pictures', true)
    }
    if ($checkboxIncludeDetailsByPlans.is(':checked')) {
        formData.append('include-details-plans', true)
    }


    let chartIds = []

    $lfLoaderInfos.html('Récupération de ' + $charts.length + ' graphique(s)')
    $charts.each(function (i, elm) {
        let $chart = $(elm)
        let chartId = $chart.attr('id')

        if (chartId == undefined || chartId.length <= 0) {
            console.error('report.js::newGenerateType()::834 $chart elm need an valid id (given ' + id + ') to convert in b64')
            return
        }

        let canvas = document.getElementById(chartId)
        let dataURL = canvas.toDataURL()

        $reportLogsContainer.append('<div>Data URL generated for chart #' + chartId + '</div>')

        let chartObject = {
            id: chartId,
            interventionId: $chart.data('intervention'),
            image: dataURL,
            position: i
        }

        let $chartLegends = $('body .legend-chart-' + chartId)
        let nbChartLegends = $chartLegends.length
        if ($chartLegends.length > 0) {
            // console.log('chartLegends : ' + $chartLegends.length + ' detected')

            $chartLegends.each(function (i, chartLegend) {
                let $chartLegend = $(chartLegend)
                let position = $chartLegend.data('position') 
                let label = $chartLegend.data('label') 
                let color = $chartLegend.data('color')
                let value = $chartLegend.data('value')
                formData.append('legend-' + i + '-' + chartId + '-label', label)
                formData.append('legend-' + i + '-' + chartId + '-position', position)
                formData.append('legend-' + i + '-' + chartId + '-color', color)
                formData.append('legend-' + i + '-' + chartId + '-value', value)
            })

        }

        formData.append('nb-legend-chart-' + chartId, nbChartLegends)

        chartIds.push(chartId)
        formData.append('image-' + chartId, dataURL)
        formData.append('interventionId-' + chartId, $chart.data('intervention'))
        formData.append('planId-' + chartId, $chart.data('plan'))
        formData.append('position-' + chartId, i)
        formData.append('height-' + chartId, $chart.height())
        formData.append('width-' + chartId, $chart.width())

        // console.log('------- START CHART OBJECT -----------')
        // console.info(chartObject)
        // console.log('------- END CHART OBJECT -----------')
    })

    formData.append('chartIds', JSON.stringify(chartIds))

    let planIds = []
    $lfLoaderInfos.html('Récupération de ' + $plans.length + ' plan(s)')
    $plans.each(function (i, elm) {
        let $plan = $(elm)
        let planId = $plan.attr('id')

        if (planId == undefined || planId.length <= 0) {
            console.error('report.js::newGenerateType()::873 $plan elm need an valid id (given ' + id + ') to convert in b64')
            return
        }

        let canvas = document.getElementById(planId)
        let dataURL = canvas.toDataURL()

        $reportLogsContainer.append('<div>Data URL generated for plan #' + planId + '</div>')

        let planObject = {
            id: planId,
            interventionId: $plan.data('intervention-id'),
            planId: $plan.data('plan-id'),
            image: dataURL,
            position: i,
            height: $plan.height(),
            width: $plan.width()
        }

        formData.append('planId-' + planId + '-image-plan-' + planObject.planId + '-intervention-' + planObject.interventionId, dataURL)
        formData.append('planId-' + planObject.id + '-plan-id', planObject.planId)
        formData.append('planId-' + planObject.id + '-intervention-id', planObject.interventionId   )
        formData.append('planId-' + planObject.id, planObject.id)
        formData.append('planId-' + planObject.id + '-height', planObject.height)
        formData.append('planId-' + planObject.id + '-width', planObject.width)
        formData.append('position-' + planObject.id, i)

        // console.log('------- START PLAN OBJECT -----------')
        // console.info(planObject)
        // console.log('------- END PLAN OBJECT -----------')

        planIds.push(planId)
    })


    formData.append('planIds', JSON.stringify(planIds))
    console.log(formData)
    const postUrl = Routing.generate('app_customer_report_post_form', {
        slug: tools.slug()
    })

    $lfLoaderInfos.html('Envoi des informations pour génération de votre rapport')
    
    $.post({
        url: postUrl,
        data: formData,
        processData: false,
        contentType: false,
       
        success: function (data) {
            if (data.state != true) {
                console.error('La génération du rapport a échoué.')
                return
            }

            $('body .loader-ico').removeClass('fa-spin').addClass('fa-check-circle').removeClass('fa-circle-notch')
            $('body .loader-results').append('<a class="btn btn-d-blue rounded-pill" href="' + data.link + '" target="_blank" title="Télécharger le rapport généré ?"><i class="fal fa-file-download"></i> Télécharger le rapport</a>')
            $('body .loader-results').fadeIn('slow')
            $('body .loader-actions').slideDown('slow')
            loader.flashLoader()
        },
        
        complete: function () {
            $('body .loader-message').html('Génération de votre rapport terminée !')
            $lfLoaderInfos.hide()
            loader.flashLoader()
            let $elmsIsNotInPdf = $('body .not-in-pdf')
            let $pdfPages = $('body .is-an-pdf-page')
            let $resizeOnPreviewElms = $('body .resize-on-preview')
            $('.leaflet-touch .leaflet-bar').show()

            $pdfPages.hide()
            $('body .page-navigation.not-in-pdf').show()
            $('body .plan-title').show()
            $('body .form-plan-wrapper .resize-on-preview').hide()
            $('body .details-report-container').hide()
            $('body .articles-report-container').hide()
            $('body .seuil-report-container').hide()
            $('body .plan-report-container').css('overflow', 'hidden')
            $('body .plan-report-container').css('position', 'relative')
            $('body .return-plan-container').hide()
            $('body .legend-plan-switcher').removeClass('d-none').addClass('d-flex')
            $('body .plan-title .toggle-container.active').each(function (i, elm) {
                let $e = $(this)
                let t = $e.data('target')
                $('body #' + t).show()
            })
            $('body .contract-action-menee-report-container').hide()

            let $plans = $('body .interactive-plan')
            $plans.each(function (i, elm) {
                let $plan = $(elm)
                $plan.css('width', $('body #page-container').width() + 'px')
                $plan.css('max-width', $('body #page-container').width() + 'px')
                $plan.css('overflow', 'hidden')
            })
            $('body .form-plan-wrapper').hide()
            let target = $('body .page-navigation .toggle-container.active').data('target')
            let $target = $('body #' + target)
            $target.show()
        }
    })
}

const makePreview = async function (elm = null) {
    
    let $btn = undefined
    let $lfLoaderInfos = $('body .lf-loader .loader-infos')
    $lfLoaderInfos.html('Préparation des éléments en cours')
    if (elm != null) {
        $btn = $(elm)
    }

    let $elmsIsNotInPdf = $('body .not-in-pdf')
    let $pdfPages = $('body .is-an-pdf-page')
    
    let $resizeOnPreviewElms = $('body .resize-on-preview')
    $('.leaflet-touch .leaflet-bar').hide()
    $('body .plan-report-container').show()
    $('body .return-plan-container').show()
    $('body .plan-report-container').css('position', 'absolute')
    $('body .legend-plan-switcher').removeClass('d-flex').addClass('d-none')
    $('body .in-pdf').show()
    $pdfPages.each(function (i, elm) {
        let $pdfPage = $(elm)

        if (!$pdfPage.is(':visible')) {
            $pdfPage.slideDown()
        }
    })

    $resizeOnPreviewElms.each(function (i, elm) {
        let $resizeElm = $(elm)
        let currentCol = $resizeElm.data('current-col')
        let resizeTo = $resizeElm.data('resize-to')

        $resizeElm.removeClass(currentCol).addClass(resizeTo)
    })

    $elmsIsNotInPdf.slideUp('slow')

    // Chaque plan
    let $plans = $('body .interactive-plan')
    $lfLoaderInfos.html('Mise à niveau de ' + $plans.length + ' plan(s)')
    $plans.each(function (i, elm) {
        let $plan = $(elm)
        let planHeight = $plan.data('height')
        let planWidth = $plan.data('width')
        // planHeight = planHeight > 500 ? 500 : planHeight
        $plan.css('width', planWidth + 'px')
        $plan.css('max-width', planWidth + 'px')
        $plan.css('height', planHeight + 'px')
        $plan.css('overflow', 'visible')
        $plan.css('max-height', planHeight + 'px')
    })

    let currentMapsInstance = plan.getMapsInstance()
    let defaultTimer = 7500
    defaultTimer += (2500 * (currentMapsInstance.length + 1))
    
    // console.log('Default timer = ' + defaultTimer)
    // console.log('CurrentMapsInstance = ' + currentMapsInstance.length)

    setTimeout(function () {
        for (var i = 0; i < currentMapsInstance.length; i++) {
            let mapInstance = currentMapsInstance[i]
            
            // setTimeout(function () {
                mapInstance.setZoom(0)
                mapInstance.invalidateSize()
            // }, 500)
        }
    }, 1000)

    $lfLoaderInfos.html('Préparation de la mise à niveau des éléments ~ ' + (defaultTimer / 1000) + ' sec')
    await tools.sleep(8000)

    let $planCaptures = $('body .capture-plan')
    // console.log('Default timer = ' + defaultTimer)

    let needed = $planCaptures.length
    let current = 0
    $planCaptures.each(function (i, elm) {
        $lfLoaderInfos.html('Ajustement du plan ' + i + ' en cours...')
        // console.log('each an capture-plan')
        let $elm = $(elm)
        let targetId = $elm.data('target')
        if (targetId == undefined || targetId == '') {
            return
        }
        let node = document.getElementById(targetId)
        let receiptId = 'return-' + targetId
        let $receipt = $('body #' + receiptId)
        let $origin = $('body #' + targetId)
        $receipt.addClass('py-0 my-0')
        let receipt = document.getElementById(receiptId)
        // console.log('receipt id = ' + receiptId)
        $receipt.css('height', $origin.height())
        $receipt.css('min-height', $origin.height())
        $receipt.css('width', $origin.width())
        $receipt.css('min-width', $origin.width())
        html2canvas(document.querySelector('#' + targetId), {
            scrollX: 0,
            scrollY: -window.scrollY,
            useCORS: true,
        }).then(canvas => {
            $('.report-logs').append('<div>Plan is transformed (to canvas)</div>')
            $(canvas).addClass('generated-plan-canvas')
            $(canvas).attr('id', 'generated-plan-' + tools.getRandomInt())
            // console.log('plan planId : ' + $origin.data('plan-id'))
            // console.log('plan interventionId : ' + $origin.data('intervention-id'))
            $(canvas).attr('data-plan-id', $origin.data('plan-id'))
            $(canvas).attr('data-intervention-id', $origin.data('intervention-id'))
            receipt.appendChild(canvas)
            current++
        })
    })

    await tools.sleep($plans.length * 3000)
    $lfLoaderInfos.html('Mise à niveau des éléments ~ ' + (($plans.length * 3000) / 1000) + ' sec')
    return true
}

const makePreviewOld = function (elm) {
    // plan.loader('Prévisualisation de vos données en cours', false, 15000)
    let $logsContainer = $('body .report-logs')
    $logsContainer.append('<span>makePreview()</span>')
    const $btn = $(elm)
    let $elmsIsNotInPdf = $('body .not-in-pdf')
    let $pdfPages = $('body .is-an-pdf-page')
    let $planTitles = $('body .plan-titles')
    $planTitles.addClass('font-size-12')

    let $pdfInLandscape = $('body #stats_intervention_isLandscapePdfMode')

    let pdfHeight = 650
    let pdfWidth = 841

    if ($pdfInLandscape.length > 0) {
        if ($pdfInLandscape.is(':checked')) {
            console.log('PDF in landscape mode')
            pdfHeight = 841
            pdfWidth = 1024
            $('body #report-pdf').css('height', '100%')
            // $('body #report-pdf').css('width', '841px')
        }
    }
    let $interventionDetail = $('body .intervention-details')

    if ($interventionDetail.length > 0) {
        let interventionDetailId = $interventionDetail.attr('id')

        if (interventionDetailId === undefined || interventionDetailId === '') {
            console.log('interventionDetail is required (=>' + interventionDetailId + ')')
            return
        }
        console.log('append detail of intervention', 'interventionDetailId => ' + interventionDetailId)

        let $interventionDetailContainer = $('body #' + interventionDetailId)

        console.log($interventionDetailContainer)

        console.log(document.getElementById(interventionDetailId))

        let rId = 'receipt-' + tools.getRandomInt()
        $interventionDetailContainer.parent().prepend('<div id="' + rId + '"></div>')
        let r = document.getElementById(rId)
        html2canvas(document.querySelector('#' + interventionDetailId), {
            scrollX: 0,
            scrollY: -window.scrollY
        }).then(canvas => {
            $(canvas).addClass('pdf-image')
            $(canvas).attr('id', 'myPersonalImg-' + tools.getRandomInt())
            // $(canvas).attr('data-plan', $elm.data('plan'))
            $('#' + interventionDetailId).hide('slow', function () {
                r.appendChild(canvas)
            })
            // document.body.appendChild(canvas)
        })

        
        // pdf.html(document.getElementById(interventionDetailId), {
        //     // callback: function (doc) {
        //     //     console.log('adding intervention details to jspdf instance')
        //     //     // pdf.add
        //     // },
        //     x: 10,
        //     y: 10
        // })
        // pdf.addPage()
    }

    let $svgs = $('body .is-svg-img')
    $svgs.each(function (i, elm) {
        let $svg = $(elm)
        $svg.attr('id', 'te-' + tools.getRandomInt())
        let id = $svg.attr('id')
        $('body #' + id + ' path').css('fill', 'red').css('stroke', 'blue')
        let de = document.getElementById(id)
        let ser = new XMLSerializer().serializeToString(de)
        let b64 = window.btoa(ser)
        console.log('SVG ELM : ', $svg, 'ID => ' + id, 'TO64 => ' + b64)
        let src = 'data:image/jpeg;base64,' + b64
        // $('body').append(src)
        let d = document.createElement('img')
        // d.style.background = 'url(' + src + ')'
        // d.style.backgroundRepeat = 'no-repeat'
        d.style.height = 10 + 'px'
        d.style.backgroundColor = 'red'
        d.src = src
        d.style.width = 10 + 'px'
        $svg.parent().append(d)
        $svg.hide()
        // $svg.attr('src', 'data:image/svg+xml;base64,' + b64)

    })

    $pdfPages.each(function (i, elm) {
        let $pdfPage = $(elm)

        if (!$pdfPage.is(':visible')) {
            $pdfPage.show('slow')
        }

        $pdfPage.addClass('pdf-page-wrapper').removeClass('col-lg-5 col-md-10 col-12').addClass('col-12')

        let pdfPageId = $pdfPage.attr('id')
        console.log('pdfPage id = ' + pdfPageId)

        let $associatedPlan = $('body #' + pdfPageId + ' .interactive-plan')
        let $pdfPagePlanContainer = $('body #' + pdfPageId + ' .resize-on-preview')

        if ($pdfPagePlanContainer.length > 0) {
            let currentCol = $pdfPagePlanContainer.data('current-col')
            let nextCol = $pdfPagePlanContainer.data('resize-to')
            $pdfPagePlanContainer.removeClass(currentCol).addClass(nextCol)
        }

        let planId = $associatedPlan.attr('id')
        let planHeight = $associatedPlan.data('height')
        // let planHeight = 500
        // let planWidth = 500
        let planWidth = $associatedPlan.data('width')
        $associatedPlan.css('width', planWidth + 'px')
        $associatedPlan.css('height', planHeight + 'px')


        // $pdfPage.css('padding', '0px 15px')
        // if (planWidth < pdfWidth) {
        //     console.log('plan success the condition planWidth < pdfWidth, launch the resize event')
        //     console.info('plan id : ' + planId + ' current height : ' + $associatedPlan.height() + 'px redefined to ' + $associatedPlan.data('height') + 'px')
        // }
        // if (planHeight < 700) {
        // } else {
        //     $associatedPlan.css('height', '700px')
        // }

        // let map = L.map(planId)
        // console.log('map = ', map)

        // console.log('app-backend gte mapsInstance => ' + plan.getMapsInstance())

        // setTimeout(function () {
        //     console.log('plan id (' + planId + ') invalidate size fired')
        //     map.invalidateSize()
        // }, 350)


    })

    let currentMapsInstance = plan.getMapsInstance()
    
    // $('body #report-pdf').parent().css('width', pdfWidth + 'px')
    $('body #report-pdf').parent().addClass('px-3')
    // $('body #report-pdf').parent().removeClass('col-12')
    // $('body #report-pdf').addClass('ml-1')
    $elmsIsNotInPdf.hide('slow')
    console.log('currentMapsInstance.length => ' + currentMapsInstance.length)
    console.log(currentMapsInstance)
    setTimeout(function () {
        for (var i = 0; i < currentMapsInstance.length; i++) {
            let mapInstance = currentMapsInstance[i]
            
            setTimeout(function () {
                console.info('resize the MAP')
                mapInstance.setZoom(0)
                mapInstance.invalidateSize()
                // mapInstance.fitBounds([
                //     [0, 0],
                //     [pdfWidth, pdfHeight]
                // ])
                // console.log('MAP INSTANCE N°' + i)
                // console.log('MAP SIZE : ' + mapInstance.getSize())
                // console.log('MAP PIXELS BOUNDS : ' + mapInstance.getPixelBounds())
                // console.log('MAP PIXELS ORIGIN : ' + mapInstance.getPixelOrigin())
                // console.log('MAP PIXELS WORLD BOUNDS : ' + mapInstance.getPixelWorldBounds())
                // console.log('MAP ZOOM : ' + mapInstance.getZoom())
                // console.log(mapInstance)
            }, 500)
        }
    }, 750)
    // $('body #report-pdf').parent().removeClass('col-12').addClass('col-7 mx-auto')
    $('body #report-pdf .leaflet-top .leaflet-control-zoom').hide('slow')
    $btn.addClass('active')

    let $toCaptures = $('body .capture-plan')
    console.log('toCaptures.length => ' + $toCaptures.length)

    // setTimeout(function () {
        console.log('launch html2canvas')
        $toCaptures.each(function (i, elm) {
            let $elm = $(elm)
            let targetId = $elm.data('target')
            let node = document.getElementById(targetId)
            console.log(targetId)
            console.log(node, node.clientHeight, node.clientWidth, node.getClientRects())
            let receiptId = 'return-' + targetId
            let $receipt = $('body #' + receiptId)
            $receipt.addClass('py-0 my-0')
            let receipt = document.getElementById(receiptId)
            html2canvas(document.querySelector('#' + targetId), {
                scrollX: 0,
                scrollY: -window.scrollY
            }).then(canvas => {
                $(canvas).addClass('pdf-image')
                $(canvas).attr('id', 'myPersonalImg-' + tools.getRandomInt())
                $(canvas).attr('data-plan', $elm.data('plan'))
                $('#' + targetId).hide('slow', function () {
                    receipt.appendChild(canvas)
                })
                // document.body.appendChild(canvas)
            })
        })
    // }, 3500)
    tools.sleep()
}

const report = {
    appendPlanForm,
    init,
    generatePdf,
    makePreview,
    generatePDFV2,
    generatePdfReady,
    appendDetailsOfInterventionInReport,
    newGenerateType
}

export default report