// import 'leaflet.browser.print/dist/leaflet.browser.print' impression leaflet

import L from './leaflet';
import M from './leaflet.custom.control';
import anime from 'animejs/lib/anime.es.js';
import html2canvas from "html2canvas/dist/html2canvas";
import loaderLib from './loader';
import moment from 'moment';
import tools from './tools';
window.html2canvas = html2canvas;

var mapsInstance = [];

const loadPanelListingPieges = function (url) {

    const $panels = $('body .panel-pieges');

    $panels.each(function (i, elm) {
        const $panel = $(elm);

        if ($panel.hasClass('initialized')) {
            return;
        }
        $panel.addClass('initialized');

        tools.loadContent($panel, url);
    });
};

const loadMarker = function (feeds, mapInstance, ratio, img) {
    plan.exportExcel()
    plan.planDebug('<i class="fal fa-spinner fa-spin"></i> Start loading marker of plan');
    var yx = L.latLng;
    let $planContainer = $(img);
    let planId = $planContainer.data('plan-id');
    if (planId == undefined || planId.length <= 0) {
        console.log('plan container need a valid attr data-plan-id');
        return;
    }
    const $currentPlanLogger = $('body .plan-' + planId + '-logger');
    $currentPlanLogger.append('<div class="col-12">Chargement des relevés de piège pour le plan n°' + planId + '</div>');
    var xy = function(x, y) {
        if (L.Util.isArray(x)) {    // When doing xy([x, y]);
            return yx(x[1], x[0]);
        }
        return yx(y, x);  // When doing xy(x, y);
    };
    let iconWidth = 15;
    let iconHeight = 15;
    let numSize = 12;

    let $inputHeight = $('body #marker_height');
    let $inputWidth = $('body #marker_width');

    let $potentialReportPlan = $('body #reportPlanFormType-' + planId);
    if ($potentialReportPlan.length > 0) {
        // console.log($potentialReportPlan)
        $currentPlanLogger.append('<div class="col-12">ReportPlanFormType trouvé, affectation du paramétrage défini</div>');
        let $reportPlanHeight = $('body #reportPlanFormType-' + planId + ' #report_plan_relevePiegeHeight-' + planId);
        let $reportPlanWidth = $('body #reportPlanFormType-' + planId + ' #report_plan_relevePiegeWidth-' + planId);
        let $reportPlanNumSize = $('body #reportPlanFormType-' + planId + ' #report_plan_relevePiegeNumSize-' + planId);

        if ($reportPlanHeight.length > 0) {
            // console.log('reportplanHeight (' + planId + ') found')
            let reportPlanHeight = $reportPlanHeight.val();
            if (reportPlanHeight != undefined && reportPlanHeight.length > 0) {
                console.log('reportPlanHeight has value ' + reportPlanHeight);
                iconHeight = reportPlanHeight;
            }
        }
        if ($reportPlanWidth.length > 0) {
            // console.log('reportplanWidth (' + planId + ') found')
            let reportPlanWidth = $reportPlanWidth.val();
            if (reportPlanWidth != undefined && reportPlanWidth.length > 0) {
                console.log('reportPlanWidth has value ' + reportPlanWidth);
                iconWidth = reportPlanWidth;
            }
        }
        if ($reportPlanNumSize.length > 0) {
            // console.log('reportPlanNumSize (' + planId + ') found')
            let reportPlanNumSize = $reportPlanNumSize.val();
            if (reportPlanNumSize != undefined && reportPlanNumSize.length > 0) {
                console.log('reportPlanNumSize has value ' + reportPlanNumSize);
                numSize = reportPlanNumSize;
            }
        }

    } else {
        if ($inputHeight.length > 0) {
            if ($inputHeight.val() != undefined) {
                iconHeight = $inputHeight.val();
                numSize = iconHeight - 5;
                console.log('Icon Height => ' + iconHeight);
            }
        }
    
        if ($inputWidth.length > 0) {
            if ($inputWidth.val() != undefined) {
                iconWidth = $inputWidth.val();
                console.log('Icon Width => ' + iconWidth);
            }
        }
    }

    $currentPlanLogger.append('<div class="col-12">Lancement de l\'obtention des relevés pièges pour le plan courant (' + planId + ')</div>');
    $.post({
        url: feeds,
        processData: false,
        contentType: false,
        success: function (response) {
            $currentPlanLogger.append('<div class="col-12">Récupération des relevés pièges OK</div>');
            $currentPlanLogger.append('<div class="col-12">' + response.pieges.length + ' données à traiter</div>');
            if (response.legend != undefined) {
                plan.makeLegend(response.legend, planId);
            }
            // console.log(response)
            var items = [];
            var icons = [];
            
            for (var i = 0; i < response.pieges.length; i++) {
                // $currentPlanLogger.append('<div class="col-12">Initialisation du relevé n°' + i + '</div>')
                let customClass = '';

                if (response.pieges[i].intervention != null) {
                    if (!response.pieges[i].isLast) {
                        customClass += 'hide ';
                    }
                    customClass += ' releve-for-intervention intervention-' + response.pieges[i].intervention;
                }


                if (response.pieges[i].date != null) {
                    customClass += ' date-' + response.pieges[i].date;
                }

                // if (response.pieges[i].rules != null) {
                //     customClass += ' rule-' + response.pieges[i].rule
                //     customClass += ' rules_passed_tp-' + response.pieges[i].rule
                // }

                if (response.pieges[i].relevePiegeId == null) {
                    customClass += ' initial-piege only-piege-' + response.pieges[i].piegeId;
                    
                } else {
                    customClass += ' only-releve-piege-' + response.pieges[i].relevePiegeId + ' releve-piege-' + response.pieges[i].relevePiegeId;
                }

                if (response.pieges[i].etatcId != null) {
                    customClass += ' etat-conso-piege-' + response.pieges[i].etatcId + ' etat-conso-piege-' + response.pieges[i].etatcId + '-interv-' + response.pieges[i].intervention;
                }

                if (response.pieges[i].etateId != null) {
                    customClass += ' etat-releve-piege-' + response.pieges[i].etateId + ' etat-releve-piege-' + response.pieges[i].etateId + '-interv-' + response.pieges[i].intervention ;
                }

                if (response.pieges[i].zone != null) {
                    customClass += '  zone-' + response.pieges[i].zone;
                }
                if (response.pieges[i].piegeId == 1522) {
                    console.log(response.pieges[i]);
                }
                
                if (response.pieges[i].zoneV2Id != null) {
                    customClass += ' zoneV2-' + response.pieges[i].zoneV2Id;
                }
            
                if (response.pieges[i].emplacement != null) {
                    customClass += ' emplacement-' + response.pieges[i].emplacement;
                }
                if (response.pieges[i].interieurExterieur != null) {
                    let iE = response.pieges[i].interieurExterieur == 2 ? 2 : 1;
                    customClass += ' interieurExterieur-' + iE;
                }

                if (response.pieges[i].seuilId != null) {
                    customClass += ' seuil-' + response.pieges[i].seuilId;
                }

                if (response.pieges[i].seuils != null) {
                    customClass += ' seuils-' + response.pieges[i].seuils;
                }
     
                if (response.pieges[i].rule != null) {                   
                    for (var r = 0; r < response.pieges[i].rule.length; r++){
                        customClass += ' rule-' + response.pieges[i].rule[r];
                    }
                }
                
                if (response.pieges[i].rules != null) {    
                    for (var v =0; v < response.pieges[i].rules.length; v++)  {
                        customClass += ' rules-' + response.pieges[i].rules[v];                    
                    }           
                }
                
                if (response.pieges[i].limitSeuil != null) {
                    for (var l = 0; l < response.pieges[i].limitSeuil.length; l++) {
                        customClass += ' seuilLimit-' + response.pieges[i].limitSeuil[l];
                    }
                }
                if (response.pieges[i].totalSeuil != null ) {
                    for (var t = 0; t < response.pieges[i].totalSeuil.length; t++) {
                        customClass += ' constatTotal-' + response.pieges[i].totalSeuil[t];
                    }
                }

                customClass += ' i_piege piege-' + response.pieges[i].piegeId + ' type-piege-' + response.pieges[i].typeId;

                let color = '';
                let etatColor = '';
                let rulesColor = '';
                let ruleColor = '#868a86'; 

                if (response.pieges[i].color != null) {
                    color = `background-color: ` + response.pieges[i].color + `;`;
                }
                if (response.pieges[i].colorEtat != null) {
                    etatColor = `background-color: ` + response.pieges[i].colorEtat + `;`;
                }

                if (response.pieges[i].colorRules != null ) {
                    
                    for (var c =0; c < response.pieges[i].colorRules.length; c++) {
                        rulesColor = `background-color: ` + response.pieges[i].colorRules[c] + `;`; 
                        if (response.pieges[i].totalSeuil >= response.pieges[i].limitSeuil) {
                            ruleColor = response.pieges[i].colorRules[c] ;
                        }
                    }
                }

                let canvasInstanceId = tools.getRandomInt();
                let markerBgUrl = '/assets/img/marqueur-' + response.pieges[i].indice + '.png';
                if ($(document.body).hasClass('dev-mode')) {
                    markerBgUrl = 'http://127.0.0.1:8000/assets/img/marqueur-' + response.pieges[i].indice + '.png';
                }
                
                const markerHtmlStyles = `background-image: url('` + markerBgUrl + `');`;
                let isRelevePiege = response.pieges[i].relevePiegeId == undefined || response.pieges[i].relevePiegeId == null ? false : true;
                let classToIdentifyPiegeOrRelevePiege = isRelevePiege ? 'is-releve-piege' : 'is-piege';
                let tmpCanvasMarker = `
                    <canvas 
                        id="canvas-` + canvasInstanceId + `" 
                        class="canvas-to-draw ` + classToIdentifyPiegeOrRelevePiege + `" 
                        data-color="` + response.pieges[i].color + `" 
                        data-etat-color="` + response.pieges[i].colorEtat + `"
                        data-rules-color="` + ruleColor + `"
                        data-src="` + markerBgUrl + `"
                        height="` + iconHeight + `" 
                        width="` + iconWidth + `"
                    ></canvas>
                `
                let isHidden = response.pieges[i].isLast == true ? '' : ' hide'

                let popupAnchorY = (iconHeight / 2) - 1
                let popupAnchorX = iconWidth / 2
                // popupAnchorX = 0

                icons.push(L.divIcon({
                    className: "my-custom-pin " + customClass + " " + isHidden,
                    iconAnchor: [iconHeight, iconWidth],
                    popupAnchor: [popupAnchorY, popupAnchorX],
                    html: `
                        ` + tmpCanvasMarker + `
                        <div class="plan-marker" style="width: ` + iconWidth + `px; height: ` + iconHeight + `px;">
                            <div class="marqueur-layer">
                                ` + response.pieges[i].identification + `
                            </div>
                        </div>`,
                    iconSize: L.point(iconHeight,iconWidth)
                }))

                let posX = response.pieges[i].x
                let posY = response.pieges[i].y
                let decalYForCenter = (iconHeight/2)
                let decalXForCenter = (iconWidth/2)
                // console.log('decalY => ' + decalYForCenter)
                // console.log('decalX => ' + decalXForCenter)
                // posY = $(img).data('height') - posY - decalYForCenter
                posY = $(img).data('height') - posY
                // posX -= decalXForCenter

                if (ratio) {
                    posY = posY / 2
                    posX = posX / 2
                }

                items.push({
                    coords: {lat: posY, lng: posX},
                    title: response.pieges[i].type,
                    etatc: response.pieges[i].etatc,
                    etate: response.pieges[i].etate,
                    etatcId: response.pieges[i].etatcId,
                    etateId: response.pieges[i].etateId,
                    consoColor: response.pieges[i].color,
                    etatColor: response.pieges[i].colorEtat,
                    rulesColor: response.pieges[i].colorRules,
                    identification: response.pieges[i].identification,
                    date: response.pieges[i].date,
                    hDate: response.pieges[i].hDate,
                    piegeId: response.pieges[i].piegeId,
                    relevePiegeId: response.pieges[i].relevePiegeId,
                    marker: markerBgUrl,
                    article: response.pieges[i].article,
                    action: response.pieges[i].action,
                    zone: response.pieges[i].zone,
                    zoneLabel: response.pieges[i].zoneLabel,
                    zoneV2Id: response.pieges[i].zoneV2Id,
                    zoneV2: response.pieges[i].zoneV2,
                    seuil: response.pieges[i].seuil,
                    seuils: response.pieges[i].seuils,
                    seuilId: response.pieges[i].seuilId,
                    rule: response.pieges[i].rule,
                    rules: response.pieges[i].rules,
                    emplacement: response.pieges[i].emplacement,
                    interieurExterieur: response.pieges[i].interieurExterieur,
                    photos: response.pieges[i].photos,
                    isLast: response.pieges[i].isLast,
                    limitSeuil: response.pieges[i].limitSeuil,
                    totalSeuil: response.pieges[i].totalSeuil,
                    intervention: response.pieges[i].intervention
                })
                // console.log(response.pieges[i]);
                // console.log(response.pieges[i].limitSeuil);
                // console.log(response.pieges[i].rules);
                // console.log(response.pieges[i].rulesColor);

               
            }
            $currentPlanLogger.append('<div class="col-12">Initialisation des relevés terminés</div>')
            $currentPlanLogger.append('<div class="col-12">Positionnement sur le plan, ' + items.length + ' item à traiter</div>')

            for (var j = 0; j < items.length; j++) {
                // $currentPlanLogger.append('<div class="col-12">Positionnement du relevé ' + j + '</div>')
                let tempItem = items[j]
                // the div.row element was closed after condition
                let showPiegeId = $('body').hasClass('dev-mode') ? '<span class="badge dev-badge">PID - ' + tempItem.piegeId + '</span>' : ''
                let showRelevePiegeId = $('body').hasClass('dev-mode') ? '<span class="badge dev-badge">RPID - ' + tempItem.relevePiegeId + '</span>' : ''
                let wrapperDev = `
                    <div class="col-12 pb-1">
                        <div class="row">
                            <div class="col-12">
                                ` + showPiegeId + `
                                ` + showRelevePiegeId + `
                            </div>
                        </div>
                    </div>
                `
                let title = ''
                let wrapperTypePiegeAndDateReleve = ''
                let wrapperEmplacementAndZone = ''
                let wrapperEtatAndConso = ''
                let wrapperPhotos = ''
                let wrapperArticle = ''
                let wrapperActionMenee = ''
                let wrapperZoneV2 = ''
                let wrapperSeuil = ''

                if (tempItem.relevePiegeId != undefined) {
                    title = `
                        <div class="col-12 text-uppercase font-size-15 font-weight-bold py-1">
                            Relevé piège # ` + tempItem.identification + `
                        </div>
                    `
                } else {
                    title = `
                        <div class="col-12 text-uppercase font-weight-bold font-size-15 py-1">
                            Piège # ` + tempItem.identification + `
                        </div>
                    `
                }
                

                

                if (tempItem.hDate != null || tempItem.title != null) {
                    if (tempItem.hDate != null && tempItem.title != null) {
                        wrapperTypePiegeAndDateReleve = `
                            <div class="col-12 py-1">
                                <div class="row">
                                    <div class="col-lg-6 col-12 mb-1">
                                        <i class="fal fa-calendar"></i> ` + tempItem.hDate + `
                                    </div>
                                    <div class="col-lg-6 col-12 mb-1 text-right font-size-12">
                                        ` + tempItem.title + `
                                        <img src="` + tempItem.marker + `" class="img-fluid w-10" />
                                    </div>
                                </div>
                            </div>
                        `
                    } else {
                        if (tempItem.hDate != null) {
                            wrapperTypePiegeAndDateReleve = `
                                <div class="col-12 py-1">
                                    <i class="fal fa-calendar">
                                    ` + tempItem.hDate + `
                                </div>
                            `
                        } else {
                            wrapperTypePiegeAndDateReleve = `
                                <div class="col-12 py-1">
                                    ` + tempItem.title + `
                                    <img src="` + tempItem.marker + `" class="img-fluid w-10" />
                                </div>
                            `
                        }
                    }
                }

                if (tempItem.photos != undefined && tempItem.photos.length > 0) {
                    // console.log(tempItem)
                    let documentUri = '/file/document/'
                    
                    let subWrapperPhotos = ''
                    let tempPhotoWidth = 'col-6'
                    if (tempItem.photos.length > 3) {
                        tempPhotoWidth = 'col-lg-4 col-6'
                    }

                    for (var ii = 0; ii < tempItem.photos.length; ii++) {
                        let tempPhoto = tempItem.photos[ii]
                        let alt = tempPhoto.alt == undefined ? 'Photo du relevé' : tempPhoto.alt
                        subWrapperPhotos += `
                            <img 
                            src="` + tools.documentUri() + documentUri + tempPhoto.docId + '?token=' + tools.documentToken() + `&cstmr= ` + tools.documentCstmr() + `" 
                            alt="` + alt + `"
                            id="photo-releve-piege-` + tempPhoto.id + `" class="img-fluid mb-2 ` + tempPhotoWidth + `" />
                        `
                    }
                    let photosUri = Routing.generate('app_customer_piege_releve_piege_photos', {
                        slug: tools.slug(),
                        relevePiege: tempItem.relevePiegeId
                    })
                    let wrapperPhotoTitle = tempItem.photos.length > 1 ? 'Photos' : 'Photo'
                    wrapperPhotos = `
                        <div class="col-12 py-2">
                            <div class="row">
                                <h3 class="col-12 h5">
                                    ` + wrapperPhotoTitle + `
                                    <div class="float-right">
                                        <button class="btn btn-d-blue btn-sm rounded-0 py-1 btn-load-modal"
                                        data-url="` + photosUri + `">
                                            <i class="fal fa-expand"></i>
                                        </button>
                                    </div>
                                </h3>
                                ` + subWrapperPhotos + `
                            </div>
                        </div>
                    `
                }

                if (tempItem.article != null) {
                    wrapperArticle = `
                        <div class="col-12">
                            <b>
                                Produit : 
                            </b>
                            ` + tempItem.article + `
                        </div>
                    `
                }

                if (tempItem.emplacement != null || tempItem.zoneLabel != null  ) {
                    if (tempItem.emplacement != null && tempItem.zoneLabel != null) {
                        wrapperEmplacementAndZone = `
                            <div class="col-12 py-1">
                                <div class="row">
                                    <div class="col-lg-6 col-12 mb-1">
                                        <b>EMPT : </b>` + tempItem.emplacement + `
                                    </div>
                                    <div class="col-lg-6 col-12 mb-1 float-right">
                                        <b>ZONE : </b>` + tempItem.zoneLabel + `
                                    </div>
                                </div>
                            </div>
                        `
                    } else {
                        if (tempItem.emplacement != null) {
                            wrapperEmplacementAndZone = `
                            <div class="col-12 py-1">
                                ` + tempItem.emplacement + `
                            </div>`
                        } else {
                            wrapperEmplacementAndZone = `
                            <div class="col-12 py-1">
                                ` + tempItem.zoneLabel + `
                            </div>`
                        } 
                    }
                }

                if (tempItem.zoneV2 != null) {
                    wrapperZoneV2 = `
                    <div class="col-lg-12 col-12 mb-1">
                    <b> ZONE : </b>` + tempItem.zoneV2 +`
                    </div>`
                }

                if (tempItem.action != null) {
                    wrapperActionMenee = `
                        <div class="col-12 my-1 text-muted font-weight-bold">
                            <i class="fal fa-chevron-circle-right"></i>
                            Actions menées
                        </div>
                        <p class="col-12 my-0">
                             ` + tempItem.action + `
                        </p>
                    `
                }


                if (tempItem.etatc != null || tempItem.etate != null) {
                    let tempEtatColor = tempItem.etatColor
                    let tempConsoColor = tempItem.consoColor
                    let tempEtatYiqColor = tempEtatColor != undefined ? tools.calculYiq(tempEtatColor) : null
                    let tempConsoYiqColor = tempConsoColor != undefined ? tools.calculYiq(tempConsoColor) : null
                    
                    let styleConso = `style="color: ` + tempConsoYiqColor  + `; background-color: ` + tempConsoColor + `;"`
                    let styleEtat = `style="color: ` + tempEtatYiqColor  + `; background-color: ` + tempEtatColor + `;"`

                    if (tempItem.etatc != null && tempItem.etate != null) {
                        wrapperEtatAndConso = `
                            <div class="col-12 py-1">
                                <div class="row">
                                    <div class="text-center col-lg-6 col-12 py-1 mb-1" ` + styleConso + `>
                                        <span class="text-uppercase"><b>Constat / Conso </b></span> <br />` + tempItem.etatc + `
                                    </div>
                                    <div class="col-lg-6 col-12 mb-1 text-center py-1" ` + styleEtat + `>
                                        <span class="text-uppercase"><b>État</b></span> <br />` + tempItem.etate + `
                                    </div>
                                </div>
                            </div>
                        `

                    } else {
                        if (tempItem.etatc != null) {
                            wrapperEtatAndConso = `
                                <div class="col-12 py-1 text-center py-1" ` + styleConso + `>
                                    <span class="text-uppercase"><b>Constat / Conso</b></span><br />` + tempItem.etatc + `
                                </div>
                            `
                        } else {
                            wrapperEtatAndConso = `
                                <div class="col-12 py-1 text-center py-1" ` + styleEtat + `>
                                    <span class="text-uppercase"><b>État</b></span><br />` + tempItem.etate + `
                                </div>
                            `
                        }
                    }

                    // let tempRulesYiqColor = '';
                    // let styleRules = '';
                   
                    // let tempRulesColor = tempItem.rulesColor
                    // for (var c = 0; c < tempRulesColor.length; c++) {

                    //     tempRulesYiqColor = tempRulesColor != undefined ? tools.calculYiq(tempRulesColor[c]) : null 
                    //     styleRules = `style="color:`+ tempRulesYiqColor +` ; background-color: ` + tempRulesColor[c] + `;"`

                    //     if (tempItem.seuil != null || tempItem.rule) {
                    //         if (tempItem.limitSeuil >= tempItem.totalSeuil ) {
                    //             for (var r =0; r < tempItem.rule.length; r++) {
                    //                 wrapperSeuil = `
                    //                 <div class="col-12 py-1">
                    //                     <div class="row ">
                    //                         <div class="text-center col-12  py-1 mb-1 " ` + styleRules + `>
                    //                             <span class="text-uppercase">
                    //                                 <b>Seuil </b>` + tempItem.seuil +`
                    //                                 <br>
                    //                                 <b>Règle(s) n°</b> ` + tempItem.rule[c] +`
                    //                             </span>
                    //                         </div>
                    //                     </div>
                    //                 </div>
                    //              `
                    //             }
                    //         } else {
                    //             for (var r =0; r < tempItem.rule.length; r++) {
                    //                 wrapperSeuil = `
                    //                 <div class="col-12 py-1">
                    //                     <div class="row">
                    //                         <div class="text-center col-12  py-1 mb-1 border" ` + styleRules + `>
                    //                             <span class="text-uppercase">
                    //                                 <span class="float-right text-danger text-uppercase">Seuil <br> Dépassé</span>
                    //                                 <b>Seuil </b>` + tempItem.seuil +`
                    //                                 <br>
                    //                                 <b>Règle(s) n°</b> ` + tempItem.rule[c] +`
                    //                             </span>
                    //                         </div>
                    //                     </div>
                    //                 </div>
                    //                 `
                    //             }
                    //         }
                    //     }

                    // }
                
                }
                
            
                let wrapperStatsReleve = ''
                let wrapperStatsComptageReleve = ''
                let wrapperAStatsReleve = ''
                let wrapperAStatsComptageReleve = ''

                

                if (tempItem.relevePiegeId != undefined) {
                    let graphId = tempItem.relevePiegeId;

                    let statsUrl = Routing.generate('app_customer_piege_stats_by_releve_piege', {
                        slug: tools.slug(),
                        relevePiege: tempItem.relevePiegeId
                    })
                    let comptageUrl = Routing.generate('app_customer_piege_stats_comptages_by_releve_piege', {
                        slug: tools.slug(),
                        relevePiege: tempItem.relevePiegeId
                    })

                    wrapperAStatsReleve = `
                        <a class="load-details nav-link btn-url bg-page active" href="#" id="`+ graphId +`" data-url="`+ statsUrl +`" data-container="graph-container">
                            Constat / Conso
                        </a>
                    `
                    wrapperAStatsComptageReleve = `
                        <a class="load-details-comptage nav-link btn-url bg-page " href="#" id="`+ graphId +`" data-url="`+ comptageUrl +`" data-container="graph-container">
                            Comptages
                        </a>
                    `

                    // wrapperStatsReleve = `
                    // <div class="col-12 mt-2">
                    //     <div class="load-details row graph-test" data-url="` + statsUrl + `"></div>
                    // </div>    
                    // `
                    // wrapperStatsComptageReleve = `
                    // <div class="col-12 mt-2">
                    //     <div class="load-details-comptage row graph-test-2" data-url="` + comptageUrl + `"></div>
                    // </div>    
                    // `
                }

                
                
                let wrapperHtml = `
                    <div class="row">
                        <div class="col-6">
                            <div class="row">
                            ` + wrapperDev + `
                            ` + title + `
                            ` + wrapperTypePiegeAndDateReleve + `
                            ` + wrapperEmplacementAndZone + `
                            ` + wrapperZoneV2 + `
                            ` + wrapperSeuil + `
                            ` + wrapperArticle + `
                            ` + wrapperEtatAndConso + `
                            ` + wrapperActionMenee + `
                            ` + wrapperPhotos + ` 
                            </div>
                        </div>
                        <div class=" col-6">
                            <div class="row">
                                <ul class="nav nav-tabs sub-navbar mt-2 font-size-12" id="graph-tabs">
                                    <li class="nav-item">
                                        `+ wrapperAStatsReleve+`
                                    </li>
                                    <li class="nav-item">
                                        `+ wrapperAStatsComptageReleve +`
                                    </li>
                                </ul>
                                <div class="col-12">
                                    <div class="row graph-test" id="graph-container"></div>
                                    
                                </div>
                            </div>
                        </div>
                        <div class="col-12 float-right">
                            <button class="btn btn-d-blue float-right px-2 btn-md py-auto font-size-13 mb-2" id="export-excel" data-piege="`+ tempItem.piegeId +`" data-relevePiege="`+ tempItem.relevePiegeId +`">
                                <i id="image-Load" class="fal fa-file-excel"></i>
                                Export Excel
                            </button>
                        </div>
                    </div>
                `

                // // close first div.row
                // popupHtml += `</div>`

                L.marker(items[j].coords, {icon: icons[j]}).addTo(mapInstance).bindPopup(wrapperHtml, {
                    maxWidth: "auto",
                    // width: 350
                }).on('popupopen', function (e) {
                    const popupContainer = e.popup._container
                    $(popupContainer).css('width', '500px');
                    $(popupContainer).css('left', '-250px');


                    const $statContainer = $(popupContainer).find('.load-details.active') 
                    if ($statContainer.length <= 0) {
                        console.log('load details not found')
                        return
                    }
                    const $container = $('body #' + $statContainer.data('container'))
                    console.log('load details found, load conten')
                    // const $comptageContainer = $(popupContainer).find('.load-details-comptage')
                    // if ($comptageContainer.length <= 0) {
                    //     console.log('load comptage not found')
                    //     return
                    // }
                    // console.log('load comptage found, load conten')

                    // const comptageUrl = $comptageContainer.data('url')
                    const statUrl = $statContainer.data('url')
                    tools.loadContent($container, statUrl)
                    ///////////////////////////////////////////
                    

                  
                })
            }

            $currentPlanLogger.append('<div class="col-12">Initialisation des relevés sur le plan terminé</div>')

            plan.planDebug('<i class="fal fa-check-circle"></i> End loading marker of plan')
            let $canvasToDraws = $('body .canvas-to-draw')
            $currentPlanLogger.append('<div class="col-12">Génération du canvas pour chaque relevé du plan, ' + $canvasToDraws.length + ' canvas à dessiner</div>')
            $canvasToDraws.each(function (i, elm) {
                // $currentPlanLogger.append('<div class="col-12">Traitement du canvas n°' + i + '</div>')
                const $canvasToDraw = $(elm)
                if ($canvasToDraw.hasClass('canvas-set')) {
                    return
                }
                $canvasToDraw.addClass('canvas-set')
                const canvasInstanceId = $canvasToDraw.attr('id')
                let node = document.getElementById(canvasInstanceId)
                let markerUrl = $canvasToDraw.data('src')
                let color = $canvasToDraw.data('color')
                let hexaColor = color
                let drawCross = false
                color = tools.hexToRgb(color)

                if (color === null && !$canvasToDraw.hasClass('is-piege')) {
                    // console.log('hexToRgb generate null color : ', color, $canvasToDraw)
                    // color = {
                    //     r: 255,
                    //     g: 255,
                    //     b: 255
                    // }
                    drawCross = true
                }

                let ctx = node.getContext('2d')
                let tmpImage = new Image()
                tmpImage.src = markerUrl
                tmpImage.onload = function () {
                    drawPattern(this, iconHeight, canvasInstanceId)
                    // let tempCanvas = document.createElement('canvas')
                    // let tempCtx = tempCanvas.getContext('2d')
                    // console.log(this, this.width, this.height)
                    // tempCanvas.width = 16
                    // tempCanvas.height = 16
                    // tempCtx.drawImage(this, 0, 0, this.width,this.height, 0, 0, 16,16)

                    // ctx.clearRect(0,0,node.width, node.height)
                    // ctx.fillRect = ctx.createPattern(tempCanvas, 'no-repeat')
                    // ctx.beginPath()
                    // ctx.rect(0,0,node.width, node.height)
                    // ctx.fill()

                    // ctx.drawImage(tmpImage, 0, 0)
                    // let pattern = ctx.createPattern(tmpImage, 'no-repeat')
                    // ctx.fillStyle = pattern
                    // ctx.fillRect(0, 0, 32, 32)

                    if (color != null) {
                        let ctxImgData = ctx.getImageData(0, 0, iconHeight, iconWidth)
                        let pix = ctxImgData.data
                        let unC = [
                            color.r,
                            color.g,
                            color.b
                        ]

                        // console.log(unC, pix.length)
    
                        for (var i = 0, n = pix.length; i < n; i += 4) {
                            pix[i] = unC[0]
                            pix[i+1] = unC[1]
                            pix[i+2] = unC[2]
                        }
    
                        ctx.putImageData(ctxImgData, 0, 0)

                        // if (drawCross) {
                        //     console.log('drawCross : ' + drawCross, $canvasToDraw)
                        //     // ctx.clearRect(0,0,64,64)
                        //     ctx.lineWidth = 1;
                        //     ctx.beginPath();
                        //     ctx.moveTo(0, 0);
                        //     ctx.lineTo(node.width, node.height);
                        //     ctx.stroke();
                        //     ctx.lineWidth = 1;
                        //     ctx.beginPath();
                        //     ctx.moveTo(node.width, 0);
                        //     ctx.lineTo(0, node.height);
                        //     ctx.stroke();
                        // }

                        let $canvasParent = $canvasToDraw.parent()
    
                        if ($canvasParent.length <= 0) {
                            return
                        }
            
                        let canvasParentId = $canvasParent.attr('id')
            
                        if (canvasParentId == undefined) {
                            canvasParentId = 'leaflet-marker-id-' + tools.getRandomInt()
                            $canvasParent.attr('id', canvasParentId)
                        }
            
                        let canvasParentNod = document.getElementById(canvasParentId)
                        let $marqueurLayer = $(canvasParentNod).find('.marqueur-layer')
                        $marqueurLayer.css('color', tools.calculYiq(hexaColor))
                        $marqueurLayer.css('font-size', numSize + 'px')
                    }
                }
                // console.log('ctx n°' + i, ctx)
            })

            // console.log('canvasToDraws length : ' + $canvasToDraws.length)
            $currentPlanLogger.append('<div class="col-12">Initialisation des canvas terminés</div>')

            $('body').on('mouseenter', '.hover-piege', function () {
                // console.log('.hover-piege::mouseenter() fired')
                const $elm = $(this)
                const target = $elm.data('target')

                if (target == undefined) {
                    return
                }

                const $target = $('body .' + target)

                if ($target.length <= 0 || !$target.is(':visible')) {
                    return
                }

                $target.css('transition', 'all .2s')
                $target.css('box-shadow', '10px 10px 5px 13px black')
            })
            $('body').on('click', '.click-piege', function () {
                // console.log('.click-piege::click() fired')
                const $elm = $(this)
                const target = $elm.data('target')

                if (target == undefined) {
                    return
                }

                const $target = $('body .' + target)

                if ($target.length <= 0 || !$target.is(':visible')) {
                    // console.log('target not found : ' + target +  'or is not visible')
                    return
                }
                
                $target.trigger('click')
                // console.log($target.children('div').hasClass('load-details'))
            })
            $('body').on('mouseout', '.hover-piege', function () {
                const $elm = $(this)
                const target = $elm.data('target')

                if (target == undefined) {
                    return
                }

                const $target = $('body .' + target)

                if ($target.length <= 0 || !$target.is(':visible')) {
                    return
                }

                $target.css('transition', 'none')
                $target.css('box-shadow', 'none')
            })
            $('body').on('click', '.expand-plan', function () {
                plan.loader()
                $('.interactive-plan').css('opacity', '0')
                $('.navbar-container').css('z-index', '0')
                $('body .interactive-plan').css('position', 'fixed')
                $('.interactive-plan').animate({
                    width: 100 + '%',
                    left: 0 + '%',
                    top: 0 + '%',
                    height: 100 + '%',
                }, 100)
                // $('body #panel-container').parent().draggable()
                $(this).removeClass('expand-plan').addClass('compress-plan')
                $(this).children('i').replaceWith('<i class="fal fa-2x mx-1 mt-1 fa-compress"></i>')
                $(this).attr('title', 'Revenir à l\'affichage par défaut ?')
                $('.interactive-plan').animate({
                    opacity: 1
                }, 800)
                setTimeout(function () { 
                    mapInstance.invalidateSize()
                    $('.interactive-plan').css('z-index', '2')
                }, 200)
            })
            $('body').on('click', '.compress-plan', function () {
                plan.loader()
                $('.interactive-plan').css('opacity', '0')
                $('.navbar-container').css('z-index', '0')
                $(this).attr('title', 'Passer en plein écran ?')
                $('.interactive-plan').css('position', 'relative')
                $('.interactive-plan').css('left', 'unset')
                $('body #panel-container').css('left', '0px')
                $('body #panel-container').css('top', '0px')
                $('.interactive-plan').css('top', 'unset')
                $('.interactive-plan').animate({
                    height: $('.interactive-plan').data('height') + 'px',
                    width: 100 + '%',
                })
                $('.interactive-plan').animate({
                    opacity: 1
                }, 800)
                setTimeout(function () { 
                    mapInstance.invalidateSize()
                }, 200)
                $(this).removeClass('compress-plan').addClass('expand-plan')
                $(this).children('i').replaceWith('<i class="fal mx-1 mt-1 fa-2x fa-expand"></i>')
            })
            $('body').on('change', '.change-interactive-plan-bg', function () {
                $('body .interactive-plan').animate({
                    backgroundColor: $(this).val()
                }, 750)
                let yiqBgColor = tools.calculYiq($(this).val())
                let yiqTextColor = tools.calculYiq(yiqBgColor)
                $('body .panel-pieges, body .plan-logs').css('background-color', yiqBgColor)
                $('body .panel-pieges, body .plan-logs').css('color', yiqTextColor)
                $('body .fa.fa-circle.btn-click').css('color', $(this).val())
            })
        }
    })
}

const exportExcel = function() {
    let btnExport = $('#export-excel') 


    $('body').on('click', '#export-excel', function() {
        var btn = $(this)


        let piegeId = btn.data('piege')
        let releveId = btn.data('relevePiege')
  
        const $postUrl = Routing.generate('app_customer_piege_export_data_excel', {
            slug: tools.slug(),
            piege: piegeId,
            relevePiege: releveId,
        })
  
         // si un export est déjà en cours, on ne lance pas le process
         if (btn.hasClass('in-progress')) {
            return
        }
        btn.addClass('in-progress')


        btn.children().removeClass('fa-file-excel').addClass('fa-file-download')

        $.post({
            url: $postUrl,
            processData: false,
            contentType: false,

            xhrFields: {
                responseType: 'blob' // to avoid binary data being mangled on charset conversion
            },
            success: function(blob, status, xhr) {
                // check for a filename
                var filename = "";
                var disposition = xhr.getResponseHeader('Content-Disposition');
                if (disposition && disposition.indexOf('attachment') !== -1) {
                    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    var matches = filenameRegex.exec(disposition);
                    if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
                }
        
                if (typeof window.navigator.msSaveBlob !== 'undefined') {
                    // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
                    window.navigator.msSaveBlob(blob, filename);
                } else {
                    var URL = window.URL || window.webkitURL;
                    var downloadUrl = URL.createObjectURL(blob);
        
                    if (filename) {
                        // use HTML5 a[download] attribute to specify filename
                        var a = document.createElement("a");
                        // safari doesn't support this yet
                        if (typeof a.download === 'undefined') {
                            window.location.href = downloadUrl;
                        } else {
                            a.href = downloadUrl;
                            a.download = filename;
                            document.body.appendChild(a);
                            a.click();
                        }
                    } else {
                        window.location.href = downloadUrl;
                    }
        
                    setTimeout(function () { URL.revokeObjectURL(downloadUrl); }, 100); // cleanup
                }
            }
        })


        console.log('click btn export excel');
    })
}

const makeLegend = function (legend, planId) {
    let wrapper = ``

    $('body .plan-legend-container-' + planId).html('<div class="bg-white">Chargement du plan en cours....</div>')

    for (let i = 0; i < legend.length; i++) {
        let currentTypePiege = legend[i]

        let markerBgUrl = '/assets/img/marqueur-' + currentTypePiege.indice + '.png';
        if ($(document.body).hasClass('dev-mode')) {
            markerBgUrl = 'http://127.0.0.1:8000/assets/img/marqueur-' + currentTypePiege.indice + '.png';
        }

        let currentEtatsConso = currentTypePiege.etatsConso
        let currentEtatsType = currentTypePiege.etatsType
        let wrapperConsoStart = '<div class="col-12"><div class="row">'
        let wrapperConsoEnd = '</div></div>'
        let wrapperConso = ''

        let isHidden = $('body .plan-legend-container-' + planId).hasClass('in-report') ? 'hide' : ''

        for (let cec = 0; cec < currentEtatsConso.length; cec++) {
            let currentConsoState = currentEtatsConso[cec]
            wrapperConso += `
                <div class="col-12 ` + isHidden + ` not-in-pdf font-size-10">
                    <div class="row">
                        <div class="col">
                            <span style="color: ` + currentConsoState.bgColor + `;">
                                <i class="fa fa-circle"></i>
                            </span>
                            ` + currentConsoState.label + `<sup>
                            x` + currentConsoState.nb + `</sup>
                        </div>
                    </div>
                </div>
            `
        }

        wrapperConso = wrapperConsoStart + wrapperConso + wrapperConsoEnd

        let encartTypePiege = `
        <div class="col-lg-4 col-md-6 col-12 bg-white border border-left-d-blue py-2">
            <div class="row">
                <h5 class="col-12 mb-0 font-size-12">
                    <img class="img-fluid" style="height: 16px; width: 16px;" src="` + markerBgUrl + `" />
                    ` + currentTypePiege.label + `
                </h5>
        `

        if (currentTypePiege.nbReleves > 0) {
            encartTypePiege += `
            <blockquote class="col-12 mb-0 not-in-pdf ` + isHidden + ` font-size-11">` + currentTypePiege.nbReleves + ` relevés associés</blockquote>
            ` + wrapperConso;
        }

        encartTypePiege += '</div></div>'

        wrapper += encartTypePiege
    }

    wrapper = `
    <div class="col-12 min-width-100">
        <div class="row">
            <div 
            title="Afficher/Masquer la légende ?"
            style="max-width: 42px;"
            class="col-1 py-1 not-in-pdf btn btn-d-blue rounded-0 legend-plan-switcher fade show text-white toggle-container reverse-arrow d-flex align-items-center plan-legend-container-toggle" data-target="plan-legend-container">
                <i class="fal fa-arrow-circle-left"></i>
            </div>
            <div class="col-11 plan-legend-container" id="plan-legend-container">
                <div class="row">
                    ` + wrapper + `
                </div>
            </div>
        </div>
    </div>`
    
    $('body .plan-legend-container-' + planId).html(wrapper)

}

const loader = function (message = null, isForBigScreen = true, timeout = 1500, noLimit = false) {

    if (message == null || message == undefined) {
        message = 'Redimensionnement et ajustement en cours'
    }

    $('body .lf-loader').remove()
    const wrapper = `
    <div class="lf-loader text-center text-yellow bg-white">
        <div class="float-right loader-actions hide">
            <div class="p-3">
                <button class="btn close-loader btn-outline-danger rounded-pill">
                    <i class="fal fa-times-circle"></i>
                    Fermer
                </button>
            </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <h1 class="ml1">
        <span class="text-wrapper">
            <span class="line line1 bg-yellow"></span>
            <span class="letters">HYGONLINE</span>
            <span class="line line2 bg-yellow"></span>
        </span>
        </h1>
        <br />
        <br />
        <b class="loader-message">
            ` + message + `
        </b>
        <p class="loader-infos">
        </p>
        <div class="loader-results py-3 hide col-6 offset-3">
        </div>
        <br />
        <br />
        <i class="fal fa-circle-notch loader-ico fa-3x fa-spin"></i>
    </div>`
    $('body').append(wrapper)
    // if ($('body .lf-loader').length <= 0) {
    // } else {
    //     $('body .lf-loader').html(wrapper)
    // }

    $('body .lf-loader').show()
    $('body .lf-loader').css('position', 'fixed')
    $('body .lf-loader').css('z-index', '99999999')
    $('body .lf-loader').css('top', '0%')
    $('body .lf-loader').css('height', '100%')
    $('body .lf-loader').css('width', '100%')
    $('body .lf-loader').css('left', '0%')

    var textWrapper = document.querySelector('.ml1 .letters');
    textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");

    anime.timeline({loop: false})
    .add({
        targets: '.ml1 .letter',
        scale: [0.3,1],
        opacity: [0,1],
        translateZ: 0,
        easing: "easeOutExpo",
        duration: 700,
        delay: (el, i) => 120 * (i+1)
    }).add({
        targets: '.ml1 .line',
        scaleX: [0,1],
        opacity: [0.5,1],
        easing: "easeOutExpo",
        duration: 400,
        offset: '-=875',
        delay: (el, i, l) => 120 * (l - i)
    });

    if (noLimit == false) {
        setTimeout(function () {
            $('.lf-loader').hide('slow')
        }, timeout)
    }
}

const removeLoader = function () {
    $('body .lf-loader').hide('slow')
}

const load = function () {
    plan.planDebug('plan.js::load() fired', 'success')
    // console.log('plan::load()')
    const $plans = $('body .interactive-plan')

    plan.planDebug($plans.length + ' interactive plan found')

    $plans.each(function (i, elm) {
        const $plan = $(elm)

        if ($plan.hasClass('initialized')) {
            return
        }

        $plan.addClass('initialized')

        const nodeId = $plan.attr('id')
        const planId = $plan.data('plan-id')
        const $currentPlanLogger = $('body .plan-' + planId + '-logger')

        if (planId == undefined || planId.length <= 0) {
            console.log('plan required data-plan-id')

            return
        }

        if (nodeId === undefined) {
            plan.planDebug('.interactive-plan {' + i + '} : <b>The HTMLElm required an identifier</b>', 'danger')

            return
        }
        $currentPlanLogger.append('<div class="col-12">Début du chargement du plan n°' + planId + '</div>')

        plan.planDebug('<i class="fal fa-spin fa-spinner"></i> Plan <b>#' + nodeId + '</b> start loading', 'info')

        const node = document.getElementById(nodeId)

        let overlayBackgroundColor = $plan.data('background')

        let planHeight = $plan.data('height')
        let planWidth = $plan.data('width')
        const backgroundImageUrl = $plan.attr('src')
        let ratioIsModified = false
        $currentPlanLogger.append('<div class="col-12">Dimension du plan : ' + planHeight + ' (H) / ' + planWidth + ' (W) </div>')
        if ($plan.hasClass('ratio-by-2')) {
            planHeight = (planHeight / 2)
            planWidth = (planWidth / 2)
            ratioIsModified = true
            $currentPlanLogger.append('<div class="col-12"><b>Ratio actif</b> Nouvelle dimension du plan : ' + planHeight + ' (H) / ' + planWidth + ' (W) </div>')
        } else {
            $plan.css('max-height', planHeight + 'px')
            $plan.css('height', planHeight + 'px')
        }
        // console.log(planHeight, planWidth)
        let yiqBgColor = '#FFF'
        // $plan.css('height', planHeight + 'px')
        let yiqTextColor = '#000' 
        if (overlayBackgroundColor != undefined && overlayBackgroundColor != '') {
            $plan.css('background-color', overlayBackgroundColor)
            yiqBgColor = tools.calculYiq(overlayBackgroundColor) 
            yiqTextColor = tools.calculYiq(yiqBgColor)
        } else {
            overlayBackgroundColor = '#ddd'
        }
        let panelBgColor = 'style="background-color: ' + yiqBgColor + '; color: ' + yiqTextColor + '"'

        $('body .panel-interactive-color').css('background-color', yiqBgColor)
        $('body .panel-interactive-color').css('color', yiqTextColor)

        // Load save or default settings
        let minZoom = tools.getCookie('plan_min_zoom')
        let maxZoom = tools.getCookie('plan_max_zoom')
        let defaultZoom = tools.getCookie('plan_zoom')
        let deltaZoom = tools.getCookie('plan_delta_zoom')
        let boxZoom = tools.getCookie('plan_box_zoom')
        let trackResize = tools.getCookie('plan_track_resize')
        let preferCanvas = tools.getCookie('plan_prefer_canvas')
        let closePopupOnClick = tools.getCookie('plan_close_popup_on_click')

        let options = {
            crs: L.CRS.Simple,
            minZoom: -4,
            maxZoom: 4,
            zoom: -1,
            // center: [0, 0],
            deltaZoom: 0.5
        }
    
        L.Map.addInitHook(function () {
            mapsInstance.push(this)
        })

        let map = L.map(nodeId, options)

        // let map = L.map(nodeId, {
        //     crs: L.CRS.Simple,
        //     minZoom: minZoom != false ? minZoom : -5,
        //     maxZoom: maxZoom != false ? maxZoom : 5,
        //     zoom: defaultZoom != false ? defaultZoom : 0,
        //     deltaZoom: deltaZoom != false ? deltaZoom : 1,
        //     maxBounds: [planHeight, planWidth],
        //     boxZoom: boxZoom != false ? boxZoom : false,
        //     trackResize: trackResize != false ? trackResize : true,
        //     preferCanvas: preferCanvas != false ? true : true,
        //     closePopupOnClick: closePopupOnClick != false ? closePopupOnClick : true,
        //     // renderer: L.canvas()
        // })

        // let Sw = map.unproject([0, planHeight], map.getMaxZoom()-1)
        // let Ne = map.unproject([planWidth, 0], map.getMaxZoom()-1)
        // console.log(Sw, Ne)
        // let bnds = new L.LatLngBounds(Sw, Ne)
        // console.log(bnds)

        let bounds = [
            [0, 0],
            [planHeight, planWidth]
        ]

        let backgroundImage = L.imageOverlay(backgroundImageUrl, bounds).addTo(map)
        // $plan.css('height', planHeight)
        
        $plan.show('slow')
        $currentPlanLogger.append('<div class="col-12">Initialisation OK - Affichage du plan</div>')
        map.fitBounds(bounds)
        $currentPlanLogger.append('<div class="col-12">FitBounds effectué</div>')

        let iconSize = ratioIsModified === true ? '' : 'fa-2x'

        const cogsUrl = Routing.generate('app_customer_plan_settings', {
            slug: tools.slug()
        })
        let affichageLegend = 'bottomleft'
        let affichageFilter = 'topright'
        let affichageSettings = 'bottomright'
        let classLegend = ''
        let classFilter = ''
        let classSettings = ''
        if ($plan.data('legend-position') != undefined && $plan.data('legend-position') != '') {
            let legendPosition = $plan.data('legend-position')
            if (legendPosition != 0 && legendPosition != 5) {
                switch (legendPosition) {
                    case 1:
                        affichageLegend = 'topleft'
                        affichageFilter = 'topright'
                        affichageSettings = 'bottomright'
                    break
                    case 2:
                        affichageLegend = 'topright'
                        affichageFilter = 'topleft'
                        affichageSettings = 'bottomright'
                    break
                    case 3:
                        affichageLegend = 'bottomleft'
                        affichageFilter = 'topright'
                        affichageSettings = 'bottomright'
                    break
                    case 4:
                        affichageLegend = 'bottomright'
                        affichageFilter = 'topright'
                        affichageSettings = 'bottomleft'
                    break
                }
            }
        }
        if (!$plan.hasClass('no-filters')) {
            $currentPlanLogger.append('<div class="col-12">Initialisation des filtres</div>')
            L.control.custom({
                position: affichageFilter,
                classes: 'w-20 capture-plan-hide-control',
                content: `
                    <div class="col-12 not-in-pdf">
                        <div class="row">
                            <div class="bg-d-blue col-12 rounded-pill btn-lf expand-plan txt-shadow tuto-plan-fullscreen" title="Agrandir le plan ?">
                                <i class="fal fa-expand ` + iconSize + `"></i>
                            </div>
                            <div class="toggle-container col-12 bg-d-blue rounded-pill txt-shadow btn-lf btn-plan-filters tuto-plan-filters" title="Afficher les filtres ?" 
                            data-target="panel-container">
                                <i class="fal fa-filter ` + iconSize + `"></i>
                            </div>
                            <div class="toggle-container bg-d-blue col-12 rounded-pill btn-lf txt-shadow filter-etat tuto-plan-etat-type" 
                            title="Afficher l'état des relevés ?">
                                <i class="fal fa-info ` + iconSize + `"></i>
                            </div>
                            <div class="toggle-container bg-d-blue col-12 rounded-pill btn-lf txt-shadow filter-conso tuto-plan-etat-conso"
                            title="Afficher la consommation des relevés ?">
                                <i class="fal fa-percent ` + iconSize + `"></i>
                            </div>
                            <div class="toggle-container bg-d-blue col-12 rounded-pill btn-lf txt-shadow filter-rules tuto-plan-rules"
                            title="Afficher les seuils des relevés ?">
                                <i class="fal fa-analytics  ` + iconSize + `"></i>
                            </div>
                            <div class="toggle-container bg-d-blue col-12 rounded-pill btn-lf txt-shadow tuto-plan-logs" 
                            title="Afficher le journal ?" 
                            data-target="plan-logs">
                            <i class="fal fa-comment-alt ` + iconSize + `"></i>
                            </div>
                            <div class="toggle-container bg-d-blue col-12 rounded-pill btn-lf txt-shadow tuto-plan-bg-color" 
                            title="Modifier la couleur d'arrière plan ?" 
                            data-target="plan-logs">
                            <i class="fal fa-circle ` + iconSize + ` btn-click" data-click="plan_background" style="color: ` + overlayBackgroundColor + `;"></i>
                            <input type="color" class="change-interactive-plan-bg hide" id="plan_background" value="` + overlayBackgroundColor + `" />
                            </div>
                        </div>
                    </div>
                    `
            }).addTo(map)
            // L.control.browserPrint({
            //     title: 'Options d\'impression',
            //     printModes: ["Portrait", "Landscape", "Auto", "Custom"]
            // }).addTo(map)
            L.control.custom({
                position: affichageSettings,
                classes: 'w-50 capture-plan-hide-control',
                content: `
                    <div class="col-12 not-in-pdf">
                        <div class="row text-right">
                            <div class="bg-d-blue col-12 rounded-pill btn-lf txt-shadow btn-load-modal"
                            data-url="` + cogsUrl + `"
                            title="Afficher les paramétrages ?" 
                            data-target="cogs-panel">
                                <i class="fal fa-cogs ` + iconSize + `"></i>
                            </div>
                            <div class="launch-tuto bg-d-blue col-12 rounded-pill btn-lf txt-shadow" title="Afficher le tutoriel ?" 
                            data-target="plan">
                                <i class="fal fa-question-circle ` + iconSize + `"></i>
                            </div>
                        </div>
                    </div>
                `
            }).addTo(map)
            L.control.custom({
                position: affichageLegend,
                classes: 'col-6 mh-150px px-0 a-transition',
                content: `
                    <div class="col">
                        <div class="plan-legend-container plan-legend-container-` + planId + ` row">
                        </div>
                    </div>
                `
            }).addTo(map)
        } else {
            L.control.custom({
                position: affichageLegend,
                classes: 'col-12 px-0 a-transition',
                content: `
                    <div class="col">
                        <div class="plan-legend-container in-report plan-legend-container-` + planId + ` row">
                        </div>
                    </div>
                `
            }).addTo(map)
        }
        // $('body #panel-plan-select-filters').
        // L.control.custom({
        //     position: 'topleft',
        //     content: `
        //     `
        // }).addTo(map)
        
        plan.planDebug('<i class="fal fa-check-circle"></i> Plan <b>#' + nodeId + '</b> end loading', 'info')
        
        plan.loadPanelListingPieges($plan.data('panel'))
        setTimeout(function () {
            plan.loadMarker($plan.data('feeds'), map, ratioIsModified, $plan)
        }, 500)
    })
    return

        // var sol = L.latLng([ 145, 175.2 ]);
        // L.marker(sol).addTo(map);
        // map.setView( [70, 120], 1);
        var yx = L.latLng;
        var xy = function(x, y) {
            if (L.Util.isArray(x)) {    // When doing xy([x, y]);
                return yx(x[1], x[0]);
            }
            return yx(y, x);  // When doing xy(x, y);
        };
       

        // var sol      = xy(175.2, 145.0);
        // var mizar    = xy( 41.6, 130.1);
        // var kruegerZ = xy( 13.4,  56.5);
        // var deneb    = xy(218.7,   8.3);
        // 
        // var LeafIcon = L.Icon.extend({
        //     options: {
        //         shadowUrl: 'http://127.0.0.1:8000/assets/img/thumbnail_image003.png',
        //         iconSize:     [38, 95],
        //         shadowSize:   [50, 64],
        //         iconAnchor:   [22, 94],
        //         shadowAnchor: [4, 62],
        //         popupAnchor:  [-3, -76]
        //     }
        // });

        // L.marker(     sol, {icon: greenIcon}).addTo(map).bindPopup(      'Sol');
        // L.marker(   mizar).addTo(map).bindPopup(    'Mizar');
        // L.marker(kruegerZ).addTo(map).bindPopup('Krueger-Z');
        // L.marker(   deneb).addTo(map).bindPopup(    'Deneb');
        

        // var travel = L.polyline([sol, deneb]).addTo(map);
}

const clearMapInstance = function () {
    mapsInstance = []
}

const planDebug = function (message, type = 'info') {
    let planContainerId = 'plan-debug-container'
    let $debugWrapper = tools.debugWrapper()

    let $planContainer = $('body #' + planContainerId)

    if ($planContainer.length <= 0) {
        $debugWrapper.append('<h5 class="h5 toggle-container" data-target="' + planContainerId + '"><i class="fal fa-bug"></i> Plan</h5><div class="row" id="' + planContainerId + '"></div>')
        
        $planContainer = $('body #' + planContainerId)
        $planContainer.hide()
    }

    let $alerts = $('body #' + planContainerId + ' .alert')
    // if ($alerts.length >= 25) {
    //     let limit = $alerts.length - 2
    //     $alerts.each(function (i, elm) {
    //         if (i >= limit) {
    //             return
    //         }
    //         $(elm).hide('slow')
    //     })
    // }
    
    const ts = moment().unix()
    const alertId = 'debug-alert-' + Math.floor(Math.random() * Math.floor(ts))

    $planContainer.append('<div id="' + alertId + '" class="alert col-12 rounded-0 alert-' + type + ' mb-1">' + message + '</div')

    let planContainerNode = document.getElementById(planContainerId)

    $planContainer.parent().animate({
        scrollTop: planContainerNode.scrollHeight
    }, 1000)
}

const getMapsInstance = function () {
    return mapsInstance
}

function drawPattern(img, size, canvasId) {
    var canvas = document.getElementById(canvasId)
    var tempCanvas = document.createElement("canvas"),
        tCtx = tempCanvas.getContext("2d")

    tempCanvas.width = size
    tempCanvas.height = size
    tCtx.drawImage(img, 0, 0, img.width, img.height, 0, 0, size, size)

    var ctx = canvas.getContext('2d')
    ctx.clearRect(0, 0, canvas.width, canvas.height)
    ctx.fillStyle = 'white'
    ctx.fillStyle = ctx.createPattern(tempCanvas, 'no-repeat')
    
    ctx.beginPath()
    ctx.rect(0,0,canvas.width,canvas.height)
    ctx.fill()
}

const openPanelPlan = function (elm) {
    let $panelPieges = $('body .panel-pieges')

    if ($panelPieges === undefined) {
        tools.toolsDebug('.panel-pieges not found', 'danger')

        return
    }

    $panelPieges.removeClass('position-absolute')
    $panelPieges.css('position', 'fixed')
    $panelPieges.css('top', '0')
    $panelPieges.css('left', '0')
    $panelPieges.css('bottom', '0')
    $panelPieges.css('max-width', '400px')
    $panelPieges.css('width', '400px')
    $panelPieges.css('margin-top', '0px')
    $panelPieges.draggable('disable')
    $(elm).addClass('panel-compress').removeClass('panel-expand').addClass('fa-compress').removeClass('fa-expand')
}

const closePanelPlan = function (elm) {
    let $panelPieges = $('body .panel-pieges')

    if ($panelPieges === undefined) {
        tools.toolsDebug('.panel-pieges not found', 'danger')

        return
    }

    $panelPieges.addClass('position-absolute')
    $panelPieges.css('position', 'absolute')
    $panelPieges.css('top', '0')
    $panelPieges.css('left', '0')
    $panelPieges.css('bottom', 'unset')
    $panelPieges.css('max-width', 'unset')
    $panelPieges.css('width', 'unset')
    $panelPieges.css('margin-top', '130px')
    $panelPieges.draggable('enable')
    $(elm).addClass('panel-expand').removeClass('panel-compress').addClass('fa-expand').removeClass('fa-compress')
}

const invalidateSize = function (mapId = null) {

    let mapInstances = plan.getMapsInstance()

    // console.log(mapInstances)

    for (var i = 0; i < mapInstances.length; i++) {
        let mapInstance = mapInstances[i]

        let mapInstanceContainer = mapInstance._container
        let $mapContainer = $(mapInstanceContainer)
        let mapContainerId = $mapContainer.attr('id')

        if (mapId !== null && mapId === mapContainerId || mapId === null) {
            mapInstance.setZoom(0)
            mapInstance.invalidateSize()
        }
        // console.log(mapInstanceContainer, mapContainerId)
    }
}

const makePicture = async function (planId, btn) {
    plan.loader('Préparation de votre fichier en cours <i class="fal fa-circle-notch fa-spin"></i>', false, 1, true)
    let $btn = $(btn)
    let $lfLoaderInfos = $('body .lf-loader .loader-infos')
    let $plan = $('body #' + planId)
    let originHeight = $plan.height()
    let originWidth = $plan.width()
    let planHeight = $plan.data('height')
    let planWidth = $plan.data('width')
    $('body .not-in-pdf').hide()
    $('body .return-plan-container').show()
    $('body .legend-plan-switcher').removeClass('d-flex').addClass('d-none')
    $('body .plan-legend-container').show()
    $('body .plan-legend-container .col-lg-4').addClass('col-lg-12').removeClass('bg-white')
    $('body .plan-legend-container .col-lg-4 h5').addClass('font-size-20')
    $('body .plan-legend-container').parent().parent().removeClass('mh-150px')
    // console.log($('body .plan-legend-container .col-lg-4 h5'))
    $plan.css('width', planWidth + 'px')
    $plan.css('max-width', planWidth + 'px')
    $plan.css('overflow', 'visible')
    $plan.css('height', planHeight + 'px')
    $plan.css('max-height', planHeight + 'px')
    $('body .leaflet-top .leaflet-control-zoom, body .capture-plan-hide-control').hide('slow')
    $lfLoaderInfos.html('Mise à niveau des éléments en cours...')
    await tools.sleep(2500)

    let currentMapsInstance = plan.getMapsInstance()
    for (var i = 0; i < currentMapsInstance.length; i++) {
        let mapInstance = currentMapsInstance[i]
        mapInstance.setZoom(0)
        mapInstance.invalidateSize()
    }

    $lfLoaderInfos.html('Optimisation du plan et de ses éléments en cours...')
    let $elm = $btn
    let targetId = $elm.data('target')
    if (targetId == undefined || targetId == '') {
        return
    }
    let receiptId = 'return-' + targetId
    let $receipt = $('body #' + receiptId)
    let $origin = $('body #' + targetId)
    $receipt.addClass('py-0 my-0')
    let receipt = document.getElementById(receiptId)
    $receipt.css('height', $origin.height())
    $receipt.css('min-height', $origin.height())
    $receipt.css('width', $origin.width())
    $receipt.css('min-width', $origin.width())
    await tools.sleep(5000)

    html2canvas(document.querySelector('#' + targetId), {
        scrollX: 0,
        scrollY: -window.scrollY,
        useCORS: true,
    }).then(async canvas => {
        $('.report-logs').append('<div>Plan is transformed (to canvas)</div>')
        $(canvas).addClass('generated-plan-canvas')
        $(canvas).attr('id', 'generated-plan-' + tools.getRandomInt())
        $(canvas).attr('data-plan-id', $origin.data('plan-id'))
        $(canvas).attr('data-intervention-id', $origin.data('intervention-id'))
        await tools.sleep(5000)
        receipt.appendChild(canvas)
        let dataURL = canvas.toDataURL()
        $lfLoaderInfos.html('Plan optimisé, envoi des informations...')

        let planMakePictureUri = $plan.data('picture-uri')

        if (planMakePictureUri == undefined) {
            console.log('planMakePictureUri is undefined')
            return
        }

        let planPictureFormData = new FormData()

        planPictureFormData.append('planDataURL', dataURL)
        planPictureFormData.append('height', $origin.height())
        planPictureFormData.append('width', $origin.width())
        planPictureFormData.append('interventionId', $plan.data('intervention-id'))
        console.log('plan-intervention-id => ' + $plan.data('intervention-id'))

        $.post({
            url: planMakePictureUri,
            data: planPictureFormData,
            processData: false,
            contentType: false,
            success: function (response) {
                // console.log('response', response)
                if (response.state != true) {
                    plan.removeLoader()
                    return
                }
                $('body .loader-message').html('Génération de votre fichier terminée !')
                $lfLoaderInfos.hide()
                $('body .loader-ico').removeClass('fa-spin').addClass('fa-check-circle').removeClass('fa-circle-notch')
                $('body .loader-results').append('<a class="btn btn-d-blue rounded-pill" href="' + response.link + '" target="_blank" title="Télécharger mon fichier ?"><i class="fal fa-file-download"></i> Télécharger le fichier</a>')
                $('body .loader-actions').slideDown('slow')
                $('body .loader-results').fadeIn('slow')
                $('body .plan-legend-container .col-lg-4').removeClass('col-lg-12')
                $('body .plan-legend-container .col-lg-4 h5').removeClass('font-size-25')
            },
            complete: function (complete) {
                // console.log('complete', complete)
                loaderLib.flashLoader()
            },
            error: function (error) {
                console.log('error', error)
            }
        })

        $('body .not-in-pdf').show()
        $plan.css('overflow', 'hidden')
        $('body .leaflet-top .leaflet-control-zoom, body .capture-plan-hide-control').show('slow')
        $('body .legend-plan-switcher').removeClass('d-none').addClass('d-flex')        
        $plan.css('width', originWidth + 'px')
        $plan.css('max-width', originWidth + 'px')
        $plan.css('height', originHeight + 'px')
        $plan.css('max-height', originHeight + 'px')
        $('body .return-plan-container').hide()
        for (var i = 0; i < currentMapsInstance.length; i++) {
            let mapInstance = currentMapsInstance[i]
            mapInstance.setZoom(0)
            mapInstance.invalidateSize()
        }
        // plan.removeLoader()
    })
}

const plan = {
    load,
    planDebug,
    loadPanelListingPieges,
    loadMarker,
    loader,
    getMapsInstance,
    openPanelPlan,
    closePanelPlan,
    invalidateSize,
    clearMapInstance,
    makeLegend,
    removeLoader,
    makePicture,
    exportExcel
}

export default plan