import report from "../report";
import tools from "../tools";

let init = function () {
    if ($('#reportV2-container').length <= 0 || $('#reportV2-container').hasClass('initialized')) {
        return
    }

    const dtLanguage = {
        processing: "Traitement en cours...",
        search: "",
        lengthMenu: "_MENU_",
        info: "_START_ &agrave; _END_ sur _TOTAL_",
        infoEmpty: "",
        infoFiltered: "",
        infoPostFix: "",
        loadingRecords: "Chargement en cours...",
        zeroRecords: "Aucun &eacute;l&eacute;ment &agrave; afficher",
        emptyTable: "Aucune donnée disponible dans le tableau",
        paginate: {
            first: "<<",
            previous: "<",
            next: ">",
            last: ">>"
        },
        aria: {
            sortAscending: ": activer pour trier la colonne par ordre croissant",
            sortDescending: ": activer pour trier la colonne par ordre décroissant"
        },
        select: {
            cells: "",
            columns: "",
            rows: ""
        }
    }

    // parameters used on tab click
    let navParams = {
        interventions: {
            isFirstLoad: true,
            isGeneratedLists: false,
            name: 'interventions',
            dtTables: {
                Intervention: {
                    isAjax: true,
                    entity: 'Intervention',
                }
            }
        },
        plans: {
            isFirstLoad: true,
            isGeneratedLists: false,
            name: 'plans',
            dtTables: {
                Plan: {
                    isAjax: true,
                    entity: 'Plan',
                }
            }
        },
        zones: {
            isFirstLoad: true,
            isGeneratedLists: true,
            url: Routing.generate('app_customer_reportV2_zones', {
                slug: tools.slug()
            }),
            name: 'zones',
            columns: [
                {name: '', orderable: false, visible: true, className: 'w-10'},
                {name: 'id', orderable: false, visible: false, searchable: false},
                {name: 'nom', orderable: true}
            ],
            dtTables: {}
        },
        nuisibles: {
            isFirstLoad: true,
            isGeneratedLists: true,
            url: Routing.generate('app_customer_reportV2_nuisibles', {
                slug: tools.slug()
            }),
            name: 'nuisibles',
            columns: [
                {name: '', orderable: false, visible: true, className: 'w-10'},
                {name: 'id', orderable: false, visible: false, searchable: false},
                {name: 'nom', orderable: true}
            ],
            dtTables: {}
        },
        dispositifs: {
            isFirstLoad: true,
            isGeneratedLists: true,
            url: Routing.generate('app_customer_reportV2_dispositifs', {
                slug: tools.slug()
            }),
            name: 'dispositifs',
            columns: [
                {name: '', orderable: false, visible: true, className: 'w-10'},
                {name: 'id', orderable: false, visible: false, searchable: false},
                {name: 'identification', orderable: true}
            ],
            dtTables: {}
        },
        seuils: {
            isFirstLoad: true,
            isGeneratedLists: true,
            url: Routing.generate('app_customer_reportV2_seuils', {
                slug: tools.slug()
            }),
            name: 'seuils'
        },
        photos: {
            isFirstLoad: true,
            isGeneratedLists: true,
            url: Routing.generate('app_customer_reportV2_photos', {
                slug: tools.slug()
            }),
            name: 'photos'
        },
        actionsAMener: {
            isFirstLoad: true,
            isGeneratedLists: false,
            name: 'actionsAMener',
            dtTables: {
                ActionAMener: {
                    isAjax: true,
                    entity: 'ActionAMener',
                }
            }
        },
    }

    //Contains all elements, indexes are the id of the element, tru/false if checked
    let reportData = {
        nav: navParams.interventions.name,
        selectedItems: {
            contrat: [],
            intervention: '',
            interventions: {
                Intervention: {
                    dateStart: null,
                    dateEnd: null,
                    isAllSelected: true,
                    items: {},
                }
            },
            plans: {
                Plan: {
                    isAllSelected: true,
                    items: {},
                }
            },
            zones: {
                ZoneV2: {
                    isAllSelected: true,
                    items: {},
                },
                Zone: {
                    isAllSelected: true,
                    items: {},
                }
            },
            nuisibles: {},
            dispositifs: {isSansComptage: true},
            seuils: {
                Seuil: {
                    isAllSelected: true,
                    items: {},
                }
            },
            photos: {
                Photo: {
                    isAllSelected: true,
                    items: {},
                },
                PhotoR: {
                    isAllSelected: true,
                    items: {},
                }
            },
            actionsAMener: {
                ActionAMener: {
                    isAllSelected: true,
                    items: {},
                }
            }
        }
    }
    let datatables = {};
    let isUserAction = true;

    $('#reportV2-container').empty().addClass('initialized')

    const DATATABLE_LOADER_URL = Routing.generate('app_customer_reportV2_datatable_loader', {
        slug: tools.slug()
    });
    const TABLE_TYPES_URL = Routing.generate('app_customer_reportV2_table_types', {
        slug: tools.slug()
    });

    const ALL_INTERS_URL = Routing.generate('app_customer_reportV2_all_inters', {
        slug: tools.slug(),
    })

    const ALL_PLANS_URL = Routing.generate('app_customer_reportV2_all_plans', {
        slug: tools.slug()
    })

    const ALL_ACTION_URL = Routing.generate('app_customer_reportV2_all_actions', {
        slug: tools.slug()
    })

    const REPORT_URL = Routing.generate('app_customer_reportV2_report', {
        slug: tools.slug()
    })

    const contrat = $('#report-navbar-data').data('codecontrat')
    const intervention = $('#report-navbar-data').data('intervention')

    reportData.selectedItems.contrat.push(contrat);

    let report = $('#report-navbar-data').data('report');

    const EXPORT_EXCEL_URL = Routing.generate('app_customer_reportV2_export_excel', {
        slug: tools.slug(),
    })

    $(document).off('change', '#date-start');
    $(document).on('change', '#date-start', function (e) {
        let start = reportData.selectedItems.interventions.Intervention.dateStart = $(this).val() ? $(this).val() : null;
        let end = reportData.selectedItems.interventions.Intervention.dateEnd;
        // if (isDateRangeConstraints(start, end)) {
            reloadDataTable(datatables['interventions-Intervention']);
        // }
    })

    $(document).off('change', '#date-end');
    $(document).on('change', '#date-end', function (e) {
        let start = reportData.selectedItems.interventions.Intervention.dateStart;
        let end = reportData.selectedItems.interventions.Intervention.dateEnd = $(this).val() ? $(this).val() : null;
        // if (isDateRangeConstraints(start, end)) {
            reloadDataTable(datatables['interventions-Intervention']);
        // }
    })

    $(document).off('click', '#dispositif-check');
    $(document).on('click', '#dispositif-check', function (e) {
        reportData.selectedItems.dispositifs.isSansComptage = $(this).is(':checked');
        $('#dispositif-nav').trigger('click');
    })

    $(document).off('click', '#btn-report-excel');
    $(document).on('click', '#btn-report-excel', function (e) {
        let $elm = $(this)
        if ($elm.hasClass('fired')) {
            return
        }
        $elm.addClass('fired')
        $elm.children('span').html('Export en cours...')
        e.preventDefault();
        $.post({
            url: EXPORT_EXCEL_URL,
            xhrFields: {
                responseType: 'blob' // to avoid binary data being mangled on charset conversion
            },
            data: {
                report: report,
                reportData: reportData
            },
            success: function (blob, status, xhr) {
                $elm.removeClass('fired')
                $elm.children('span').html('Export Excel')
                // check for a filename
                let filename = "";
                let disposition = xhr.getResponseHeader('Content-Disposition');
                if (disposition && disposition.indexOf('attachment') !== -1) {
                    let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    let matches = filenameRegex.exec(disposition);
                    if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
                }

                if (typeof window.navigator.msSaveBlob !== 'undefined') {
                    // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
                    window.navigator.msSaveBlob(blob, filename);
                } else {
                    var URL = window.URL || window.webkitURL;
                    // var downloadUrl = blob;
                    var downloadUrl = URL.createObjectURL(blob);

                    if (filename) {
                        // use HTML5 a[download] attribute to specify filename
                        var a = document.createElement("a");
                        // safari doesn't support this yet
                        if (typeof a.download === 'undefined') {
                            window.location.href = downloadUrl;
                        } else {
                            a.href = downloadUrl;
                            a.download = filename;
                            document.body.appendChild(a);
                            a.click();
                        }
                    } else {
                        window.location.href = downloadUrl;
                    }

                    setTimeout(function () {
                        URL.revokeObjectURL(downloadUrl);
                    }, 100); // cleanup
                }
            }
        });
    });


    //gets ajax columns infos
    let ajaxTables = {};
    for (const nav in navParams) {
        if (navParams.hasOwnProperty(nav)) {
            for (const table in navParams[nav].dtTables) {
                if (navParams[nav].dtTables.hasOwnProperty(table)) {
                    if (navParams[nav].dtTables[table].isAjax) {
                        if (ajaxTables[nav]) {
                            ajaxTables[nav].push(table);
                        } else {
                            ajaxTables[nav] = [table];
                        }
                    }
                }
            }
        }
    }

    $.get(ALL_INTERS_URL, {contrat: reportData.selectedItems.contrat[0], intervention:intervention, report: report}, function (data) {
        let selectedIds = {};

            if(!intervention) {
                for (let i = 0; i < data.length; i++) {
                    selectedIds[data[i]] = true;
                }
            } else {
                data = intervention
                selectedIds[data] = true;
            }

        reportData.selectedItems.interventions.Intervention.items = selectedIds;
    })

    if (report) {
        $.post(REPORT_URL, {report: report}, function (data) {
            reportData.selectedItems.interventions.Intervention.items = booleanize(data.interventions);
            reportData.selectedItems.plans.Plan.items = booleanize(data.plans);
            reportData.selectedItems.zones.Zone.saved = data.zones;
            reportData.selectedItems.zones.ZoneV2.saved = data.zonesV2;
            reportData.selectedItems.nuisibles.saved = data.nuisibles;
            reportData.selectedItems.dispositifs.saved = data.dispositifs;
            reportData.selectedItems.seuils.Seuil.saved = data.seuils;
            reportData.selectedItems.photos.Photo.saved = data.photos;
            reportData.selectedItems.photos.PhotoR.saved = data.photosR;
            reportData.selectedItems.actionsAMener.ActionAMener.items = booleanize(data.actionsAMener);
            reportData.selectedItems.dispositifs.isSansComptage = data.isSansComptage;


            if (data.dateStart) {
                let date = new Date(data.dateStart.date);
                let month = date.getMonth().toString().length === 1 ? '0'+ date.getMonth() : date.getMonth();
                reportData.selectedItems.interventions.Intervention.dateStart = date.getFullYear() + '-' +  month + '-' +  date.getDate();
            }
            if (data.dateEnd) {
                let date = new Date(data.dateEnd.date);
                let month = date.getMonth().toString().length === 1 ? '0'+ date.getMonth() : date.getMonth();
                reportData.selectedItems.interventions.Intervention.dateEnd = date.getFullYear() + '-' +  month + '-' +  date.getDate();
            }

            loadFirstNav();
        })
    } else {
        loadFirstNav();
    }

    function loadFirstNav() {
        //gets all columns definitions from DataTableService
        $.get(TABLE_TYPES_URL, {tables: ajaxTables}, function (data) {
            for (const nav in data) {
                if (data.hasOwnProperty(nav)) {
                    for (const table in data[nav]) {
                        if (data[nav].hasOwnProperty(table)) {
                            navParams[nav].dtTables[table].columns = data[nav][table]
                        }
                    }
                }
            }

            initDataTable('interventions', navParams.interventions.dtTables.Intervention);
        })
    }

    //does what it says, it's true
    function booleanize(array) {
        let booledObject = {};
        for (let i = 0; i < array.length; i++) {
            booledObject[array[i]] = true;
        }
        return booledObject;
    }


    $(document).off('click', '#save-report');
    $(document).on('click', '#save-report', function (e) {
        e.preventDefault();
        let url = $(this).data('url');
        $.post(url, {reportData: reportData})
    })

    $(document).off('click', '.btn-edit-report');
    $(document).on('click', '.btn-edit-report', function (e) {
        e.preventDefault();
        let url = $(this).data('url');
        let report = $(this).data('report');
        $.post(url, {report: report, reportData: reportData})
    })

    $(document).off('click', '.nav-report')
    $(document).on('click', '.nav-report', function (e) {
        let hasOneItemSelected = false;
        let countItems = 0;
        // alert if no item is selected when changing tab
        if (reportData.nav !== 'seuils' && reportData.nav !== 'photos' && reportData.nav !== 'actionsAMener') {
            for (const table in reportData.selectedItems[reportData.nav]) {
                if (reportData.selectedItems[reportData.nav].hasOwnProperty(table)) {
                    let items = reportData.selectedItems[reportData.nav][table].items;
                    for (const id in items) {
                        if (items.hasOwnProperty(id)) {
                            countItems++;
                            if (items[id]) {
                                hasOneItemSelected = true;
                                break;
                            }
                        }
                    }
                }
            }

            if (hasOneItemSelected === false && countItems > 0) {
                alert('Sélectionnez au moins un élément dans la liste.');
                return;
            }
        }

        $('.nav-report').removeClass('active');
        $(this).addClass('active');
        $('#reportV2-container').removeClass('d-flex flex-row');
        e.preventDefault();
        $('#reportV2-container').empty();
        reportData.nav = $(this).data('nav');

        $('#reportV2-actions').empty();

        let isFirstLoad = navParams[reportData.nav].isFirstLoad;

        if (navParams[reportData.nav].isGeneratedLists) {
            $('#reportV2-container').addClass('horizontal-scroll ');
            $.post(navParams[reportData.nav].url, {reportData: reportData}, function (response) {
                if (reportData.nav === 'seuils' || reportData.nav === 'photos') {
                    $('#reportV2-container')
                        .removeClass('horizontal-scroll')
                        .append(response);
                    let nav = reportData.nav;

                    $('.select-check-Seuil').each(function () {
                        const id = $(this).attr('id').split('-')[2];
                        if (report && isFirstLoad) {
                            reportData.selectedItems.seuils.Seuil.items[id] = reportData.selectedItems.seuils.Seuil.saved.includes(parseInt(id));
                        } else {
                            reportData.selectedItems.seuils.Seuil.items[id] = reportData.selectedItems.seuils.Seuil.items[id] === undefined
                                ? true : reportData.selectedItems.seuils.Seuil.items[id];
                        }
                    })

                    $('.select-Photo').each(function () {
                        const id = $(this).attr('id').split('-')[2];
                        if (report && isFirstLoad) {
                            reportData.selectedItems.photos.Photo.items[id] = reportData.selectedItems.photos.Photo.saved.includes(parseInt(id));
                        } else {
                            reportData.selectedItems.photos.Photo.items[id] = reportData.selectedItems.photos.Photo.items[id] === undefined
                                ? true : reportData.selectedItems.photos.Photo.items[id];
                        }
                    })

                    $('.select-PhotoR').each(function () {
                        const id = $(this).attr('id').split('-')[2];
                        if (report && isFirstLoad) {
                            reportData.selectedItems.photos.PhotoR.items[id] = reportData.selectedItems.photos.PhotoR.saved.includes(parseInt(id));
                        } else {
                            reportData.selectedItems.photos.PhotoR.items[id] = reportData.selectedItems.photos.PhotoR.items[id] === undefined
                                ? true : reportData.selectedItems.photos.PhotoR.items[id];
                        }
                    })


                    if (nav === 'seuils') {
                        selectRows(nav, 'Seuil')
                        rowListeners($('.report-table'), 'Seuil');
                    } else {
                        selectRows(nav, 'Photo')
                        selectRows(nav, 'PhotoR')

                        rowListeners($('.select-PhotoR'), 'PhotoR');
                        rowListeners($('.select-Photo'), 'Photo');
                    }

                    function selectRows(nav, table) {
                        let selection = reportData.selectedItems[nav][table];
                        let isAllSelected = selection.isAllSelected.toString() === 'true';
                        $('#select-all-' + table).prop("checked", isAllSelected);

                        for (const id in selection.items) {
                            if (selection.items.hasOwnProperty(id)) {
                                $('#selected-' + table + '-' + id).prop("checked", selection.items[id]);
                            }
                        }
                    }

                    function rowListeners(rows, table) {
                        rows.off('click')
                        rows.on('click', function (e) {
                            if (e.target.nodeName.toLowerCase() !== 'img') {
                                let id = $(this).attr('id').split('-')[2];
                                let checkBox = $('#selected-' + table + '-' + id);
                                let isChecked = checkBox.is(':checked');
                                isChecked = !isChecked;
                                checkBox.prop("checked", isChecked)
                                reportData.selectedItems[nav][table].items[id] = isChecked;
                            }
                        })
                    }
                    //nuisibles dispositifs et zones
                } else if (response.success) {
                    $('#reportV2-container').addClass('d-flex flex-row');
                    let tables = response.tables;
                    if (reportData.nav === 'dispositifs' && $('#dispositif-check').length === 0) {
                        let isChecked = reportData.selectedItems.dispositifs.isSansComptage ? 'checked' : '';
                        $('#reportV2-actions').empty().append('<input type="checkbox" id="dispositif-check" ' + isChecked + '> Prendre en compte les dispositifs sans capture</input>');
                    }

                    let savedItems = [];
                    if (reportData.selectedItems[reportData.nav].saved) {
                        savedItems = reportData.selectedItems[reportData.nav].saved;
                    }

                    for (const tableId in tables) {
                        if (tables.hasOwnProperty(tableId)) {
                            let tableName = tables[tableId].name
                            let data = [];

                            if (tableName === 'ZoneV2' || tableName === 'Zone') {
                                if(tableName === 'ZoneV2') {
                                    tableName = 'Zone personalisée'
                                }
                                navParams.zones.columns[2]['name'] = tableName
                            }

                            tableName = tables[tableId].name
                            
                            if (isFirstLoad) {
                                let items = {};
                                if (tableName === 'ZoneV2' || tableName === 'Zone') {
                                    savedItems = reportData.selectedItems['zones'][tableName].saved
                                        ? reportData.selectedItems['zones'][tableName].saved : [];
                                        console.log(navParams.zones.columns[2]['name'])
                                }


                                if (tableName === 'ZoneV2') {
                                    items = {0: true};
                                }

                                // reportData.selectedItems['zones']['ZoneV2'] = {
                                //     isAllSelected: true,
                                //     items: {indefini:true}
                                // };


                                reportData.selectedItems[reportData.nav][tableId] = {
                                    isAllSelected: true,
                                    items: items
                                };

                            }

                            for (const id in tables[tableId].data) {
                                if (tables[tableId].data.hasOwnProperty(id)) {
                                    data.push(tables[tableId].data[id])
                                    if (isFirstLoad) {
                                        let selected = true;
                                        if (savedItems.length > 0) {
                                            selected = savedItems.includes(parseInt(id));
                                        }
                                        reportData.selectedItems[reportData.nav][tableId].items[id] = selected;

                                    }
                                }
                            }
                            //dev mode only
                            // navParams[reportData.nav].columns[0].visible = response.isDev;
                            navParams[reportData.nav].columns[0].visible = true;

                            if (reportData.nav === 'nuisibles' || reportData.nav === 'dispositifs') {
                                navParams[reportData.nav].columns[2].name = tableName;
                            }

                            navParams[reportData.nav].dtTables[tableId] = {
                                tableId: tableId,
                                isAjax: false,
                                entity: tableName,
                                columns: navParams[reportData.nav].columns,
                                data: data
                            }

                            initDataTable(reportData.nav, navParams[reportData.nav].dtTables[tableId]);
                        }
                    }
                }
            })
        } else {
            //actionsAMener et Plans
            $('#reportV2-container').removeClass('horizontal-scroll ');
            let dataTables = navParams[reportData.nav].dtTables;
            for (const table in dataTables) {
                if (dataTables.hasOwnProperty(table)) {
                    if (table === 'Plan') {
                        $.post(ALL_PLANS_URL, {reportData: reportData}, function (data) {
                            let selectedIds = {};
                            for (let i = 0; i < data.length; i++) {
                                if (isFirstLoad && $.isEmptyObject(reportData.selectedItems.plans.Plan.items)) {
                                    selectedIds[data[i]] = reportData.selectedItems.plans.Plan.items[data[i]]
                                        ? reportData.selectedItems.plans.Plan.items[data[i]] : true;
                                } else {
                                    selectedIds[data[i]] = reportData.selectedItems.plans.Plan.items[data[i]]
                                        ? reportData.selectedItems.plans.Plan.items[data[i]] : false;
                                }
                            }

                            reportData.selectedItems.plans.Plan.items = selectedIds;
                            initDataTable(reportData.nav, navParams[reportData.nav].dtTables[table]);
                        })
                    } else if (table === 'ActionAMener') {
                        $.post(ALL_ACTION_URL, {reportData: reportData}, function (data) {
                            let selectedIds = {};
                            for (let i = 0; i < data.length; i++) {
                                if (isFirstLoad && $.isEmptyObject(reportData.selectedItems.actionsAMener.ActionAMener.items)) {
                                    selectedIds[data[i]] = true;
                                } else {
                                    selectedIds[data[i]] = reportData.selectedItems.actionsAMener.ActionAMener.items[data[i]]
                                        ? reportData.selectedItems.actionsAMener.ActionAMener.items[data[i]] : false;
                                }
                            }
                            reportData.selectedItems.actionsAMener.ActionAMener.items = selectedIds;
                            initDataTable(reportData.nav, navParams[reportData.nav].dtTables[table]);
                        })
                    } else {
                        //inters
                        initDataTable(reportData.nav, navParams[reportData.nav].dtTables[table]);
                    }
                }
            }
        }
        navParams[reportData.nav].isFirstLoad = false;
    })

    //help for debug selected items
    if ($('#log-report-data').length > 0) {
        $(document).off('click', '#log-report-data')
        $(document).on('click', '#log-report-data', function (e) {
            console.log(navParams);
            console.log(reportData.selectedItems);
        })
    }

    $(document).off('click', '.select-all')
    $(document).on('click', '.select-all', function (e) {
        e.stopPropagation();
        let isChecked = $(this).is(':checked');
        let el = $(this);
        let nav = el.data('nav');
        let table = el.data('table');
        let dataTable = datatables[nav + '-' + table];

        reportData.selectedItems[reportData.nav][table].isAllSelected = isChecked;
        for (const key in reportData.selectedItems[reportData.nav][table].items) {
            if (reportData.selectedItems[reportData.nav][table].items.hasOwnProperty(key)) {
                reportData.selectedItems[reportData.nav][table].items[key] = isChecked;
            }
        }

        if (dataTable) {
            isUserAction = false;
            isChecked ? dataTable.DataTable().rows().select() : dataTable.DataTable().rows().deselect();
            isUserAction = true;
        }
        $('.select-check-' + table).prop("checked", isChecked);

        if (reportData.nav === 'interventions' && isUserAction) {
            showHideSeuilsPhotos(reportData);
        }
    })


    function initDataTable(nav, dtTable) {
        const isAjax = dtTable.isAjax;
        const columns = dtTable.columns;
        columns.forEach(c => {
            if (c.name !== 'id') {
                c.visible = true
            }
        })
        const table = dtTable.tableId ? dtTable.tableId : dtTable.entity;
        const isAllSelected = reportData.selectedItems[reportData.nav][table].isAllSelected
        let options;
        const checked = isAllSelected ? 'checked' : '';
        //dataTable html
        let columnHeaders = `<thead>`;
        columnHeaders += `<th><input class="select-all" type="checkbox"
                            value="" data-nav="${nav}" data-table="${table}"
                            ${checked}></th>`;
        for (let i = 1; i < columns.length; i++) {
            columnHeaders += `<th>${columns[i].name}</th>`;
        }
        columnHeaders += ' </thead>';

        let dateStart = report ? reportData.selectedItems.interventions.Intervention.dateStart : '';
        let dateEnd = report ? reportData.selectedItems.interventions.Intervention.dateEnd : '';

        const dateRangeHtml = `
                <div class="row mt-1">
                    <div class="col-lg-6 input-group">
                        <div class="input-group-prepend">
                            <label for="date-start" class="input-group-text">
                                Début
                            </label>
                        </div>
                        <input type="date" id="date-start" name="date-start" class="form-control" value="${dateStart}">
                    </div>
                    <div class="col-lg-6 input-group">
                        <div class="input-group-prepend">
                            <label for="date-end" class="input-group-text">
                                Fin
                            </label>
                        </div>
                        <input type="date" id="date-end" name="date-end" class="form-control" value="${dateEnd}">
                    </div>
                </div>
                `;


        let tableHtml = '';
        if (table === 'Intervention') {
            tableHtml += dateRangeHtml;
        }

        tableHtml += ` <div class="mt-1">`
        tableHtml += `<table class="report-table table initialized datatable-select w-100 search-col-12 table-striped" id="table-${table}"
                                   data-table="${table}">
                             ${columnHeaders}
                            </table></div>`;

        $('#reportV2-container').append(tableHtml);


        //dataTable options
        if (isAjax) {
            options = {
                processing: true,
                serverSide: true,
                searching: true,
                'columnDefs': [
                    {
                        'targets': 0,
                        'checkboxes': {
                            'selectRow': true
                        }
                    }
                ],
                select: {
                    style: "multi",
                    selector: "td"
                },
                pageLength: 50,
                order: [],
                columns: columns,
                ajax: {
                    'url': DATATABLE_LOADER_URL,
                    'type': 'POST',
                    'data': function (d) {
                        Object.assign(d, {
                            table: table,
                            contrat: reportData.selectedItems.contrat,
                            intervention: reportData.selectedItems.intervention,
                            nav: reportData.nav,
                            reportData: reportData
                        });
                        return d;
                    },
                },
                language: {
                    "url": "https://cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/French.json"
                },
            }
        } else {
            if (table === 'ZoneV2') {
                dtTable.data.unshift([
                    "<input id=\"selected-ZoneV2-0\" class=\"select-check-ZoneV2\" type=\"checkbox\">",
                    "0",
                    "Indéfini",
                ])
            }
            options = {
                searching: true,
                data: dtTable.data,
                select: {
                    style: 'multi',
                    selector: "td"
                },
                pageLength: 50,
                order: [],
                columns: columns,
                //callback function for non ajax
                "drawCallback": function () {
                    isUserAction = false;
                    let selection = reportData.selectedItems[nav][table];

                    //select or unselect items from list
                    let dataTable = this.api();
                    let rows = dataTable.rows().data();
                    $('.select-check-' + table).prop("checked", false);
                    for (let i = 0; i < rows.length; i++) {
                        if (selection.items[rows[i][1]]) {
                            dataTable.row(i).select();
                            $(this).find('#selected-' + table + '-' + rows[i][1]).prop("checked", true);
                        }
                    }

                    isUserAction = true;
                },
                language: dtLanguage
            }
        }


        //init datatable
        datatables[nav + '-' + table] = $('#table-' + table).dataTable(options);

        //click on photo will not select the row
        datatables[nav + '-' + table].off('user-select.dt')
        datatables[nav + '-' + table].on('user-select.dt', function (e, dt, type, cell, originalEvent) {
            if (originalEvent.target.nodeName.toLowerCase() === 'img') {
                return false;
            }
        });

        //filters selectedItems on draw
        datatables[nav + '-' + table].off('draw.dt');
        datatables[nav + '-' + table].on('draw.dt', function (e, settings) {
            isUserAction = false;
            let selection = reportData.selectedItems[nav][table];
            //select or unselect items from list
            let dataTable = datatables[nav + '-' + table].DataTable();
            let rows = dataTable.rows().data();
            $('.select-check-' + table).prop("checked", false);

            for (let i = 0; i < rows.length; i++) {
                if (selection.items[rows[i][1]]) {
                    dataTable.row(i).select();
                    $(this).find('#selected-' + table + '-' + rows[i][1]).prop("checked", true);
                }
            }
            if (reportData.nav === 'interventions') {
                showHideSeuilsPhotos(reportData);
            }

            isUserAction = true;
        })

        datatables[nav + '-' + table].off('select.dt deselect.dt');
        datatables[nav + '-' + table].on('select.dt deselect.dt', function (e, dt, type, indexes) {
            if (isUserAction === false) {
                return;
            }
            let rowData = $(this).DataTable().rows(indexes).data();
            let items = reportData.selectedItems[nav][table].items;
            reportData.selectedItems[nav][table].items[rowData[0][1]] = !reportData.selectedItems[nav][table].items[rowData[0][1]];
            $(this).find('#selected-' + table + '-' + rowData[0][1]).prop("checked", items[rowData[0][1]]);

            if (nav === 'interventions' && isUserAction) {
                showHideSeuilsPhotos(reportData);
            }
        })

        // console.log('#table-' + table + '_filter')
        // console.log($('body #table-' + table + '_filter').length)
        $('body #table-'+table+'_filter').parent().removeClass('col-sm-12 col-md-6').addClass('col-12')
        $('body #table-'+table+'_paginate').parent().removeClass('col-sm-12 col-md-7').addClass('col-12')
        $('body #table-'+table+'_filter').parent().removeClass('col-sm-12 col-md-6').addClass('col-12')
        $('body #table-'+table+'_filter label').addClass('w-100')
        $('body #table-'+table+'_filter label input').addClass('w-100')
        $('body #table-'+table+'_filter label input').addClass('ml-0')
        $('body #table-'+table+'_filter label input').attr('placeholder', 'Rechercher...')
    }
}

//show only if one inter selected
let showHideSeuilsPhotos = function (reportData) {
    let items = reportData.selectedItems.interventions.Intervention.items;
    let countInters = 0;
    for (const id in items) {
        if (items.hasOwnProperty(id)) {
            if (items[id]) {
                countInters++;
            }
        }
    }
    countInters === 1
        ? $('.unique-inter-btn').removeClass('disabled')
        : $('.unique-inter-btn').addClass('disabled');
}

let reloadDataTable = function (dataTable) {
    dataTable.DataTable().ajax.reload();
}

let isDateRangeConstraints = function (startDate, endDate) {
    let start = startDate ? new Date(startDate) : false;
    let end = endDate ? new Date(endDate) : false;

    if ((start && end) && start > end) {
        alert('La date de fin doit être supérieure ou égale à la date de début.');
        return false;
    }
    return true;
}

const reportV2 = {
    init
}

export default reportV2