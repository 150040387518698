import 'jquery-bootgrid/dist/jquery.bootgrid.js';
import 'interactiveimagejs/dist/interactive-image';
import 'jquery-modal';

import actionExport from './actionExport'
import anime from 'animejs/lib/anime.es.js';
import loader from './loader';
import moment from 'moment';
import plan from './plan';
import report from './report';
import reportV2 from "./report/reportV2";
import select2 from 'select2';
import tarteaucirton from '../../public/assets/js/tarteaucitron';
import tinymce from 'tinymce';

let process = [];
let calledUrls = [];
let tacIsLaunched = false;
let colors = [
    'primary',
    'red',
    'aqua-green',
    'd-blue',
    'peach',
    'yellow',
    'danger',
    'secondary'
]
;
const loadingWrapper = function (color = 'primary', multiple = 1, mx = 0, mt = 0, type = null) {
    let wrapper = '';
    let marginClass = (mx == 0 || mt == 0) ? 'm-auto' : 'mx-' + mx + ' mt-' + mt;
    if (mx != 0) {
        marginClass = 'my-auto mx-' + mx;
    }
    if (mt != 0) {
        marginClass = 'mx-auto mt-' + mt;
    }

    let colorTxt = '';
    if (type == null) {
        for (var i = 0; i < multiple; i++) {
            if (Array.isArray(color)) {
                colorTxt = color[Math.floor(Math.random() * Math.floor(color.length))];
            } else {
                colorTxt = color;
            }

            wrapper += `
                <div class="spinner-grow tools-loadingWrapper spinner-grow-sm ` + marginClass + ` bg-` + colorTxt + `">
                </div>
            `;
        }
    }

    if (type == 'spinner') {
        if (Array.isArray(color)) {
            colorTxt = color[Math.floor(Math.random() * Math.floor(color.length))];
        } else {
            colorTxt = color;
        }
        wrapper = `<div class="col-12 my-3"><div class="row justify-content-center">
                        <div class="spinner-border text-` + colorTxt + ` ` + marginClass + `"></div>
                    </div></div>`;
    }

    return wrapper;
};

const updateBrowserHistory = function (newUrl) {
    window.history.pushState({
        "html": document.documentElement.innerHTML,
        "pageTitle": "Test",
        "refresh-ajax": true
    }, "titre", newUrl);
};

const loadContent = function (container, url, inModal, withBigLoading = false, bigLoadingMessage = null, autoRefreshTarget = { target: null, delay: null }) {
    if ($(container).hasClass('jx-loading-in-progress')) {
        console.log('loadContent() called on container with <b>jx-loading-in-progress</b> class, demand aborded');
        return;
    }
    $(container).addClass('jx-loading-in-progress');

    if (calledUrls.includes(url)) {
        console.log(url + ' is already in calledUrls process', 'danger');
        return;
    }

    if (withBigLoading === true) {
        plan.loader(bigLoadingMessage);
    } else {
        $(container).html(tools.loadingWrapper(['menu', 'yellow', 'red', 'gray', 'd-blue', 'peach'], 5, 0, 0, 'spinner'));
    }
    url = url.trim();
    $(container).load(url, function () {
        $(container).removeClass('jx-loading-in-progress');
        if (!$(container).is(':visible') && !$(container).hasClass('disable-auto-hide')) {
            $(container).show('slow');
        }

        if ($(container).children('.search').length > 0) {
            // console.log('autofocus')
            $(container).children('.search').trigger('focus');
        }

        tools.init();

        if (autoRefreshTarget.target != null) {
            console.log('autoRefreshTarget');
            let delay = autoRefreshTarget.delay == null ? 3000 : autoRefreshTarget.delay;

            setTimeout(function () {
                console.log('autoRefreshTarget sto');
                $('body .' + autoRefreshTarget.target).trigger('click');
            }, delay);

        }

    });
};

const updateEntity = function (url, isModal = false) {
    if (isModal == true) {
        tools.loadModal(url);
    }
}
;
const numberWithSpace = function (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

const addToProcess = function (id) {
    // console.log('tools::addToProcess() fired')
    // console.log('add to process : <b>' + id + '</b>')
    process.push(id);
    // console.log('process length : <b>' + process.length + '</b>')
};

const isInProcess = function (id) {
    return process.indexOf(id);
};

const getRandomColor = function (prefix = null) {
    let i = tools.getRandomInt(0, colors.length);

    return (prefix === null ? '' : prefix) + colors[i];
};

const autoloading = function () {
    tools.addToDebug('tools.js::autoloading() fired');
    const $loadings = $('body .autoloading');

    if ($loadings.length <= 0) {
        tools.addToDebug('No .autoloading found');

        return;
    }
    let loadingLength = 0;

    $loadings.each(function (i, elm) {
        const $loading = $(elm);

        if ($loading.hasClass('autoloading-initialized')) {
            return;
        }

        const elmId = $loading.attr('id')

        if (elmId === undefined) {
            console.log('tools::autoloading() the loading elm need an id', 'warning')

            return
        }

        $loading.addClass('autoloading-initialized')

        let url = $loading.data('url')
        const container = $loading.data('container')

        $('body #' + container).append(`
            <div class="col-12">
                <i class="fal fa-circle-notch fa-spin fa-2x ` + tools.getRandomColor('text-') + `"></i>
            </div>
        `)

        if (url === undefined) {
            tools.addToDebug('url for autoloading elm is undefined', 'warning')

            if (!$loading.hasClass('form-control')) {
                tools.addToDebug('autoloading container has no url and is not a form element, autoloading aborted', 'danger')

                return
            }

            let $selectedOption = $loading.find(':selected')

            if ($selectedOption.length <= 0) {
                return
            }

            url = $selectedOption.data('url')

            if (url === undefined) {
                tools.addToDebug('url for autoloading elm is undefined (on form elm)', 'warning')

                return
            }
        }

        if (container == undefined) {
            tools.addToDebug('container for autoloading elm is undefined', 'warning')

            return
        }

        loadingLength += 500

        setTimeout(function () {
            tools.loadContent($('body #' + container), url)
        }, loadingLength)
    })
}

const makeBigContainer = function (elmId) {
    const $elm = $('body #' + elmId)

    if ($elm.length <= 0) {
        console.log('bigLoadingContainer elm not found', 'danger')

        return
    }

    $elm.removeClass('mt-3')
    $elm.css('opacity', '0')
    $elm.css('position', 'fixed')
    $elm.css('top', '0px')
    $elm.css('left', '0px')
    $elm.css('margin', '0px')
    $elm.css('z-index', '99999')
    $elm.addClass('bg-white')
    $elm.css('display', 'inline-block')
    setTimeout(function () {
        $elm.animate({
            width: 100 + '%',
            height: 100 + '%',
            opacity: 1
        }, 500)
    })

    const $compress = `
        <div class="py-3 btn no-switcher btn-outline-danger my-3 switch-big-loading"
        data-target="` + elmId + `">
            <i class="fal fa-compress bl fa-2x"></i>
        </div>
    `

    $(document.body).append($compress)
}

const init = function () {
    // console.log('tools::init()')
    tools.autoloading()
    loader.fullCalendarLoader()
    loader.chartLoader()
    loader.planLoader()
    loader.bootstrapToggleLoader()
    loader.select2Loader()
    loader.datatableLoader()
    loader.counterLoader()
    loader.interventionSummaryLoader()
    loader.flashLoader()
    loader.inputRangeLoader()
    report.init()
    reportV2.init()
    actionExport.exportData()
    loader.inputPasswordLoader()
    if (!tacIsLaunched) {
        tacIsLaunched = true
        tools.launchTac()
    }

    $('body').off('click', '.export-data-excel')
    $('body').on('click', '.export-data-excel', function (e) {
        let $elm = $(this)

        if ($elm.hasClass('fired')) {
            return
        }

        $elm.addClass('fired')
        $elm.children('span').html('Export en cours...')

        e.preventDefault();
        $.post({
            url: Routing.generate('app_customer_reportV2_export_excel', {
                slug: tools.slug(),
                reportId: $elm.data('report'),
                contract: $elm.data('contract')
            }),
            xhrFields: {
                responseType: 'blob' // to avoid binary data being mangled on charset conversion
            },
            success: function (blob, status, xhr) {
                $elm.removeClass('fired')
                $elm.children('span').html('Export Excel')
                // check for a filename
                let filename = "";
                let disposition = xhr.getResponseHeader('Content-Disposition');
                if (disposition && disposition.indexOf('attachment') !== -1) {
                    let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    let matches = filenameRegex.exec(disposition);
                    if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
                }

                if (typeof window.navigator.msSaveBlob !== 'undefined') {
                    // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
                    window.navigator.msSaveBlob(blob, filename);
                } else {
                    let URL = window.URL || window.webkitURL;
                    // let downloadUrl = blob;
                    let downloadUrl = URL.createObjectURL(blob);

                    if (filename) {
                        // use HTML5 a[download] attribute to specify filename
                        let a = document.createElement("a");
                        // safari doesn't support this yet
                        if (typeof a.download === 'undefined') {
                            window.location.href = downloadUrl;
                        } else {
                            a.href = downloadUrl;
                            a.download = filename;
                            document.body.appendChild(a);
                            a.click();
                        }
                    } else {
                        window.location.href = downloadUrl;
                    }

                    setTimeout(function () {
                        URL.revokeObjectURL(downloadUrl);
                    }, 100); // cleanup
                }
            }
        })
    })

    let $footer = $('body footer.main-footer')

    if ($footer.length > 0) {
        let wh = window.innerHeight
        let $mainContainer = $('body .container-fluid')
        let mh = $mainContainer.height()

        if (mh > wh) {
            $footer.removeClass('fixed-bottom')
        }

    }

    let $generateCheckBoxForms = $('body .generated-checkbox-parent')

    $generateCheckBoxForms.each(function (i, elm) {
        let $elm = $(this)
        if ($elm.hasClass('initialized')) {
            return
        }
        $elm.addClass('initialized')
        let id = $elm.attr('id')
        let $checkboxes = $('body #' + id + ' input[type="checkbox"]')
        let $receipt = $('body .generated-checkbox-children-' + id)
        let checkboxIsDisabled = false

        $checkboxes.each(function (j, checkbox) {
            // console.log('-------------- start each checkbox n ' + j + ' -------------------')
            let $checkbox = $(checkbox)
            let checkboxId = $checkbox.attr('id')
            let $labelAssociated = $checkbox.parent().children('.form-check-label')
            // console.log('checkboxId', checkboxId)
            let isActive = $checkbox.is(':checked') ? 'active' : ''
            // console.log('isActive', isActive)
            let isDisabled = $checkboxes.length > 1 ? '' : 'disabled'
            let defaultBg = isActive != '' ? 'btn-aqua-green' : 'btn-outline-danger'
            checkboxIsDisabled = isDisabled != ''
            // console.log('isDisabled', isDisabled)
            defaultBg = isDisabled != '' ? 'btn-outline-secondary' : defaultBg
            // console.log('defaultBg', defaultBg)
            let tpc = `
            <div class="btn btn-sm font-size-11 my-1 ` + defaultBg + ` ` + isActive + ` ` + isDisabled + ` switch-checkbox"
            data-active-classname="btn-aqua-green"
            data-inactive-classname="btn-outline-danger"
            data-reference="generated-checkbox-children-` + id + `"
            data-target="` + checkboxId + `">
            ` + $labelAssociated.html() + `
            </div>
            `

            $receipt.append(tpc)
            // console.log('-------------- end each checkbox n ' + j + ' -------------------')
        })

        // After the each set the correct bg class, based on all checkbox, only if all checkbox are not disabled
        if (!checkboxIsDisabled) {
            // console.log('after eaching checkbox')
            let nbCheckboxActive = $('body .generated-checkbox-children-' + id + ' .switch-checkbox.active').length
            let $generatedCheckbox = $('body .generated-checkbox-children-' + id + ' .switch-checkbox')
            if (nbCheckboxActive == 0) {
                $generatedCheckbox.removeClass('btn-outline-danger').addClass('btn-aqua-green')
            } else {
                $('body .generated-checkbox-children-' + id + ' .switch-checkbox.active').removeClass('btn-outline-danger').addClass('btn-aqua-green')
                $('body .generated-checkbox-children-' + id + ' .switch-checkbox:not(.active)').removeClass('btn-aqua-green').addClass('btn-outline-danger')
            }
        }
    })


    // Init sortable container
    // $('body .sortable-container').each(function (i, elm) {
    //     if ($(elm).hasClass('initialized')) {
    //         return
    //     }
    //     // $(elm).addClass('initialized')
    //     const elmId = $(elm).attr('id')
    //     if (elmId == undefined) {
    //         // console.log('A sortable elm missing an id : ' + elm)

    //         return
    //     }

    //     const $elm = $('#' + elmId)
    //     const sortableUrl = $elm.data('sortable-url')

    //     if (sortableUrl == undefined) {
    //         // console.log('A sortable elm dont have an data url : ' + elmId)

    //         return
    //     }

    //     // console.log('Init sortable container n°' + i + ' with the id #' + $(elm).attr('id'))
    //     $('#' + $(elm).attr('id')).sortable({
    //         revert: false,
    //         items: ".draggable-elm",
    //         cancel: ".droppable-zone, .not-sortable",
    //         // containment: window,
    //         // grid: [10, 10],
    //         stop: function (event, ui) {
    //             const $sortableElm = $(event.target)
    //             const $list = $sortableElm.children('.draggable-elm')

    //             let finalOrder = []
    //             let pos = 1
    //             // console.log('sortable event:stop is fired')
    //             // console.log('sortable elm : ', $sortableElm)
    //             // console.log('sortable elm childrens : ', $list)
    //             // console.log('start foreach list of sortable elms')
    //             $list.each(function (i, elm) {
    //                 finalOrder.push({position: pos, id: $(elm).data('id')})
    //                 // console.log($(elm))
    //                 // console.log('push elm')
    //                 pos++
    //             })
    //             // console.log(finalOrder)
    //             // console.log('ready to post the new sortable list')
    //             const url = $sortableElm.data('sortable-url')
    //             if (url == undefined) {
    //                 // console.log('the sortable container dont have a data url')
    //                 return
    //             }
    //             // console.log('the data-url was found on sortable container : ' + url)
    //             $.post({
    //                 url: url,
    //                 data: 'order=' + JSON.stringify(finalOrder),
    //                 success: function (response) {
    //                     // console.log(response)
    //                 }
    //             })
    //         }
    //     })
    //     $("#" + $(elm).attr('id')).disableSelection()
    // })

    $('body .sortable').each(function (i, elm) {
        // console.log('each sortable elm ' + i)
        let $sortableContainer = $(elm)

        if ($sortableContainer.hasClass('sortable-initialized')) {
            // console.log('sortable ' + i + ' already initialized')
            return
        }

        let sortableContainerId = $sortableContainer.attr('id')
        if (sortableContainerId == undefined || sortableContainerId.length <= 0) {
            sortableContainerId = 'sortable-container-' + tools.getRandomInt()
            $sortableContainer.attr('id', sortableContainerId)
        }

        let targets = $sortableContainer.data('targets')

        if (targets == undefined || targets.length <= 0) {
            console.error('sortable container #' + sortableContainerId + ' need data-targets attr')
            return
        }

        // console.log(sortableContainerId)

        $sortableContainer = $('body #' + sortableContainerId)

        if ($sortableContainer.length <= 0) {
            console.error('sortable container #' + sortableContainerId + ' was not found after re initialisation')
            return
        }

        $sortableContainer.sortable({
            items: $('body .' + targets),
            start: function (e, u) {
                let $sortableContainer = $(e.target)
                let $item = $(u.item)
                let $items = $sortableContainer.children('.' + $sortableContainer.data('targets'))
                $items.addClass('border border-d-blue')
                $item.addClass('border border-red').removeClass('border-d-blue')
                // $item.css('border', '1px solid red')
                // console.log($item)
            },
            stop: function (e, u) {
                let $sortableContainer = $(e.target)
                let $item = $(u.item)
                let $items = $sortableContainer.children('.' + $sortableContainer.data('targets'))
                $items.removeClass('border border-d-blue')
                $item.removeClass('border border-red')
                // $item.css('border', '1px solid red')
                // console.log($item)
            }
        })

        $sortableContainer.addClass('sortable-initialized')
        $("body #" + sortableContainerId + " ." + targets).disableSelection()
        // console.log('sortable #' + sortableContainerId + ' is initialized with targets .' + targets)
    })

    // Init draggable elm
    $('body .draggable-elm').each(function (i, elm) {
        if ($(elm).hasClass('draggable-initialized')) {
            return
        }
        $(elm).addClass('draggable-initialized')
        // console.log('draggable elm init ' + i + ' with id : ' + $(elm).attr('id'))
        const elmId = $(elm).attr('id')

        if (elmId == undefined) {
            // console.log('The draggable elm missing an id ' + elm)
            return
        }

        const $elm = $('#' + elmId)

        let isConnectedToSortableContainer = $elm.data('connect-sortable-container')
        if (isConnectedToSortableContainer == undefined) {
            isConnectedToSortableContainer = 'page-container'
        }
        let revert = ($elm.data('revert') == undefined || $elm.data('revert') == false) ? false : true

        $elm.draggable({
            connectToSortable: $('#' + isConnectedToSortableContainer),
            revert: revert,
            drag: function (e) {
                // console.log('drag elm in progress')
            }
        })

    })

    // Init droppable zone
    $('body .droppable-zone').each(function (i, elm) {
        if ($(elm).hasClass('initialized')) {
            return
        }
        $(elm).addClass('initialized')
        const elmId = $(elm).attr('id')
        const $elm = $('#' + elmId)
        $elm.droppable({
            drop: function (event, ui) {
                const elm = ui.draggable
                const $droppableZone = $(this)
                const droppableEvent = $droppableZone.data('event')

                switch (droppableEvent) {
                    case 'remove':
                        // console.log('delete action')
                        var url = elm.data(droppableEvent + '-url')
                        if (url == undefined) {
                            // console.log('url is not defined for droppable:drop:delete event')

                            return
                        }
                        tools.deleteEntity(url, elm)
                        break;
                    case 'update':
                        // console.log('update action')
                        var url = elm.data(droppableEvent + '-url')
                        if (url == undefined) {
                            // console.log('url is not defined for droppable:drop:update event')

                            return
                        }
                        tools.updateEntity(url, true)
                        elm.css({ top: 0, left: 0 })
                        break;
                    default:
                        // console.log('Droppable event drop event : droppableEvent is not defined : ' + droppableEvent)
                        break;
                }

                // console.log('end droppable:drop event')
            }
        })
    })

    // TinyMCE
    $('body .tinymce-textarea').each(function (i, elm) {
        if ($(elm).hasClass('initialized')) {
            return
        }
        let tinymcePlugins = "emoticons hr image link lists charmap table fullscreen code"
        let tinymceToolbar = "formatgroup paragraphgroup insertgroup code fullscreen"
        let tinymceToolbarGroups = {
            formatgroup: {
                icon: 'format',
                tooltip: 'Formatting',
                items: 'bold italic underline strikethrough | forecolor backcolor | superscript subscript | removeformat'
            },
            paragraphgroup: {
                icon: 'paragraph',
                tooltip: 'Paragraph format',
                items: 'h1 h2 h3 | bullist numlist | alignleft aligncenter alignright | indent outdent'
            },
            insertgroup: {
                icon: 'plus',
                tooltip: 'Insert',
                items: 'link image emoticons charmap hr'
            }
        }
        let $textarea = $(elm)
        $textarea.addClass('initialized')
        if ($textarea.hasClass('without-plugins')) {
            tinymcePlugins = ""
        }
        if ($textarea.hasClass('without-toolbar')) {
            tinymceToolbar = ""
        }
        if ($textarea.hasClass('without-toolbar-groups')) {
            tinymceToolbarGroups = {}
        }

        const id = $(elm).attr('id')
        const $form = $(id).closest('form')
        let nb = $form.data('currentnb') == undefined ? 0 : $form.data('currentnb')
        // console.log('tinymce id : ' + id)
        tinymce.remove('#' + id)
        tinymce.init({
            body_id: id + '-gtinymce',
            selector: '#' + id,
            plugins: tinymcePlugins,
            toolbar: tinymceToolbar,
            content_css: "/assets/css/global.css",
            toolbar_groups: tinymceToolbarGroups,
            toolbar_location: 'bottom',
            menubar: false,
            init_instance_callback: function (editor) {
                // console.log('tinymce instance initied : ' + editor.id)
                editor.on('keyup', function (e) {
                    tinymce.triggerSave()
                    // const $form = $('#' + id).closest('form')
                    // let nb = $form.data('currentnb') == undefined ? 0 : $form.data('currentnb')
                    // tools.verifyTransparentForm(nb, $('#' + id))
                })
                let content = tinymce.get(id).getContent()
                if (content.length <= 0) {
                    tinymce.get(id).insertContent('<p></p>')
                }
            },
        })
    })
    tools.loadChartsByRules('rule-search')
    tools.loadInterByContract('search-one')
    tools.loadRulesByInters('rule-search-inter')
    tools.datatableListener()
    tools.selectInters('search')
    tools.reportSearchInter('report-search-one')
    tools.checkAll('checkAll')
    // tools.checkPieges()
    // tools.checkNusibles()
    tools.cookies()
    tools.checkBoxes()
    tools.activePhotos()
    tools.activeSeuil()
    tools.loadCookie()
    tools.cookiePieges()
    tools.cookieNuisibles()
    tools.consoDefault()
    tools.filterDate()
    tools.btnLoadInterPlan()

    // tools.verifyCookie()
    // tools.deleteCookie()

}

const datatableListener = function (table) {
    let $table = $(table)

    if ($table.data('cookie') === undefined || $table.data('cookie') === '') {
        // console.warn('Table required data-cookie attr')

        return
    }

    let checkboxClassname = $table.data('checkboxes')

    let cookie = tools.getCookie($table.data('cookie'))
    let interventionsIds = []
    tools.cookies()

    // pas de données check
    if (/_/.test(cookie) && cookie !== false && cookie !== undefined) {
        interventionsIds = cookie.split('_')
    }
    $('body .' + checkboxClassname).each(function (elm, i) {
        let $elm = $(this)

        if (interventionsIds.length === 0 ) {
            $elm.prop('checked', false)
        } else {
            $elm.prop('checked', true)
        }
    })
}

// btn tout cocher rapport contrat
const checkAll = function () {

    let $checkAll = $('body .checkAll')

    let $checkbox = $('.check-report')

    $checkbox.each(function() {
        let $elm = $(this);
        let id = $elm.data('id');
        let cookieId = $elm.data('cookie')
        let table = $elm.data('table');

        let cookies = tools.getCookie(cookieId + '-' + table )
        if (cookies === false) {
            $('.check-report').prop('checked', true)
        }
    })


    $checkAll.each(function (i, elm) {
        const $c = $(elm)
        let table = $c.data('table')
        let cookieId = $c.data('cookie');

        $c.prop('checked', true)
        let cookies = tools.getCookie(cookieId + '-' + table )
        if (cookies === false) {
            $('.check-report').prop('checked', true)
        }

        $c.on('click', function(e) {
            const $elm = $(this)
            table = $elm.data('table');
            tools.setCookie(cookieId + '-' + table)
            if (!$elm.is(':checked')) {
                //tout décocher
                $('.check-box-' + table).prop('checked', false)
                $('.check-sansZoneV2').prop('checked', false)

            } else {
                // tout décocher
                $('.check-box-' + table).prop('checked', true)
                $('.check-sansZoneV2').prop('checked', true)
            }

            tools.cookies()
            tools.activePhotos()
            tools.activeSeuil()
        })
    })
}


// activer ou non l'onglet photo (rapport contrat )
const activePhotos = function() {
    let $navPhoto = $('#nav-container-photos');
    let $cookieId = $navPhoto.data('cookie');

    let cookies = tools.getCookie($cookieId + '-interventions')

    if (cookies !== false) {
        cookies = cookies.split('_');

        for (var i = 0; i < cookies.length; i++) {
            if (cookies[0] == "undefined") {
                cookies.shift()
            }

            if (cookies.length > 1 ) {
                $('#nav-container-photos').addClass("disabled")
            } else {
                $('#nav-container-photos').removeClass("disabled")
            }
        }
    }
}

// activer ou non l'onglet seuil (rapport contrat)
const activeSeuil = function() {
    let $navSeuil = $('#nav-container-seuils');
    let cookieId = $navSeuil.data('cookie');

    let cookieIntervention = tools.getCookie(cookieId + '-interventions');

    if (cookieIntervention !== false) {
        cookieIntervention = cookieIntervention.split('_');

        for (var i = 0; i < cookieIntervention.length; i++) {
            if (cookieIntervention[0] == "undefined") {
                cookieIntervention.shift()
            }

            if (cookieIntervention.length > 1 ) {
                $navSeuil.addClass("disabled")
            } else {
                $navSeuil.removeClass("disabled")

            }

        }
    }
}
// check box rapport contrat
const checkBoxes = function() {
    let $checkbox = $('.check-report')

    $checkbox.each(function() {
        let $elm = $(this);
        $elm.on('click', function() {
            let $c = $(this);
            let id = $c.data('id');
            let cookieId = $c.data('cookie')
            let table = $c.data('table');

            let alreadyChecked = []
            let cookies = tools.getCookie(cookieId + '-' + table)
            if(cookies !== false) {
                alreadyChecked = cookies.split('_');
            }

            if (alreadyChecked.includes(String(id)) && !$c.is(':checked')) {
                alreadyChecked = alreadyChecked.filter(f => { return f !== String(id)});
            }else if ($c.is(':checked')) {
                alreadyChecked.push(id)
            }
            alreadyChecked = alreadyChecked.join('_')
            if (alreadyChecked === '') {
                alreadyChecked = 'undefined'
            }

            tools.setCookie(cookieId + '-' + table, alreadyChecked)
            tools.activePhotos()
            tools.activeSeuil()
        })
    })
}

// cookie rapport contrat
const cookies = function() {
    let $tables = $('.report-table')

    $tables.each(function() {
        const $elm = $(this)
        let elmId = $elm.attr('id')

        let $boxes = $('.check-report');
        $boxes.each(function() {
            const $b = $(this);
            let table = $b.data('table');

            if($b.length <= 0) {
                return
            }

            let cookieId = $b.data('cookie');
            let name = cookieId + '-' + table;

            let values = [];
            let alreadyChecked = [];

            let cookies = '';

            cookies = tools.getCookie(cookieId +'-'+ table);

            //if checkAll is checked
            if (cookies === 'undefined') {
                $b.prop('checked',false);
                return
            }

            if(cookies !== false) {
                alreadyChecked = cookies.split('_');
                values = alreadyChecked;
            }

            if($b.is(':checked') == true || alreadyChecked.includes(String($b.data('id')))) {
                $b.prop('checked',true);

                if (!values.includes(String($b.data('id')))) {
                    values.push($b.data('id'));
                }
            }
            values = values.join('_');
            if (values === '') {
                values = 'undefined';
            }

            tools.setCookie(name, values)
        })
    })
}

const cookiePieges = function() {
    let $checkTps = $('.check-box-tps');
    let id = $checkTps.data('id');
    let contract = $checkTps.data('contract')
    let cookieId  = $checkTps.data('cookie')
    let $checkPiege = $('.check-box-' + id)

    let values = []
    let alreadyChecked = []

    let cookies = tools.getCookie(cookieId + '-pieges')
    if (cookies !== false) {
        alreadyChecked = cookies.split('_')
        values = alreadyChecked
    }

    $checkPiege.each(function(opt, i) {
        let $elm = $(this)
        if ($elm.is(':checked') == true || alreadyChecked.includes(String($elm.data('id')))) {
            $elm.prop('checked', true)

            if (!values.includes(String($elm.data('id')))) {
                values.push($elm.data('id'))
            }
        }
    })

    values = values.join('_')

    if (values === '') {
        values = 'undefined'
    }
    tools.setCookie(cookieId + '-pieges', values)
}

const cookieNuisibles = function() {
    let $checkRias = $('.check-box-rias');
    let id = $checkRias.data('id');
    let contract = $checkRias.data('contract')
    let cookieId  = $checkRias.data('cookie')
    let $checkNuisibles = $('.check-box-' + id)

    let values = []
    let alreadyChecked = []

    let cookies = tools.getCookie(cookieId + '-nuisibles')
    if (cookies !== false) {
        alreadyChecked = cookies.split('_')
        values = alreadyChecked
    }

    $checkNuisibles.each(function(opt, i) {
        let $elm = $(this)
        if ($elm.is(':checked') == true || alreadyChecked.includes(String($elm.data('id')))) {
            $elm.prop('checked', true)

            if (!values.includes(String($elm.data('id')))) {
                values.push($elm.data('id'))
            }
        }
    })

    values = values.join('_')

    if (values === '') {
        values = 'undefined'
    }
    tools.setCookie(cookieId + '-nuisibles', values)
}


const loadCookie = function() {
    let $checks = $('.check-box');
    let contract = $checks.data('contract');
    let table = $checks.data('table')
    let cookies= tools.getCookie(contract + '-' + table)

    let ids = []

    if (false != cookies) {
        ids = cookies.split('_');

        for (var i = 0; i < ids.length; i++) {
            $('body #check-box-' + ids[i]).prop('checked', 'checked')
        }

        let values = $checks.data('id')

    }
}


const deleteCookie = function() {
    let btn = $('.delete-cookie')
    let cookieName = null

    let cookieId = btn.data('cookie')

    let $tables = ['interventions', 'plans', 'zones', 'zonesV2', 'rias', 'nuisibles', 'tps', 'pieges', 'seuils', 'photos', 'photosR', 'actionAMener' ];

    $tables.forEach(function(table) {
        let cookieName = cookieId + '-' + table
        console.log('cookie to delete => ' + cookieName)

        if(cookieName) {
            tools.eraseCookie(cookieName)
        }
    })

}

const filterDate = function() {
    let btn = $('.filter-date')
    let dataCookie = btn.data('cookie')

    btn.on('click', function() {
        if (btn.hasClass('in-progress')) {
            return
        }
        btn.addClass('in-progress')

        tools.deleteCookie()
    })
}

const consoDefault = function() {
    let $btn = $('#check-conso');
    let $checkPiege = $('.check-report');

    $checkPiege.each(function() {
        const $elm =  $(this);
        let isDefault = $elm.data('isdefault');
        let cookieId = $elm.data('cookie')

        $btn.on('click', function(i, elm) {
            let btn = $(elm);

            if(isDefault == true) {
                $elm.prop('checked', false);
            }
        })
        tools.cookies();
    })
}

const loadRulesByInters = function (id) {
    let $select = $('body #' + id)
    if ($select.hasClass('already-initialized')) {
        return
    }
    $select.addClass('already-initialized')
    if ($select === undefined) {
        return;
    }
    let $option = $select.find(':selected')
    if ($option === undefined) {
        return;
    }
    let rulesId = $option.val()

    if (rulesId === undefined) {
        return;
    }
    let intersId = $select.data('intervention')
    let url = Routing.generate('app_customer_chart_rules_intervention', {
        slug: tools.slug(),
        rulesId: $option.val(),
        intervention: intersId
    })
    let containerId = $select.data('target')
    if (containerId === undefined) {
        return;
    }

    $select.on('change', function () {
        let $option = $select.find(':selected')
        if ($option === undefined) {
            return;
        }
        let rulesId = $option.val()
        console.log(rulesId)

        if (rulesId === undefined) {
            return;
        }
        let intersId = $select.data('intervention')
        let url = Routing.generate('app_customer_chart_rules_intervention', {
            slug: tools.slug(),
            rulesId: $option.val(),
            intervention: intersId
        })
        console.log(url)
        let containerId = $select.data('target')
        if (containerId === undefined) {
            return;
        }
        tools.loadContent($('body #' + containerId), url)
    })


    tools.loadContent($('body #' + containerId), url)

}

const loadChartsByRules = function (id) {
    let $select = $('body #' + id)
    if ($select.hasClass('already-initialized')) {
        return
    }
    $select.addClass('already-initialized')
    if ($select === undefined) {
        return;
    }
    let $option = $select.find(':selected')
    if ($option === undefined) {
        return;
    }
    let rulesId = $option.val()

    if (rulesId === undefined) {
        return;
    }
    let contract = $select.data('contract')
    let url = Routing.generate('app_customer_chart_generate_rules', {
        slug: tools.slug(),
        rulesId: $option.val(),
        contract: contract
    })
    console.log(url)
    let containerId = $select.data('target')
    if (containerId === undefined) {
        return;
    }

    $select.on('change', function () {
        let $option = $select.find(':selected')
        if ($option === undefined) {
            return;
        }
        let rulesId = $option.val()
        console.log(rulesId)

        if (rulesId === undefined) {
            return;
        }
        let contract = $select.data('contract')
        let url = Routing.generate('app_customer_chart_generate_rules', {
            slug: tools.slug(),
            rulesId: $option.val(),
            contract: contract
        })
        console.log(url)
        let containerId = $select.data('target')
        if (containerId === undefined) {
            return;
        }
        tools.loadContent($('body #' + containerId), url)
    })


    tools.loadContent($('body #' + containerId), url)
}

const selectInters = function (id) {
   let $select = $('body #' + id)

    if($select.hasClass('already-initialized')) {
        return
    }
    $select.addClass('already-initialized')

    if($select === undefined) {
        return;
    }

    $select.on('change', function() {
        let $option = $select.find(':selected')

        if($option === undefined) {
            return
        }

        let interventionId = $option.val()
        if(interventionId === undefined) {
            return
        }

        let plan = $select.data('plan')
        let url = Routing.generate('app_customer_plan_plan_interactif',{
            slug: tools.slug(),
            plan: plan,
            intervention: interventionId
        })

        let containerId = $select.data('target')
        if(containerId === undefined) {
            return
        }

        console.log(containerId)
        tools.loadContent($('body #' + containerId), url)

    })

}

const btnLoadInterPlan = function () {

    
    let $btns = $('body .load-intervention')
    let $btnsYears = $('body .btn-year')
    let $btnsMonth = $('body .btn-months')
    
    
    $btnsYears.on('click', function() {
        if( $btnsYears.hasClass('bg-yellow')) {
            $btnsYears.removeClass('bg-yellow').addClass('btn-outline-secondary')
        }
        if($btnsYears.hasClass('active')) {
            $btnsYears.removeClass('active')
        }
    })

    $btnsMonth.on('click', function() {
        if( $btnsMonth.hasClass('bg-yellow')) {
            $btnsMonth.removeClass('bg-yellow').addClass('btn-outline-secondary')
        }
        if($btnsMonth.hasClass('active')) {
            $btnsMonth.removeClass('active')
        }
    })



    $btnsYears.each(function() {
        const btnYear = $(this)
        let id = btnYear.data('id')
        let i = $('body .inter-' + id)
        let year = btnYear.data('years')


        if(id == i.data('years')) {
            btnYear.html('Année ' + year + '  ('+ i.length + ')')
        }

        btnYear.on('click', function() {
            $('body .months-container').hide()
            $('body .interventions-container').hide()
            btnYear.removeClass('btn-outline-secondary').addClass('bg-yellow')
            $btnsMonth.removeClass('bg-yellow').addClass('btn-outline-secondary')
        })
    })

    $btnsMonth.each(function() {
        const btnMonth = $(this)
        let id = btnMonth.data('id')
        let name = btnMonth.data('name')
        let month = btnMonth.data('month')
        let btnInter = $('body .btn-intervention-' + id )
        let i = btnInter.length; 
        // i = i / 2
        
        if( i == 0 ) {
            btnMonth.hide()
        }
        
        // console.log(i)
        if(month == btnInter.data('month')) {
            btnMonth.html(name + '  ('+ i + ')')
        }


        btnMonth.on('click', function() {
            $('body .interventions-container').hide()
            btnMonth.removeClass('btn-outline-secondary').addClass('bg-yellow')

        })
    })

    $btns.on('click', function() {
        if($btns.hasClass('bg-yellow')) {
            $btns.addClass('btn-d-blue')
        }
    })


    $btns.each(function() {
        const $btn = $(this)

        if ($btn.hasClass('initialized')) {
            return
        }

        $btn.addClass('initialized')

        let intervention = $btn.data('intervention')
        let plan = $btn.data('plan')
        let origine = $btn.data('origine')
        let releveTable = $btn.data('releve')

        
        let containerId = $btn.data('target')
        let url = Routing.generate('app_customer_plan_plan_interactif', {
            slug: tools.slug(),
            plan: plan,
            intervention: intervention,
            isOrigin: origine,
            withRelevesReport: releveTable
        })
        
        
        $btn.on('click', function() {
            if($btns.hasClass('bg-yellow')) {
                $btns.addClass('btn-d-blue')
            }
            $btn.addClass('bg-yellow').removeClass('btn-d-blue').addClass('border-0')
            tools.loadContent($('body #' + containerId), url)
        })

        let btnsAppatages = $('body #plan-appatage-' + intervention )

        btnsAppatages.each(function() {
            const btnAppatage = $(this)

            if (btnAppatage.hasClass('initialized')) {
                return
            }
    
            btnAppatage.addClass('initialized')
    
            let intervention = btnAppatage.data('intervention')
            let plan = btnAppatage.data('plan')
            let origine = btnAppatage.data('origine')
            let releveTable = btnAppatage.data('releve')

            let containerId = btnAppatage.data('target')
            let urlAppatage = Routing.generate('app_customer_plan_plan_interactif', {
                slug: tools.slug(),
                plan: plan,
                intervention: intervention,
                isOrigin: origine,
                withRelevesReport: releveTable
            })

            btnAppatage.on('click', function() {
                $btns.removeClass('bg-yellow').addClass('btn-d-blue')
                $btn.removeClass('btn-d-blue').addClass('bg-yellow')
                tools.loadContent($('body #' + containerId), urlAppatage)
            })


        })

        // let btnsAjour = $('body #plan-a-jour-' + intervention )

        // btnsAjour.each(function() {
        //     const btnAjour = $(this)

        //     if (btnAjour.hasClass('initialized')) {
        //         return
        //     }
    
        //     btnAjour.addClass('initialized')
    
        //     let intervention = btnAjour.data('intervention')
        //     let plan = btnAjour.data('plan')
        //     let origine = btnAjour.data('origine')
        //     let releveTable = btnAjour.data('releve')

        //     let containerId = btnAjour.data('target')
        //     let urlAjour = Routing.generate('app_customer_plan_plan_interactif', {
        //         slug: tools.slug(),
        //         plan: plan,
        //         intervention: intervention,
        //         isOrigin: origine,
        //         withRelevesReport: releveTable
        //     })

        //     btnAjour.on('click', function() {
        //         // btnAjour.addClass('bg-yellow').removeClass('btn-d-blue').addClass('border-0')
        //         tools.loadContent($('body #' + containerId), urlAjour)
        //     })


        // })
    
    })

}

const loadInterByContract = function (id) {
    let $select = $('body #' + id)
    if ($select.hasClass('already-initialized')) {
        return
    }
    $select.addClass('already-initialized')
    if ($select === undefined) {
        return
    }
    let $option = $select.find(':selected')
    if ($option === undefined) {
        return
    }
    let contract = $select.data('contract')
    let url = Routing.generate('app_customer_reportContrat_report_contrat_intervention', {
        slug: tools.slug(),
        contract: contract
    })
    // console.log('url1' + url)
    $select.on('change', function () {
        let $option = $select.find(':selected')
        if ($option === undefined) {
            return
        }
        let intersId = $option.val()
        // console.log(intersId)

        if (intersId === undefined) {
            return
        }
        let contract = $select.data('contract')
        let url = Routing.generate('app_customer_reportContrat_report_contrat_intervention', {
            slug: tools.slug(),
            intersId: $option.val(),
            contract: contract
        })
        console.log(url)
        let containerId = $select.data('target')
        if (containerId === undefined) {
            return
        }
        tools.loadContent($('body #' + containerId), url)
    })
}

const reportSearchInter = function (id) {

    let $select = $('body #' + id)
    if ($select.hasClass('already-initialized')) {
        return
    }
    $select.addClass('already-initialized')
    if ($select === undefined) {
        return
    }
    let $option = $select.find(':selected')
    if ($option === undefined) {
        return
    }

    let url = Routing.generate('app_customer_report_global_intervention_form', {
        slug: tools.slug()
    })

    // console.log('url1' + url)
    $select.on('change', function () {
        let $option = $select.find(':selected')
        if ($option === undefined) {
            return
        }
        let interId = $option.val()
        // console.log(interId)

        if (interId === undefined) {
            return
        }
        let url = Routing.generate('app_customer_report_global_intervention_form', {
            intervention : interId,
            slug: tools.slug(),
        })
        //console.log(url)
        let containerId = $select.data('target')
        if (containerId === undefined) {
            return
        }
        // console.log(containerId);
        tools.loadContent($('body #' + containerId), url)
    })
}

const navbarSearch = function (elm) {

    const $elm = $(elm)
    let search = $elm.val()
    let minSearch = $elm.data('min')
    let resultContainer = $elm.data('container')

    if (search == undefined) {
        return
    }

    if (minSearch != undefined && search.length < minSearch) {
        return
    }

    const searchUrl = Routing.generate('app_customer_index_search', {
        slug: tools.slug(),
        search: search
    })

    tools.loadContent($('#' + resultContainer), searchUrl)
}

const sidebarShowText = function () {
    var textWrapper = document.querySelector('.ml11 .letters');
    textWrapper.innerHTML = textWrapper.textContent.replace(/([^\x00-\x80]|\w)/g, "<span class='letter'>$&</span>");

    anime.timeline({ loop: false })
        .add({
            targets: '.ml11 .line',
            scaleY: [0, 1],
            opacity: [0.5, 1],
            easing: "easeOutExpo",
            duration: 700
        })
        .add({
            targets: '.ml11 .line',
            translateX: [0, document.querySelector('.ml11 .letters').getBoundingClientRect().width + 10],
            easing: "easeOutExpo",
            duration: 700,
            delay: 100
        }).add({
            targets: '.ml11 .letters',
            opacity: [0, 1],
            easing: "easeOutExpo",
            duration: 600,
            offset: '-=775',
            delay: (el, i) => 34 * (i + 1)
        }).add({
            targets: '.ml11 .line',
            opacity: 0,
            translateX: [document.querySelector('.ml11 .letters').getBoundingClientRect().width + 10, 0],
            duration: 500,
            easing: "easeOutExpo",
            delay: 50
        })
}

const filterPeriod = function (start, end, containerId, url) {
    tools.addToDebug('filterPeriod() is called')

    tools.addToDebug('start : ' + start)
    tools.addToDebug('end : ' + end)

    const $container = $('body #' + containerId)
    let searchValue = null

    // look if a search input exist
    if ($('body #search').length > 0) {
        tools.addToDebug('.search elm detected')
        const $searchElm = $('body #search')
        searchValue = $searchElm.val()
        if (searchValue.length <= 0 || searchValue == undefined) {
            searchValue = null
        }
        tools.addToDebug('search elm value is ' + searchValue)
    }

    const route = Routing.generate(url, {
        slug: tools.slug(),
        start: start,
        end: end,
        search: searchValue
    })

    tools.loadContent($container, route)
}

const slug = function () {
    const $body = $('body')

    return $body.data('slug')
}

const generateRgba = function (alpha = 1) {
    if (1 < alpha) {
        alpha = 1;
    }

    if (0 > alpha) {
        alpha = 0;
    }

    let r = Math.floor(Math.random() * Math.floor(255));
    let g = Math.floor(Math.random() * Math.floor(255));
    let b = Math.floor(Math.random() * Math.floor(255));

    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
}

const confirm = function (options, onValidation, onCancel) {
    var defaults = {
        title: 'Veuillez confirmer votre action',
        closeIcon: true,
        content: '',
        type: 'red',
        buttons: {
            Annuler: {
                btnClass: 'btn btn-danger',
                action: onCancel
            },
            Confirmer: {
                btnClass: 'btn btn-aqua-green',
                action: onValidation
            }
        }
    }


    var settings = $.extend({}, defaults, options)
    $.confirm(settings)
}

const deleteEntity = function (url, elm) {
    const targetId = $(elm).attr('id')

    if (targetId == undefined) {
        return
    }

    $.get({
        url: url,
        success: function (response) {
            if (response.state == false) {
                return
            }
            $('#' + targetId).hide('slow', function () {
                let $parent = $('#' + targetId).parent()
                const parentId = $parent.attr('id')
                if ($('body .draggable-elm').length <= 1) {
                    if ($('#' + parentId + ' .fade-if-elm').length > 0) {
                        if ($('#' + parentId + ' .fade-if-elm').is(':visible')) {
                            $('#' + parentId + ' .fade-if-elm').slideUp('slow')
                        }
                    }
                    const content = `
                    <div class="alert alert-d-blue border-radius-0 col-12">
                    <i class=" fal fa-clock"></i>
                    Il semblerait qu'il n'y ait plus d'élément par ici pour le moment
                    </div>
                    `
                    $parent = $('body #' + parentId)
                    $(this).parent().append(content)
                }
                $(this).remove()
            })
        }
    })
}

const playOnMenu = function (elm) {
    let $elm = document.querySelector('#' + $(elm).attr('id'))

    if ($(elm).hasClass('deployed')) {
        return
    }

    if ($('#sub-' + $(elm).attr('id')).length <= 0) {
        return
    }

    const mAnimation = anime.timeline({
        targets: $elm,
        easing: 'easeInOutSine',
        duration: 500,
        delay: 10,
        autoplay: false,
        loopComplete: function (a) {
            $($elm).addClass('deployed')
            const div = document.getElementById($($elm).attr('id'))
            if ($(div).is(':hover') == false) {
                tools.playOffMenu($elm)
                return
            }
        }
    }).add({
        targets: document.querySelector('#sub-' + $($elm).attr('id')),
        easing: 'easeInOutSine',
        delay: 10,
        height: 125,
        loopComplete: (a) => {
            $($elm).addClass('deployed')
            const div = document.getElementById($($elm).attr('id'))
            if ($(div).is(':hover') == false) {
                tools.playOffMenu($elm)
                return
            }
        }
    }).add({
        targets: document.querySelectorAll('#sub-' + $($elm).attr('id') + ' .item-sub'),
        height: 25,
        easing: 'cubicBezier(.5, .05, .1, .3)',
        loopComplete: (a) => {
            $($elm).addClass('deployed')
            const div = document.getElementById($($elm).attr('id'))
            if ($(div).is(':hover') == false) {
                tools.playOffMenu($elm)
                return
            }
        }
    })
    mAnimation.play()
}

const playOffMenu = function (elm) {
    let $elm = document.querySelector('#' + $(elm).attr('id'))

    if ($(elm).hasClass('deployed')) {
        $($elm).removeClass('deployed')
        const eAnimation = anime.timeline({
            targets: $elm,
            easing: 'easeInOutSine',
            autoplay: false,
            duration: 500,
            loopComplete: function (a) {
                $($elm).removeClass('deployed')
            }
        }).add({
            targets: document.querySelector('#sub-' + $($elm).attr('id')),
            easing: 'linear',
            height: 0,
            direction: 'alternate',
            loopComplete: (a) => {
            }
        }).add({
            targets: document.querySelectorAll('#sub-' + $($elm).attr('id') + ' .item-sub'),
            height: 0,
            // easing: 'easeInOutSine',
            easing: 'cubicBezier(.5, .05, .1, .3)',
            loopComplete: (a) => {
            }
        })

        eAnimation.play()
    }
}

/**
 * @inheritdoc Append a modal with jquerymodal plugin after called (get) url
 * @param {string} url
 */
const loadModal = function (url, fadeDuration = 500, timeoutForInit = 650) {
    $.get({
        url: url,
        success: function (html) {
            $(html).appendTo('body').modal({
                fadeDuration: fadeDuration
            })
            setTimeout(function () {
                let $searchInput = $('body .search')
                if ($searchInput.length > 0) {
                    let searchInputId = $searchInput.attr('id')

                    if (searchInputId == undefined) {
                        return
                    }

                    $('body #' + searchInputId).trigger('focus')
                }
                tools.init()
            }, timeoutForInit)
        }
    })
}

const sleep = function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
}

const getRandomInt = function (min = null, max = null) {
    min = (min != null) ? Math.ceil(min) : Date.now()
    max = (max != null) ? Math.floor(max) : (Date.now() * 2)

    return Math.floor(Math.random() * (max - min + 1)) + min
}

const appendModal = function (title, content, modalClassNames = '') {
    const ts = moment().unix()
    const modalId = Math.floor(Math.random() * Math.floor(ts))

    const modalWrapper = `
    <div class="modal container ` + modalClassNames + `" id="modal-` + modalId + `">
        <h1 class="col-12 py-2 font-size-18">
            ` + title + `
        </h1>
        <div class="row align-center">
            <div class="col-12 text-center">
                ` + content + `
            </div>
        </div>
    </div>
    `

    $(modalWrapper).appendTo('body').modal({
        fadeDuration: 500
    })
}

const verifyTransparentForm = function (nb, input) {
    // console.log('verifyTransparentForm() nb = ' + nb)
    const $input = $(input)
    const $form = $input.closest('form')
    let newNb = nb + 1
    // console.log('verify field : ' + $input.attr('id') + ' current nb : ' + newNb + ' et form memory nb = ' + $form.data('currentnb'))
    $form.attr('data-currentnb', newNb)

    if ($('.state-ft .ft-badge-unsvg').length <= 0) {
        $('.state-ft').append('<button title="Sauvegarder le contenu ?" class="btn btn-yellow ft-badge-unsvg" data-element="' + $input.attr('id') + '"><i class="fal fa-save"></i> À sauvegarder</button>')
        $('.ft-badge-svg-success').remove()
    }

    if (newNb > 15 && !$form.hasClass('svg')) {
        $form.addClass('svg')
        const form = document.getElementById($form.attr('id'))
        const formData = new FormData(form)

        const url = $(form).data('url')
        $('.ft-badge-svg-success').remove()
        $('.ft-badge-unsvg').remove()
        $('.state-ft').append('<button class="btn btn-yellow ft-badge-svg"><i class="fal fa-circle-notch fa-spin"></i>&nbsp;Sauvegarde en cours</button>')

        $.post({
            url: url,
            data: formData,
            contentType: false,
            processData: false,
            success: function (data) {
                if (data.state == false) {
                    $('.state-ft').append('<button class="btn btn-danger"><i class="fal fa-exclamation-triangle"></i> Une erreur est survenue</button>')
                    $('#view-container').html(data.content)
                } else {
                    $('.state-ft').append('<button class="btn btn-aqua-green ft-badge-svg-success"><i class="fal fa-check-circle"></i> Sauvegarde terminée</button>')
                    $('.ft-badge-svg').remove()
                    $('.form-transparent').removeClass('observer')
                    $form.removeClass('svg')
                }
            }
        })
        return true
    }
    return false
}

const calculYiq = function (hexa) {
    if (hexa === null) {
        return '#000'
    }

    if (hexa === '#fff') {
        return '#000'
    }

    if (hexa === '#000') {
        return '#fff'
    }

    let r = parseInt(hexa.substr(1, 2), 16)
    let g = parseInt(hexa.substr(3, 2), 16)
    let b = parseInt(hexa.substr(5, 2), 16)

    const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

    return (yiq >= 128) ? '#000' : '#fff'
}

const pngToCanvas = function (image, id) {
    var canvas = document.createElement("canvas");
    canvas.width = 32;
    canvas.id = 'canvas-marker-' + id
    canvas.height = 32;
    canvas.getContext("2d").drawImage(image, 0, 0);
    $(document.body).append(canvas)
    // console.log(canvas, image)

    return canvas;
}

const hexToRgb = function (hex) {
    if (hex === null) {
        return null
    }
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
        return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}

const updateTheme = function (id, color) {
    let yiqColor = tools.calculYiq(color)
    // console.log('yiqColor is : ' + yiqColor)
    // yiqColor = '#' + yiqColor

    $('.btn-' + id).css('background-color', color + ' !important')
    $('.btn-' + id).each(function (i, elm) {
        elm.style.borderColor = color
    })
    $('.text-' + id).css('color', color + ' !important')
    $('.btn-' + id).css('color', yiqColor + ' !important')
    $('.badge-' + id).css('background-color', color + ' !important')
    $('.badge-' + id).css('color', yiqColor + ' !important')
    $('.bg-' + id).css('background-color', color + ' !important')
    $('.bg-' + id).css('color', yiqColor + ' !important')
    // console.log('here')
    $('.alert-' + id).each(function (i, elm) {
        elm.style.borderColor = color
    })
    $('.alert-' + id).css('background-color', color + ' !important')
    $('.alert-' + id).css('color', yiqColor + ' !important')
    $('.btn-outline-' + id).css('color', color)
    $('.btn-outline-' + id).each(function (i, elm) {
        elm.style.borderColor = color
    })
    $('.btn-outline-' + id + ':hover').css('background-color', color)
    $('.btn-outline-' + id + ':hover').css('color', yiqColor)
    // console.log('end listener:change .form-color')
}
// @deprecated
const addToDebug = function (message, type = 'info') {
    return
    // @todo test if local (or super admin ?)
    const wrapper = `
        <div class="py-2 col-12 bg-` + type + `">
            ` + message + `
        </div>
    `

    const $debugContainer = $('body #js-debug-container')

    if ($debugContainer.length <= 0) {
        console.log('debugContainer was not found')

        return
    }

    $debugContainer.append(wrapper)

    const d = document.getElementById('js-debug-container')

    $debugContainer.parent().animate({
        scrollTop: d.scrollHeight
    }, 1000)
}

const toolsDebug = function (message, type = 'info') {
    let toolsContainerId = 'tools-debug-container'
    let $debugWrapper = tools.debugWrapper()

    return

    let $toolsContainer = $('body #' + toolsContainerId)

    if ($toolsContainer.length <= 0) {
        $debugWrapper.append('<h5 class="h5 toggle-container" data-target="' + toolsContainerId + '"><i class="fal fa-bug"></i> Tools</h5><div class="row" id="' + toolsContainerId + '"></div>')

        $toolsContainer = $('body #' + toolsContainerId)
        $toolsContainer.hide()
    }

    let $alerts = $('body #' + toolsContainerId + ' .alert')

    if ($alerts.length >= 25) {
        let limit = $alerts.length - 5
        $alerts.each(function (i, elm) {
            if (i >= limit) {
                return
            }
            $(elm).hide('slow', function () {
                this.remove()
            })
        })
    }

    const ts = moment().unix()
    const alertId = 'debug-alert-' + Math.floor(Math.random() * Math.floor(ts))


    $toolsContainer.append('<div id="' + alertId + '" class="alert hide col-12 rounded-0 alert-' + type + ' mb-1">' + message + '</div')

    $('body #' + alertId).show('slow')

    let toolsContainerNode = document.getElementById(toolsContainerId)
    $toolsContainer.parent().animate({
        scrollTop: toolsContainerNode.scrollHeight
    }, 1000)
}

const debugWrapper = function () {
    // return

    let debugWrapperId = 'debug-wrapper'

    let $debugWrapper = $('body #' + debugWrapperId)

    let devMode = $('body').hasClass('dev-mode') ? '' : 'hide'

    if ($debugWrapper.length <= 0) {
        $('body').append('<div class="wrapper ' + devMode + ' hide" id="' + debugWrapperId + '"></div>')
        $debugWrapper = $('body #' + debugWrapperId)
        $debugWrapper.draggable()
    }

    return $debugWrapper
}

const getCookie = function (name) {
    var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    // console.log(match)
    if (match) return match[2];

    return false
}

const setCookie = function (name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

const eraseCookie = function(name) {
    var cookies = document.cookie.split('_')
    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
    }
}

const launchTac = function () {
    tarteaucitron.init({
        "privacyUrl": "/page/2/politique-de-confidentialite", /* Privacy policy url */
        "hashtag": "#consent-edit", /* Open the panel with this hashtag */
        "cookieName": "eu-consent", /* Cookie name */
        "orientation": "bottom", /* Banner position (top - bottom) */
        "groupServices": false, /* Group services by category */
        "showAlertSmall": false, /* Show the small banner on bottom right */
        "cookieslist": true, /* Show the cookie list */
        "closePopup": true, /* Show a close X on the banner */
        "showIcon": false, /* Show cookie icon to manage cookies */
        //"iconSrc": "", /* Optionnal: URL or base64 encoded image */
        "iconPosition": "BottomRight", /* BottomRight, BottomLeft, TopRight and TopLeft */
        "adblocker": false, /* Show a Warning if an adblocker is detected */
        "DenyAllCta": true, /* Show the deny all button */
        "AcceptAllCta": true, /* Show the accept all button when highPrivacy on */
        "highPrivacy": true, /* HIGHLY RECOMMANDED Disable auto consent */
        "handleBrowserDNTRequest": false, /* If Do Not Track == 1, disallow all */
        "removeCredit": true, /* Remove credit link */
        "moreInfoLink": true, /* Show more info link */
        "useExternalCss": false, /* If false, the tarteaucitron.css file will be loaded */
        "useExternalJs": false, /* If false, the tarteaucitron.js file will be loaded */
        //"cookieDomain": ".my-multisite-domaine.fr", /* Shared cookie for multisite */
        "readmoreLink": "", /* Change the default readmore link */
        "mandatory": true, /* Show a message about mandatory cookies */
    });
    (tarteaucitron.job = tarteaucitron.job || []).push('recaptcha');
    (tarteaucitron.job = tarteaucitron.job || []).push('googlemapsembed');

}

const documentUri = function () {
    return $(document.body).data('appdmuri')
}

const documentToken = function () {
    return $(document.body).data('appdmtk')
}

const documentCstmr = function () {
    return $(document.body).data('cstmr')
}

const appendContentToTinyMCE = function (content, tinymceId = null, insertMode = true) {
    // console.log('tinymceId', tinymceId)
    if (tinymceId === null) {
        tinymce.activeEditor.execCommand('mceInsertContent', false, content)

        return
    }

    if (tinymce.get(tinymceId) == null) {
        console.log('tinymce #' + tinymceId + ' editor not found')
        return
    }

    if (insertMode) {
        tinymce.get(tinymceId).execCommand('mceInsertContent', false, content)
        return
    }

    tinymce.get(tinymceId).setContent(content)
}

const verifyPasswordRules = function (password, rulesId) {
    let $rulesContainer = $('body #' + rulesId)

    if ($rulesContainer.length <= 0) {
        return
    }

    let $rules = $rulesContainer.children('.rules')
    let verifyAllRules = true

    $rules.each(function (i, elm) {
        const $rule = $(elm)

        switch ($rule.data('rules')) {
            case 'length':
                let requiredLength = $rule.data('length')

                if (requiredLength == undefined || requiredLength == '') {
                    requiredLength = 8
                }

                tools.updatePasswordRules($rule, (password.length >= requiredLength))
                verifyAllRules = !(password.length >= requiredLength) ? false : verifyAllRules
                break
            case 'number':
                let regexNumber = /[0-9]/
                let hasNumber = regexNumber.test(password)

                tools.updatePasswordRules($rule, hasNumber)
                verifyAllRules = !hasNumber ? hasNumber : verifyAllRules
                break
            case 'upper-letter':
                let regexUpper = /[A-Z]/
                let hasUpperLetter = regexUpper.test(password)
                tools.updatePasswordRules($rule, hasUpperLetter)
                verifyAllRules = !hasUpperLetter ? hasUpperLetter : verifyAllRules
                break
            case 'special-letter':
                let regexSpecialLetter = /[$%!_?@*]/
                let hasSpecialLetter = regexSpecialLetter.test(password)
                tools.updatePasswordRules($rule, hasSpecialLetter)
                verifyAllRules = !hasSpecialLetter ? hasSpecialLetter : verifyAllRules
                break
        }

    })

    return verifyAllRules
}

const updatePasswordRules = function (elm, state) {
    let $rule = $(elm)

    $rule.children('.rule-state').remove()

    if (state) {
        $rule.prepend('<i class="fa fa-check-circle text-aqua-green rule-state"></i>')
    } else {
        $rule.prepend('<i class="fa fa-times-circle text-danger rule-state"></i>')
    }
}

const openSidebar = function () {
    $('.sidebar').css('width', '100%')
    $('.sidebar').addClass('col-lg-2 col-12')
    $('.sidebar .item-wrapper .row').removeClass('text-center')
    $('.layout').removeClass('col').addClass('col-lg-10 col-12')
    $('.page-wrapper').removeClass('col-xl-11').addClass('col-xl-10')
    $('.sidebar-text-elm').show()
    let $logoDefi = $('body #topleft-logo')
    let $logoContainer = $logoDefi.parent().parent()
    $logoContainer.addClass('col-md-2')
    $logoDefi.attr('src', $logoDefi.data('full'))
    $('.sidebar-text-elm').show()
    $('body .expand-main-navbar').addClass('compress-main-navbar').removeClass('expand-main-navbar')
    $('.sidebar .customer-select-container').addClass('float-right').removeClass('col-12')
    tools.setCookie('sidebar-is-compressed', false, -1)
}

const closeSidebar = function () {
    $('.sidebar-text-elm').hide()
    $('.sidebar').css('width', '75px')
    $('.page-wrapper').removeClass('col-xl-10').addClass('col-xl-11')
    $('.sidebar').removeClass('col-lg-2 col-12')
    $('.sidebar .item-wrapper .row').addClass('text-center')
    $('.layout').removeClass('col-lg-10 col-12').addClass('col')
    let $logoDefi = $('body #topleft-logo')
    $logoDefi.attr('src', $logoDefi.data('ico'))
    let $logoContainer = $logoDefi.parent().parent()
    $logoContainer.css('width', '75px')
    $logoContainer.removeClass('col-md-2')
    $('body .compress-main-navbar').addClass('expand-main-navbar').removeClass('compress-main-navbar')
    $('.sidebar .customer-select-container').addClass('col-12')
    tools.setCookie('sidebar-is-compressed', true, 30)
}

const hasWhiteSpace = function (s) {
    return /\s/g.test(s)
}

const possibleBootstrapCol = function () {
    let possibleCol = ''
    let size = ['', 'lg', 'xl', 'sm', 'md']
    for (var c = 1; c < 13; c++) {
        for (var d = 0; d < size.length; d++) {
            let hasTiret = d == 0 ? '' : '-'
            possibleCol += ' col' + hasTiret + size[d] + '-' + c
        }
    }

    return possibleCol
}


const tools = {
    loadingWrapper,
    loadContent,
    playOnMenu,
    playOffMenu,
    verifyTransparentForm,
    loadModal,
    appendModal,
    updateEntity,
    deleteEntity,
    init,
    calculYiq,
    updateTheme,
    confirm,
    updateBrowserHistory,
    addToDebug,
    filterPeriod,
    slug,
    numberWithSpace,
    generateRgba,
    debugWrapper,
    autoloading,
    toolsDebug,
    addToProcess,
    isInProcess,
    sidebarShowText,
    navbarSearch,
    makeBigContainer,
    getCookie,
    setCookie,
    eraseCookie,
    getRandomInt,
    pngToCanvas,
    hexToRgb,
    documentUri,
    documentToken,
    documentCstmr,
    launchTac,
    appendContentToTinyMCE,
    verifyPasswordRules,
    updatePasswordRules,
    getRandomColor,
    openSidebar,
    closeSidebar,
    hasWhiteSpace,
    sleep,
    possibleBootstrapCol,
    loadChartsByRules,
    loadRulesByInters,
    loadInterByContract,
    selectInters,
    reportSearchInter,
    checkAll,
    // checkPieges,
    // checkNusibles,
    checkBoxes,
    cookies,
    datatableListener,
    loadCookie,
    activePhotos,
    activeSeuil,
    cookiePieges,
    cookieNuisibles,
    consoDefault,
    deleteCookie,
    filterDate,
    btnLoadInterPlan,

    // verifyCookie
    // listInterventionByContract
}

export default tools