import './jquery.dataTables'
import './dataTables.bootstrap4.js'
import './dataTables.buttons.js'
import './buttons.bootstrap4.js'
import './dataTables.searchBuilder.js'
import './dataTables.select.js'

import { Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import frLocale from '@fullcalendar/core/locales/fr'
import hygchart from './hygchart'
import interactionPlugin from '@fullcalendar/interaction'
import moment from 'moment'
import plan from './plan'
import timeGridPlugin from '@fullcalendar/timegrid'
import tools from './tools'

const loaderDebug = function (message, type = 'info') {
    let loaderContainerId = 'loader-debug-container'
    let $debugWrapper = tools.debugWrapper()

    let $loaderContainer = $('body #' + loaderContainerId)

    if ($loaderContainer.length <= 0) {
        $debugWrapper.append('<h5 class="h5 toggle-container" data-target="' + loaderContainerId + '"><i class="fal fa-circle-notch fa-spin"></i> Loader</h5><div class="row" id="' + loaderContainerId + '"></div>')
        
        $loaderContainer = $('body #' + loaderContainerId)
        $loaderContainer.hide()
    }

    let $alerts = $('body #' + loaderContainerId + ' .alert')
    // if ($alerts.length >= 25) {
    //     let limit = $alerts.length - 2
    //     $alerts.each(function (i, elm) {
    //         if (i >= limit) {
    //             return
    //         }
    //         $(elm).hide('slow')
    //     })
    // }
    
    const ts = moment().unix()
    const alertId = 'debug-alert-' + Math.floor(Math.random() * Math.floor(ts))

    $loaderContainer.append('<div id="' + alertId + '" class="alert col-12 rounded-0 alert-' + type + ' mb-1">' + message + '</div')

    let loaderContainerNode = document.getElementById(loaderContainerId)

    $loaderContainer.parent().animate({
        scrollTop: loaderContainerNode.scrollHeight
    }, 1000)
}

const fullCalendarLoader = function () {    
    loader.loaderDebug('loader::fullCalendarLoader() fired')
    let calendarDashboard = $('body .fullcalendar-dashboard')
    if (calendarDashboard.length > 0) {
        if (!$(calendarDashboard).hasClass('initialized')) {
            $(calendarDashboard).addClass('initialized')
            let clId = calendarDashboard.attr('id')
            let node = document.getElementById(clId)
            let feeds = calendarDashboard.data('feeds')
            let hiddenDays = calendarDashboard.data('closed-days')
            if (hiddenDays == undefined || hiddenDays == '' && hiddenDays.length <= 0) {
                hiddenDays = []
            } else if (hiddenDays == 0) {
                hiddenDays = [0]
            } else {
                let days = hiddenDays

                if (/,/.test(days)) {
                    days = hiddenDays.split(',')
                    hiddenDays = []
                    for (var i = 0; i < days.length; i++) {
                        hiddenDays.push(parseInt(days[i]))
                    }
                    if (hiddenDays.length == 7) {
                        hiddenDays = []
                    }
                } else {
                    hiddenDays = [days]
                }
            }

            var calendar = new Calendar(node, {
                plugins: [ dayGridPlugin, timeGridPlugin, interactionPlugin ],
                events: feeds,
                // aspectRatio: 1,
                locales: [ frLocale ],
                locale: 'fr',
                // contentHeight: 350,
                hiddenDays: hiddenDays,
                defaultAllDay: false,
                showNonCurrentDates: false,
                fixedWeekCount: false,
                eventClick: function (info) {
                    info.jsEvent.preventDefault()
                    let elm = $(info).get(0).el
                    if ($(elm).hasClass('event-on-modal')) {
                        
                        tools.loadModal(info.event.url)

                        return
                    }
                    tools.loadContent($('body #page-container'), info.event.url)
                }
            })
            calendar.render()
        }
    }
}

const datatableLoader = function () {
    loader.loaderDebug('loader::datatableLoader() fired')

    if ($('.datatable').length > 0) {
        const $datatables = $('.datatable')

        
        $datatables.each(function (i, elm) {
            const $datatable = $(elm)

            const datableId = $datatable.attr('id')
        
            
            if ($datatable.hasClass('initialized')) {
                return
            }

            $datatable.hide()

            $datatable.addClass('initialized')
            $.fn.DataTable.ext.search.push(
                function (settings, data, dataIndex) {
                    let $toggles = $('body .toggle-table')
                    let table = $('body #' + datableId).DataTable()
                    let isTarget = true
                    $toggles.each(function (i, elm) {
                        let target = $(elm).data('target')

                        if (!$(elm).is(':checked')) {
                            let isConcerned = $(table.row(dataIndex).node()).hasClass(target)
                            if (isConcerned) {
                                isTarget = false
                            }
                        }
                    })

                    return isTarget
                }
            )

            let table = $('body #' + datableId).DataTable({
                pageLength: 50,
                "language": {
                    "url": "https://cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/French.json"
                },
                "paging": true,
                colReorder: {
                    enable: true,
                    realtime: true
                },
                "autoFill": true,
                "headerCallback": function (thead, data, start, end, display) {
                    const $infos = $('body .datatables-caption')
                    if ($datatable.hasClass('search-col-12')) {
                        $('body #'+datableId+'_filter').parent().removeClass('col-sm-12 col-md-6').addClass('col-12')
                        $('body #'+datableId+'_filter label').css('width', '100%')
                    }
                    if ($infos.length <= 0 || $infos.hasClass('initialized')) {
                        return
                    }
                    $infos.addClass('initialized')
                    const html = $infos.html()
                    $('.dataTables_wrapper').prepend('<div class="row"><div class="col-12 text-right mb-lg-2 mb-1">' + html + '</div></div>')
                    if ($infos.hasClass('search-and-length-auto')) {
                        $('.dataTables_length').addClass('col-6')
                    } else {
                        $('.dataTables_length').addClass('col-6')
                    }
                    $infos.html('')
                    $('.dataTables_filter').parent().addClass('text-right')
                    $('.dataTables_length select').addClass('form-control')
                    $('.toggle').removeClass('disabled')
                    loader.bootstrapToggleLoader()
                },
                initComplete: function (settings, json) {
                    $('body #' + datableId).fadeIn('slow')
                    $('body #' + datableId).on('dt.draw', function () {
                        console.log('draw')
                        tools.datatableListener($datatable)
                    })
                },
                "drawCallback": function (settings) {
                    $('body #' + datableId).fadeIn('slow')
                    console.log('redraw')
                    tools.datatableListener($datatable)
                }
            })
            $('body #' + datableId).on('draw', function () {
                console.log('draw')
                tools.datatableListener($datatable)
            })
            
        })
    }
}

const select2Loader = function () {
    loader.loaderDebug('loader::select2Loader() fired')
    let select2DefaultPlaceholder = 'Sélectionner une valeur'
    $('body .select2').each(function (i, elm) {
        const $select = $(elm)
        if ($select.hasClass('ready')) {
            return
        }

        if ($select.attr('id') == undefined) {
            return
        }

        $select.addClass('ready')
        if ($select.length <= 0) {
            return
        }
        let selectPlaceholder = $select.data('placeholder')
        if (selectPlaceholder != undefined) {
            select2DefaultPlaceholder = selectPlaceholder
        }

        $select.select2({
            placeholder: select2DefaultPlaceholder,
            theme: 'bootstrap4',
            allowClear: true
        })
    })

    $('body .select2-jx-data').each(function (i, elm) {
        const $select = $(elm)
        
        if ($select.attr('id') == undefined) {
            return
        }
        
        if ($select.hasClass('ready')) {
            return
        }

        $select.addClass('ready')
        $select.select2({
            ajax: {
                url: $select.data('feeds'),
                theme: 'bootstrap4',
                dataType: 'json',
                data: function (params) {
                    var query = {
                        search: params.term
                    }
            
                    return query
                },
            },
            placeholder: 'Effectuer une recherche (3 caractères min.)',
            minimumInputLength: 3,
            language: {
                inputTooShort: function () {
                    return "Veuillez saisir plus de caractères..."
                }
            }
          })
    })
}

const bootstrapToggleLoader = function () {
    loader.loaderDebug('loader::bootstrapToggleLoader() fired')
    $('body input[type="checkbox"].toggle').each(function (i, elm) {
        let id = $(elm).attr('id')
        let $toggle = $(elm)

        if ($toggle.hasClass('initialized') || $toggle.hasClass('disabled')) {
            return
        }

        $toggle.addClass('initialized')

        if (id == undefined) {
            id = 'toggle-elm-' + tools.getRandomInt()
            $toggle.attr('id', id)
        }

        $('body #' + id).bootstrapToggle()
    })
}

const chartLoader = function () {
    loader.loaderDebug('loader::chartLoader() fired')
    hygchart.loadDashboardGraph()
    // hygchart.loadTrapGraph()
}

const planLoader = function () {
    loader.loaderDebug('loader::planLoader() fired')
    plan.load()
}

const counterLoader = function () {
    loader.loaderDebug('loader::couterLoader() fired')
    const $counters = $('body .jx-count')

    $counters.each(function (i, elm) {
        const $counter = $(elm)

        if ($counter.hasClass('initialized')) {
            return
        }

        $counter.addClass('initialized')

        $.get({
            url: $counter.data('url'),
            success: function (response) {
                $counter.html(response.nb)
            }
        })
    })
}

const interventionSummaryLoader = function () {
    let $summariesBtn = $('body .summaries-btn')

    if ($summariesBtn.length <= 0) {
        return
    }

    if ($('body').hasClass('summary-in-load')) {
        return
    }

    $('body').addClass('summary-in-load')

    const route = Routing.generate('app_customer_intervention_summary', {
        slug: tools.slug()
    })

    // $.get({
    //     url: route,
    //     success: function (response) {
    //         if (response.summaries == undefined) {
    //             return
    //         }

    //         if (response.summaries.length <= 0) {
    //             return
    //         }

    //         const info = (response.summaries.length > 1) ? response.summaries.length + ' Rapports d\'intervention' : '1 Rapport d\'intervention'

    //         let summariesListUrl = undefined

    //         if (response.summaries.length == 1) {
    //             summariesListUrl = Routing.generate('app_customer_intervention_summary_view', {
    //                 slug: tools.slug(),
    //                 intervention: response.summaries[0].id
    //             })
    //         } else {
    //             summariesListUrl = Routing.generate('app_customer_intervention_summary_list', {
    //                 slug: tools.slug()
    //             })
    //         }

    //         if ($summariesBtn.length <= 0) {
    //             return
    //         }

    //         $summariesBtn.addClass('btn-menu btn-url').removeClass('btn-outline-menu')
            
    //         $summariesBtn.data('container', 'page-container')
    //         // $summariesBtn.data('big-loading', 'page-container')
    //         // $summariesBtn.data('bg-loading-message', "Chargement de votre rapport d'intervention en cours")
    //         $summariesBtn.data('url', summariesListUrl)
    //         $summariesBtn.find('.counter-values')
    //             .addClass('btn btn-sm rounded-circle btn-yellow py-0 px-1')
    //             .attr('title', info)
    //             .html(response.summaries.length)

    //         setTimeout(function () {
    //             $('body .summaries-btn .fa.fa-bell').addClass('bell-anime')
    //         }, 3000)
    //     }
    // })

}

const viewLoader = function (elm, e) {
    e.stopImmediatePropagation()
    const $btn = $(elm)
    const url = $btn.data('url')
    if (url == undefined) {
        console.log('loader::viewLoader() data-url = undefined')

        return
    }

    let $container = $('#view-container')
    let containerId = 'page-container'

    if ($btn.data('container') != undefined) {
        $container = $('body #' + $btn.data('container'))
        containerId = $btn.data('container')
    }

    let inModal = false
    if ($btn.hasClass('in-modal')) {
        $('.modal-wrapper-main').show('slow')
        inModal = true
    }

    if ($btn.hasClass('close-modal') && $('body .modal').length > 0) {
        $('body .modal').hide('slow', function () {
            $(this).remove()
        })
        $('body .jquery-modal.blocker').hide('slow', function () {
            $(this).remove()
        })
    }

    if ($btn.hasClass('close-target')) {
        let closeTarget = $btn.data('close-target')

        if (closeTarget == undefined) {
            return
        }

        $('body .' + $btn.data('close-target')).hide('slow')

        if (closeTarget == 'first-section') {
            $('body #first-section').addClass('open-container').removeClass('close-container')
            $('body #first-section .arrow-up').hide('slow')
            $('body #first-section .arrow-down').show('slow')
        }
    }

    if ($btn.hasClass('breadcrumb-item')) {
        let $parent = $btn.parent()
        let $breacrumbsItem = $('body #' + $parent.attr('id') + ' .breadcrumb-item')
        let isRemoved = false
        $breacrumbsItem.each(function (i, elm) {
            if ($(elm).attr('id') == $btn.attr('id') && !$(elm).hasClass('active')) {
                $(elm).addClass('active')
                isRemoved = true
                return
            }
            if (isRemoved) {
                $(elm).remove()
            }
        })
        $breacrumbsItem.last().addClass('active')

        if ($btn.hasClass('home')) {
            tools.updateBrowserHistory($btn.data('history'))
        } else if (!$btn.hasClass('no-history')) {
            tools.updateBrowserHistory($btn.data('url'))
        }
    }

    if ($btn.data('breadcrumb-html') != undefined) {
        const $breacrumb = $('body .breadcrumb')
        let breadcrumbId = $breacrumb.attr('id')

        const $breacrumbsItem = $('body #' + breadcrumbId + ' .breadcrumb-item')
        $breacrumbsItem.last().removeClass('active')

        let html = ''

        if ($btn.data('breadcrumb-html') != undefined) {
            html = $btn.data('breadcrumb-html')
        } else {
            html = $btn.html()
            html = html.replace('fa-2x', '')
            html = html.replace('fa-3x', '')
            html = html.replace('<br>', '&nbsp;')
        }

        let breacrumbId = 'breadcrumb-elm-' + tools.getRandomInt()

        let wrapper = `
        <li class="breadcrumb-item btn-url active no-history" id="` + breacrumbId + `"
            data-url="` + url + `"
            data-container="` + containerId + `">
            ` + html + `
        </li>
        `

        $breacrumb.append(wrapper)
    }

    if ($btn.data('sidebar-active') != undefined) {
        let sidebarTarget = $btn.data('sidebar-active')
        $('body .item-wrapper').removeClass('active')

        if (sidebarTarget !== 'unset') {
            $('body ._sidebar-' + sidebarTarget).addClass('active')
        }
    }

    if ($btn.hasClass('nav-link') && !$btn.hasClass('toggle-container')) {
        let $ulContainer = $btn.parent().parent()
        if ($ulContainer.length > 0) {
            let ulContainerId = $ulContainer.attr('id')

            if (ulContainerId === undefined) {
                console.log('The ul container need an id to manage active state', 'danger')

                return
            }

            $('body #' + ulContainerId + ' .nav-link').removeClass('active')
            $btn.addClass('active')
        }
    }

    let isBigLoading = $btn.hasClass('big-loading')
    let bigLoadingMessage = $btn.data('big-loading-message')
    let bigLoadingContainerId = $btn.data('big-loading')

    if (bigLoadingContainerId != undefined) {
        tools.makeBigContainer(bigLoadingContainerId)
    }

    let arSettings = {
        target: $btn.data('ar-target') != undefined ? $btn.data('ar-target') : null,
        delay: $btn.data('ar-delay') != undefined ? $btn.data('ar-delay') : null
    }

    tools.loadContent($container, url, inModal, isBigLoading, bigLoadingMessage, arSettings)
}

const flashLoader = function () {
    const route = Routing.generate('app_frontend_flash_index')

    const containerId = 'flashs-wrapper'

    let $flashContainer = $('body #' + containerId)

    if ($flashContainer.length <= 0) {
        $(document.body).append('<div class="container" id="flashs-container"><div class="row" id="' + containerId + '"></div></div>')

        $flashContainer = $('body #' + containerId)
    }

    if ($flashContainer.hasClass('in-process')) {
        return
    }

    $flashContainer.addClass('in-process')

    $.get({
        url: route,
        success: function (content) {
            $flashContainer.append(content)
            const $toasts = $('body .toast')
            $flashContainer.removeClass('in-process')
            $toasts.toast({
                animation: true,
                autohide: true,
                delay: 5000
            })
            $toasts.toast('show')
            $toasts.on('hidden.bs.toast', function () {
                this.remove()
            })
        }
    })
}

const inputRangeLoader = function () {

    let $ranges = $('body .is-range-input')

    $ranges.each(function (i, elm) {
        const $range = $(this)
        const rangeId = $range.attr('id')

        let value = $range.val()
        let wrapperOfRangeClassName = 'feedvalue-' + rangeId
        let $wrapper = $('body .' + wrapperOfRangeClassName)

        if ($range.hasClass('is-percent-range')) {
            value += ' %'
        }

        $wrapper.html(value)
    })
}

const inputPasswordLoader = function () {
    let $passwordFields = $('body .form-password')
    
    $passwordFields.each(function (i, elm) {
        const $elm = $(elm)

        if ($elm.hasClass('initialized')) {
            return
        }

        $elm.after('<span class="ico-field" title="Cliquer et laisser le curseur sur l\'oeil pour vérifier votre saisi"><i class="fa fa-eye"></i></span>')
    })
}

const loader = {
    loaderDebug,
    fullCalendarLoader,
    select2Loader,
    datatableLoader,
    bootstrapToggleLoader,
    planLoader,
    chartLoader,
    counterLoader,
    interventionSummaryLoader,
    viewLoader,
    flashLoader,
    inputRangeLoader,
    inputPasswordLoader
}

export default loader