import chartJs, { Chart } from 'chart.js';

import ChartDataLabels from 'chartjs-plugin-datalabels';
import moment from 'moment';
import tools from './tools';

chartJs.plugins.register(ChartDataLabels);

const loadDashboardGraph = function (reference = null) {
    let $graphs = $('body .dashboard-graph');
    if ($graphs.length <= 0) {
        hygchart.chartDebug('No chart detected', 'danger');
        hygchart.planTrapChart();
        hygchart.planTrapCountChart();
        return;
    }
    let ts = 0;
    $graphs.each(function (i, elm) {
        const $graphCanvas = $(elm);
        const $canvasContainer = $graphCanvas.parent();
        let graphId = $(elm).attr('id');
        let chartType = $(elm).data('chart');
        let isLast = (i+1) == $graphs.length;
        if ($graphCanvas.hasClass('beta-graph') && !$graphCanvas.hasClass('initialized')) {
            chartType = 'auto';
            // $canvasContainer.hide()
        }

        if (graphId == undefined) {
            hygchart.chartDebug('graph need an id for switch the instance to load', 'warning');

            return;
        }

        switch (chartType) {
            case 'auto':
                setTimeout(function () {
                    hygchart.loadGraph(elm, isLast);
                }, ts);
            break;
            default:
                hygchart.chartDebug('graphId <b>' + graphId + '</b> didn\'t match switch instance', 'danger');
            break;
        }
        ts += 500;
    });
};

const planTrapChart = function () {
    let $graphs = $('body .pieges-graph');

    if ($graphs.length <= 0) { return; }
    $graphs.each((i, elm) => {
        if ($(elm).hasClass('initialized')) { return; }
        hygchart.loadTrapChart(elm);
    });
};

const planTrapCountChart = function() {
    let $graphs = $('body .pieges-count-graph');

    if ($graphs.length <= 0) { return; }
    $graphs.each((i, elm) => {
        if ($(elm).hasClass('initialized')) { return; }
        hygchart.loadTrapCountChart(elm);
    });
};

const showCharts = function (chartClass) {
    // console.log('showCharts is fired with chartClass -> ' + chartClass)
    let $graphs = $('body .' + chartClass);
    
    // console.log($graphs.length + ' found')


    $graphs.each(function (i, elm) {
        const $canvasContainer = $(elm);
        // const $canvasContainer = $graphCanvas.parent()
        // console.log('each .' + chartClass + ' elm')
        // console.log($canvasContainer)
        // let graphId = $(elm).attr('id')
        // let chartType = $(elm).data('chart')
        // if ($graphCanvas.hasClass('beta-graph')) {
            // chartType = 'auto'
            $canvasContainer.show();
        // }
    });
};

const loadTrapChart = function (elm) {
    $.get({
        url: $(elm).data('feeds'),
        contentType: false,
        processData: false,
        success: function (response) {
            const data = {
                labels: response.stats.labels,
                datasets: response.stats.data
            };
            var iChart = new Chart(elm, {
                type: 'line',
                data: data,
                options: {
                    scales: {
                        yAxes: [{
                            ticks: {
                                min: 0,
                                max: 100,
                                stepSize: 20
                            }
                        }]
                    },
                }
            });
        }
    });
};

const loadTrapCountChart = function (elm) {
    $.get({
        url:$(elm).data('feeds'),
        contentType: false,
        processData: false,
        success: function (response) {
            const data = {
                labels: response.stats.labels,
                datasets: response.stats.data
            };
            var iChart = new Chart(elm, {
                type: 'bar',
                data: data,
                options: {
                    scales: {
                        yAxes: [{
                            ticks: {
                                min: 0,
                            }
                        }]
                    }
                }
            });
        }
    });
};

const loadGraph = function (elm, isLast = false) {
    let $graph = $(elm);
    let graphId = $graph.attr('id');
    let feedsUrl = $graph.data('feeds');
    let node = document.getElementById(graphId);
    let chartWrapper = $graph.data('chart-wrapper');
    let graphType = $graph.data('chart');
    let chartReference = $graph.data('chart-reference');
    let labelsLimitForDefaultGraph = $graph.data('limit');
    let overLimitGraphType = $graph.data('chart-limit');
    let resizeMe = $graph.hasClass('resize-me') ? true : false;
    let $parentContainer = $graph.parent();
    let $chartFormContainer = $('body #chart-form-' + graphId);
    let hasChartForm = $chartFormContainer.length > 0;
    let data = {};
    let $progressChart = $('body #progress-' + graphId);

    if ($graph.hasClass('initialized')) {
        hygchart.chartDebug('graph <b>' + graphId + '</b> already initialized');
        return;
    }
    if ($graph.hasClass('first-try')) {
        hygchart.chartDebug('graph <b>' + graphId + '</b> is on first-try initialization', 'warning');
        return;
    }

    if (hasChartForm) {
        data = new FormData(document.getElementById($chartFormContainer.attr('id')));
    }
    
    if ($graph.hasClass('chartjs-render-monitor')) {
        let newCanvas = document.createElement('canvas');
        $parentContainer.children('.chartjs-size-monitor').hide('slow', function () {
            $(this).remove();
        });
        $graph.remove();
        newCanvas.id = graphId;
        newCanvas.height = 1;
        newCanvas.width = 1;
        $parentContainer.append(newCanvas);
        $graph = $('body #' + graphId);
        $graph.data('feeds', feedsUrl);
        $graph.data('chart', graphType);
        $graph.data('chart-reference', chartReference);
        $graph.data('limit', labelsLimitForDefaultGraph);
        $graph.data('chart-limit', overLimitGraphType);
        $graph.addClass('dashboard-graph beta-graph');
        if (true === resizeMe) {
            $graph.addClass('resize-me');
        }
        node = document.getElementById(graphId);
    }
    $graph.addClass('first-try');

    $.post({
        url: feedsUrl,
        data: data,
        contentType: false,
        processData: false,
        success: function (response) {
            if (response.state != undefined && response.state == false) {
                $progressChart.removeClass('bg-d-blue').addClass('bg-danger');
                hygchart.destroyCanvasContainer($graph, chartWrapper);
                return;
            }

            if (response.stats.datasets && response.stats.datasets.length <= 0) {
                $progressChart.removeClass('bg-d-blue').addClass('bg-danger');
                hygchart.destroyCanvasContainer($graph, chartWrapper);
                return;
            }

            let labels = response.stats.labels;
            let data = response.stats.data;

            if (labels.length <= 0) {
                $progressChart.removeClass('bg-d-blue').addClass('bg-danger');
                hygchart.destroyCanvasContainer($graph, chartWrapper);
                return;
            }

            let isDatasets = response.stats.datasets;
            let bgColors = response.stats.backgroundColor != undefined ? response.stats.backgroundColor : [];
            let dataSetsLabel = response.stats.label != undefined ? response.stats.label : 'Statistiques';
            let hasTitle = response.stats.title != undefined ? true : false;
            let title = response.stats.title != undefined ? response.stats.title : '';
            let isPercent = response.stats.isPercent != undefined ? true : false;

            if ((data == undefined || data.length <= 0) && (isDatasets == undefined || isDatasets.length <= 0)) {
                hygchart.destroyCanvasContainer($graph, chartWrapper);
                return;
            }
            
            let chartSettings = {};
            chartSettings.options = {};

            if (isDatasets != undefined) {
                graphType = isDatasets.length > 1 ? graphType : 'bar';
                let hasCookieForGraph = tools.getCookie(chartReference);
                graphType = hasCookieForGraph === false ? graphType : hasCookieForGraph;

                if (graphType === undefined) {
                    graphType = 'bar';
                }

                var iChart = new Chart(node, {
                    type: graphType,
                    data: {
                        datasets: isDatasets,
                        labels: labels
                    },
                    options: {
                        legend: {
                            display: true
                        },
                        spanGaps: true, 
                        title: {
                            display: hasTitle,
                            text: title,
                            position: 'bottom',
                            fontSize: 15
                        },
                        animation: {
                            duration: 2000,
                            onProgress: function (animation) {
                                let progressValue = animation.currentStep / animation.numSteps
                                progressValue = (progressValue * 100).toFixed(0)
                                $progressChart.css('width', progressValue + '%')
                                $progressChart.html(progressValue + '%')
                            },
                            onComplete: function (animation) {
                                setTimeout(function() {
                                }, 2000)
                                $progressChart.parent().slideUp('slow')
                                $('body #loading-' + graphId).slideUp('slow')
                            }
                        },
                        scales: {
                            yAxes: [{
                                ticks: {
                                    callback: function(tick) {
                                        if (isPercent) {
                                            return tick.toString() + '%'
                                        }

                                        return tick
                                    },
                                    beginAtZero: true,
                                    min: 0
                                }
                            }]
                        },
                        legendCallback: function(chart) {
                            // console.log('CHARTT = ', chart)
                            let legend = chart.legend
                            let legendItems = legend.legendItems

                            if (legendItems.length > 4) {
                                chart.legend.options.display = false

                                let legendStartWrapper = `
                                    <div class="col-12">
                                        <div class="row">
                                `
                                let legendEndWrapper = `
                                        </div>
                                    </div>
                                `

                                let legendElms = `<div class="col-12 text-center">`

                                let containerElms = `
                                    <div class="col-12 text-center font-size-15 font-weight-bold text-muted legend-description legend-all">
                                        ` + legendItems.length + ` éléments affichés
                                    </div>
                                `

                                for (var i = 0; i < legendItems.length; i++) {
                                    let legendItem = legendItems[i]
                                    let isActive = 'hide'
                                    legendElms += `
                                        <i class="fa fa-circle legend-click is-datasets show-cursor-pointer" 
                                        title="` + legendItem.text + `" 
                                        data-index="` + legendItem.datasetIndex + `" 
                                        style="color: ` + legendItem.fillStyle + `;"
                                        data-chart-id="` + graphId + `"></i>
                                    `
                                    containerElms += `
                                        <div class="col-12 text-center font-size-15 font-weight-bold text-muted ` + isActive + ` legend-description legend-` + i + `">
                                        <i class="fal fa-map"></i> ` + legendItem.text + `
                                       </div>
                                    `
                                }

                                legendElms += `</div>`
                                
                                chart.update()

                                return legendStartWrapper + legendElms + containerElms + legendEndWrapper
                            }
                        }
                    }
                })
                
                
                $graph.removeClass('first-try')
                $graph.addClass('initialized')
                // console.log('iChart with dataset')
                $('body #legend-ichart-' + graphId).html(iChart.generateLegend())
                return
            }
            // graphe bar/line
            if (isDatasets != undefined) {
                graphType = isDatasets.length > 1 ? graphType : 'bar'
                let hasCookieForGraph = tools.getCookie(chartReference)
                graphType = hasCookieForGraph === false ? graphType : hasCookieForGraph

                var iChart = new Chart(node,{
                    type: 'bar',
                    data: data,
                    options: {
                        element: {
                            point:{
                                radius:0
                            }
                        },
                        legend: {
                            display: true
                        },
                        spanGaps: true, 
                        title: {
                            display: true,
                            text: title,
                            position: 'bottom',
                            fontSize: 15
                        },
                        animation: {
                            duration: 2000,
                            onProgress: function (animation) {
                                let progressValue = animation.currentStep / animation.numSteps
                                progressValue = (progressValue * 100).toFixed(0)
                                $progressChart.css('width', progressValue + '%')
                                $progressChart.html(progressValue + '%')
                            },
                            onComplete: function (animation) {
                                setTimeout(function() {
                                }, 2000)
                                $progressChart.parent().slideUp('slow')
                                $('body #loading-' + graphId).slideUp('slow')
                            }
                        },
                        scales: {
                            yAxes: [{
                                stacked: true,
                                ticks: {
                                    callback: function(tick) {
                                        if (isPercent) {
                                            return tick.toString() + '%'
                                        }

                                        return tick
                                    },
                                    beginAtZero: true,
                                    min: 0
                                }
                            }],
                            xAxes: [
                                {
                                  display: true,
                                  gridLines: {
                                    display: false
                                }
                            }],
                        legendCallback: function(chart) {
                            // console.log('CHARTT = ', chart)
                            let legend = chart.legend
                            let legendItems = legend.legendItems

                            if (legendItems.length > 4) {
                                chart.legend.options.display = false

                                let legendStartWrapper = `
                                    <div class="col-12">
                                        <div class="row">
                                `
                                let legendEndWrapper = `
                                        </div>
                                    </div>
                                `

                                let legendElms = `<div class="col-12 text-center">`

                                let containerElms = `
                                    <div class="col-12 text-center font-size-15 font-weight-bold text-muted legend-description legend-all">
                                        ` + legendItems.length + ` éléments affichés
                                    </div>
                                `

                                for (var i = 0; i < legendItems.length; i++) {
                                    let legendItem = legendItems[i]
                                    let isActive = 'hide'
                                    legendElms += `
                                        <i class="fa fa-circle legend-click is-datasets show-cursor-pointer" 
                                        title="` + legendItem.text + `" 
                                        data-index="` + legendItem.datasetIndex + `" 
                                        style="color: ` + legendItem.fillStyle + `;"
                                        data-chart-id="` + graphId + `"></i>
                                    `
                                    containerElms += `
                                        <div class="col-12 text-center font-size-15 font-weight-bold text-muted ` + isActive + ` legend-description legend-` + i + `">
                                        <i class="fal fa-map"></i> ` + legendItem.text + `
                                       </div>
                                    `
                                }

                                legendElms += `</div>`
                                
                                chart.update()

                                return legendStartWrapper + legendElms + containerElms + legendEndWrapper
                            }
                      }
                    }
                }
            })
                
                $graph.removeClass('first-try')
                $graph.addClass('initialized')
                // console.log('iChart with dataset')
                $('body #legend-ichart-' + graphId).html(iChart.generateLegend())
                return
            
        
        }
////////////////////////////////////////////////
            if (labelsLimitForDefaultGraph != undefined && labels.length > labelsLimitForDefaultGraph) {
                graphType = overLimitGraphType
                $graph.data('chart', graphType)
            }

            let hasCookieForGraph = tools.getCookie(chartReference)
            graphType = hasCookieForGraph === false ? graphType : hasCookieForGraph
            if (graphType == 'bar' || graphType == 'radar') {
                $graph.css('height', 2)
                $graph.attr('height', 2)
                $graph.attr('width', 1)
                $graph.css('width', 1)
            }

            if (graphType === undefined) {
                graphType = 'line'
            }
            chartSettings.type = graphType
            chartSettings.data = {}
            chartSettings.data.datasets = [{
                data: data,
                label: dataSetsLabel,
                backgroundColor: bgColors
            }]
            chartSettings.data.labels = labels
            if (hasTitle) {
                chartSettings.options.title = {
                    display: true,
                    text: title,
                    position: 'bottom',
                    fontSize: 15
                }
            }
            chartSettings.options.responsive = true
            chartSettings.options.scales = {}
            chartSettings.options.tooltips = {}
            chartSettings.options.tooltips.mode = 'index'
            chartSettings.options.tooltips.intersect = true
            if (graphType == 'bar') {
                chartSettings.options.scales.yAxes = [{}]
                chartSettings.options.scales.yAxes[0].ticks = {}
                chartSettings.options.scales.yAxes[0].ticks.beginAtZero = true
            }
            chartSettings.options.scales.xAxes = {}
            chartSettings.options.scales.xAxes.ticks = {}
            chartSettings.options.scales.xAxes.gridLines = {}
            chartSettings.options.animation = {}
            // chartSettings.options.animation.duration = 2000
            let animationOnProgress = function (animation) {
                let progressValue = animation.currentStep / animation.numSteps
                progressValue = (progressValue * 100).toFixed(0)
                // console.log('onProgress == ' + progressValue + '%')
                $progressChart.css('width', progressValue + '%')
                $progressChart.html(progressValue + '%')
            }
            chartSettings.options.animation.onProgress = animationOnProgress
            let animationOnComplete = function () {
                $progressChart.css('width', '100%')
                $progressChart.html('100%')
                $progressChart.parent().slideUp('slow')
            }
            chartSettings.options.animation.onComplete = animationOnComplete

            chartSettings.options.legendCallback = function(chart) {
                // console.log('CHART : ')

                // chartJs.helpers.each(chartJs.instances, function (instance) {
                //     console.log('Chart instance : ' + instance.chart.canvas.id)
                // })

                let legend = chart.legend
                let legendItems = legend.legendItems

                if (legendItems.length > 4) {
                    chart.legend.options.display = false

                    let legendStartWrapper = `
                        <div class="col-12">
                            <div class="row">
                    `
                    let legendEndWrapper = `
                            </div>
                        </div>
                    `

                    let legendElms = `<div class="col-12 text-center">`

                    let containerElms = `
                        <div class="col-12 text-center font-size-15 font-weight-bold text-muted legend-description legend-all">
                            ` + legendItems.length + ` éléments affichés
                        </div>
                    `

                    for (var i = 0; i < legendItems.length; i++) {
                        let legendItem = legendItems[i]
                        // console.log('legendItem : ', legendItem)
                        let isActive = 'hide'
                        legendElms += `
                            <i class="fa fa-circle legend-click show-cursor-pointer" 
                            title="` + legendItem.text + `" 
                            data-index="` + legendItem.index + `" 
                            style="color: ` + legendItem.fillStyle + `;"
                            data-chart-id="` + graphId + `"></i>
                        `
                        containerElms += `
                            <div class="col-12 text-center font-size-15 font-weight-bold text-muted ` + isActive + ` legend-description legend-` + legendItem.index + `">
                            <i class="fal fa-map"></i> ` + legendItem.text + `
                            <div data-position="` + i + `" class="legend-chart-` + graphId + `" data-label="` + legendItem.text + `"
                            data-color="` + legendItem.fillStyle + `" data-value="` + chart.data.datasets[0].data[i] + `">
                            </div>
                           </div>
                        `
                    }

                    legendElms += `</div>`
                    
                    chart.update()

                    return legendStartWrapper + legendElms + containerElms + legendEndWrapper
                }
            }

            // Chart.plugins.register({
            //     beforeDraw: function(chartInstance) {
            //         var ctx = chartInstance.chart.ctx
            //         ctx.fillStyle = "white"
            //         ctx.fillRect(0, 0, chartInstance.chart.width, chartInstance.chart.height)
            //     }
            // })

            var iChart = new Chart(node, chartSettings)

            $graph.removeClass('first-try')
            $graph.addClass('initialized')
            $('body #legend-ichart-' + graphId).html(iChart.generateLegend())
            if (isLast) {
                hygchart.optimizeRendering(isLast)
            }
        }
    })
}

const destroyCanvasContainer = function (elm, chartWrapper = null) {
    let $elm = $(elm)
    let $gc = $elm.closest('.graph-container')

    if (chartWrapper != null && chartWrapper != undefined && chartWrapper != '') {
        $('body .' + chartWrapper).hide('slow', function () {
            $(this).remove()
        })
    } else {
        $elm.parent().parent().hide('slow', function () {
            $(this).remove()
        })
    }
}

const optimizeRendering = function (showGraphAfterOR = false) {
    let $graphsContainer = $('body .chart-wrapper')
    let nb = $graphsContainer.length
    let colClass = ''
    let barColClass = ''
    let chartRangeHeight = 2
    let barChartRangeHeight = 3
    switch (nb) {
        case 1:
            colClass = 'col-xl-2 col-lg-4 col-sm-5'
            barColClass = 'col-xl-3 col-lg-4 col-sm-5'
            chartRangeHeight = 5
        break
        case 2:
            colClass = 'col-xl-3 col-lg-4 col-sm-5'
            barColClass = 'col-xl-3 col-lg-4 col-sm-5'
            chartRangeHeight = 5
        break
        case 3:
            if ($('body .charts-default-container.is-big').length > 0) {
                colClass = 'col-xl-2 col-lg-4 col-sm-5'
                barColClass = 'col-xl-2 col-lg-4 col-sm-5'
            } else {
                colClass = 'col-xl-4 col-lg-5'
                barColClass = 'col-xl-4 col-lg-5'
            }
            chartRangeHeight = 4
        break
        case 4:
            if ($('body .charts-default-container.is-big').length > 0) {
                colClass = 'col-xl-2 col-lg-4 col-sm-5'
                barColClass = 'col-xl-2 col-lg-4 col-sm-5'
            } else {
                colClass = 'col-xl-4 col-lg-4'
                barColClass = 'col-xl-4 col-lg-4'
            }
            chartRangeHeight = 3
        break
        case 5:
            if ($('body .charts-default-container.is-big').length > 0) {
                colClass = 'col-xl-2 col-lg-4 col-sm-5'
                barColClass = 'col-xl-2 col-lg-4 col-sm-5'
            } else {
                colClass = 'col-xl-4 col-lg-5'
                barColClass = 'col-xl-4 col-lg-5'
            }
            chartRangeHeight = 3
        break
        default:
            colClass = 'col-xl-4 col-lg-5'
            barColClass = 'col-xl-4 col-lg-5'
            chartRangeHeight = 3
        break
    }
    $graphsContainer.each(function (i, elm) {
        let $chartContainer = $(elm)
        let chartContainerId = $chartContainer.attr('id')

        if ($chartContainer.hasClass('opti-initied')) {
            return
        }

        if (chartContainerId == undefined) {
            chartContainerId = 'chart-container-' + tools.getRandomInt()
            $chartContainer.attr('id', chartContainerId)
        }

        let $chartChildren = $('body #' + chartContainerId + ' .canvas-chart')
        if ($chartChildren.length <= 0 || !$chartChildren.hasClass('initialized')) {
            return
        }

        let chartId = $chartChildren.attr('id')
        let chartReference = $chartChildren.data('chart-reference')
        let hasCookieForGraphType = tools.getCookie(chartReference)
        let hasCookieForGraphCol = tools.getCookie('chart-size-' + chartReference)
        let chartType = $chartChildren.data('chart')
        chartType = hasCookieForGraphType === false ? chartType : hasCookieForGraphType

        if ($(elm).hasClass('unresize-col')) {
            return
        }

        let currentCol = tools.possibleBootstrapCol()
        if (hasCookieForGraphCol) {
            $chartContainer.removeClass(currentCol).addClass('col-' + hasCookieForGraphCol)
            $chartContainer.data('current-col', 'col-' + hasCookieForGraphCol)
            return
        }

        currentCol = $chartContainer.data('current-col')
        if (/;/.test(currentCol)) {
            currentCol = currentCol.split(';')
            currentCol = currentCol.join(' ')
        }

        if (chartType == 'bar') {
            $chartContainer.removeClass(currentCol).addClass(barColClass)
            $chartContainer.data('current-col', barColClass)
        } else {
            if (/;/.test(colClass)) {
                colClass = colClass.split(';')
                colClass = colClass.join(' ')
            }
            $chartContainer.removeClass(currentCol).addClass(colClass)
            if (tools.hasWhiteSpace(colClass)) {
                colClass = colClass.split(' ')
                colClass = colClass.join(';')
            }
            $chartContainer.data('current-col', colClass)
        }
        //setup filter range for width
        let $widthFilter = $('body #range-' + chartId)
        if ($widthFilter.length > 0) {
            let widthFilterValue = chartType == 'bar' ? barChartRangeHeight : chartRangeHeight
            $widthFilter.val(widthFilterValue)
        }
        $chartContainer.addClass('opti-initied')
    })

    if (showGraphAfterOR) {
        hygchart.showCharts('graph-container')
    }
}

const chartDebug = function (message, type = 'info') {
    let chartContainerId = 'chart-debug-container'
    let $debugWrapper = tools.debugWrapper()

    let $chartContainer = $('body #' + chartContainerId)

    if ($chartContainer.length <= 0) {
        $debugWrapper.append('<h5 class="h5 toggle-container" data-target="' + chartContainerId + '"><i class="fa fa-bug"></i> Graphique</h5><div class="row" id="' + chartContainerId + '"></div>')
        
        $chartContainer = $('body #' + chartContainerId)
        $chartContainer.hide()
    }

    let $alerts = $('body #' + chartContainerId + ' .alert')

    if ($alerts.length >= 25) {
        let limit = $alerts.length - 5
        $alerts.each(function (i, elm) {
            if (i >= limit) {
                return
            }
            $(elm).hide('slow', function () {
                this.remove()
            })
        })
    }
    
    const ts = moment().unix()
    const alertId = 'debug-alert-' + Math.floor(Math.random() * Math.floor(ts))

    $chartContainer.append('<div id="' + alertId + '" class="alert hide col-12 rounded-0 alert-' + type + ' mb-1">' + message + '</div')

    $('body #' + alertId).show('slow')

    let chartContainerNode = document.getElementById(chartContainerId)

    $chartContainer.parent().animate({
        scrollTop: chartContainerNode.scrollHeight
    }, 1000)
}

const hygchart = {
    loadDashboardGraph,
    chartDebug,
    loadGraph,
    optimizeRendering,
    showCharts,
    destroyCanvasContainer,
    planTrapChart,
    planTrapCountChart,
    loadTrapChart,
    loadTrapCountChart
}

export default hygchart